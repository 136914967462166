import { ChecksumAlgorithm } from "./constants";
import { CLIENT_SUPPORTED_ALGORITHMS } from "./types";
export var getChecksumAlgorithmForRequest = function (input, _a) {
    var requestChecksumRequired = _a.requestChecksumRequired, requestAlgorithmMember = _a.requestAlgorithmMember;
    if (!requestAlgorithmMember || !input[requestAlgorithmMember]) {
        return requestChecksumRequired ? ChecksumAlgorithm.MD5 : undefined;
    }
    var checksumAlgorithm = input[requestAlgorithmMember];
    if (!CLIENT_SUPPORTED_ALGORITHMS.includes(checksumAlgorithm)) {
        throw new Error("The checksum algorithm \"".concat(checksumAlgorithm, "\" is not supported by the client.") +
            " Select one of ".concat(CLIENT_SUPPORTED_ALGORITHMS, "."));
    }
    return checksumAlgorithm;
};
