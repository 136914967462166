import { __assign, __extends } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
import { S3ServiceException as __BaseException } from "./S3ServiceException";
var NoSuchUpload = (function (_super) {
    __extends(NoSuchUpload, _super);
    function NoSuchUpload(opts) {
        var _this = _super.call(this, __assign({ name: "NoSuchUpload", $fault: "client" }, opts)) || this;
        _this.name = "NoSuchUpload";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NoSuchUpload.prototype);
        return _this;
    }
    return NoSuchUpload;
}(__BaseException));
export { NoSuchUpload };
export var ChecksumAlgorithm;
(function (ChecksumAlgorithm) {
    ChecksumAlgorithm["CRC32"] = "CRC32";
    ChecksumAlgorithm["CRC32C"] = "CRC32C";
    ChecksumAlgorithm["SHA1"] = "SHA1";
    ChecksumAlgorithm["SHA256"] = "SHA256";
})(ChecksumAlgorithm || (ChecksumAlgorithm = {}));
var ObjectNotInActiveTierError = (function (_super) {
    __extends(ObjectNotInActiveTierError, _super);
    function ObjectNotInActiveTierError(opts) {
        var _this = _super.call(this, __assign({ name: "ObjectNotInActiveTierError", $fault: "client" }, opts)) || this;
        _this.name = "ObjectNotInActiveTierError";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ObjectNotInActiveTierError.prototype);
        return _this;
    }
    return ObjectNotInActiveTierError;
}(__BaseException));
export { ObjectNotInActiveTierError };
var BucketAlreadyExists = (function (_super) {
    __extends(BucketAlreadyExists, _super);
    function BucketAlreadyExists(opts) {
        var _this = _super.call(this, __assign({ name: "BucketAlreadyExists", $fault: "client" }, opts)) || this;
        _this.name = "BucketAlreadyExists";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BucketAlreadyExists.prototype);
        return _this;
    }
    return BucketAlreadyExists;
}(__BaseException));
export { BucketAlreadyExists };
var BucketAlreadyOwnedByYou = (function (_super) {
    __extends(BucketAlreadyOwnedByYou, _super);
    function BucketAlreadyOwnedByYou(opts) {
        var _this = _super.call(this, __assign({ name: "BucketAlreadyOwnedByYou", $fault: "client" }, opts)) || this;
        _this.name = "BucketAlreadyOwnedByYou";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BucketAlreadyOwnedByYou.prototype);
        return _this;
    }
    return BucketAlreadyOwnedByYou;
}(__BaseException));
export { BucketAlreadyOwnedByYou };
export var AnalyticsFilter;
(function (AnalyticsFilter) {
    AnalyticsFilter.visit = function (value, visitor) {
        if (value.Prefix !== undefined)
            return visitor.Prefix(value.Prefix);
        if (value.Tag !== undefined)
            return visitor.Tag(value.Tag);
        if (value.And !== undefined)
            return visitor.And(value.And);
        return visitor._(value.$unknown[0], value.$unknown[1]);
    };
})(AnalyticsFilter || (AnalyticsFilter = {}));
export var LifecycleRuleFilter;
(function (LifecycleRuleFilter) {
    LifecycleRuleFilter.visit = function (value, visitor) {
        if (value.Prefix !== undefined)
            return visitor.Prefix(value.Prefix);
        if (value.Tag !== undefined)
            return visitor.Tag(value.Tag);
        if (value.ObjectSizeGreaterThan !== undefined)
            return visitor.ObjectSizeGreaterThan(value.ObjectSizeGreaterThan);
        if (value.ObjectSizeLessThan !== undefined)
            return visitor.ObjectSizeLessThan(value.ObjectSizeLessThan);
        if (value.And !== undefined)
            return visitor.And(value.And);
        return visitor._(value.$unknown[0], value.$unknown[1]);
    };
})(LifecycleRuleFilter || (LifecycleRuleFilter = {}));
export var MetricsFilter;
(function (MetricsFilter) {
    MetricsFilter.visit = function (value, visitor) {
        if (value.Prefix !== undefined)
            return visitor.Prefix(value.Prefix);
        if (value.Tag !== undefined)
            return visitor.Tag(value.Tag);
        if (value.AccessPointArn !== undefined)
            return visitor.AccessPointArn(value.AccessPointArn);
        if (value.And !== undefined)
            return visitor.And(value.And);
        return visitor._(value.$unknown[0], value.$unknown[1]);
    };
})(MetricsFilter || (MetricsFilter = {}));
export var ReplicationRuleFilter;
(function (ReplicationRuleFilter) {
    ReplicationRuleFilter.visit = function (value, visitor) {
        if (value.Prefix !== undefined)
            return visitor.Prefix(value.Prefix);
        if (value.Tag !== undefined)
            return visitor.Tag(value.Tag);
        if (value.And !== undefined)
            return visitor.And(value.And);
        return visitor._(value.$unknown[0], value.$unknown[1]);
    };
})(ReplicationRuleFilter || (ReplicationRuleFilter = {}));
export var ChecksumMode;
(function (ChecksumMode) {
    ChecksumMode["ENABLED"] = "ENABLED";
})(ChecksumMode || (ChecksumMode = {}));
var InvalidObjectState = (function (_super) {
    __extends(InvalidObjectState, _super);
    function InvalidObjectState(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidObjectState", $fault: "client" }, opts)) || this;
        _this.name = "InvalidObjectState";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidObjectState.prototype);
        _this.StorageClass = opts.StorageClass;
        _this.AccessTier = opts.AccessTier;
        return _this;
    }
    return InvalidObjectState;
}(__BaseException));
export { InvalidObjectState };
var NoSuchKey = (function (_super) {
    __extends(NoSuchKey, _super);
    function NoSuchKey(opts) {
        var _this = _super.call(this, __assign({ name: "NoSuchKey", $fault: "client" }, opts)) || this;
        _this.name = "NoSuchKey";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NoSuchKey.prototype);
        return _this;
    }
    return NoSuchKey;
}(__BaseException));
export { NoSuchKey };
export var ObjectAttributes;
(function (ObjectAttributes) {
    ObjectAttributes["CHECKSUM"] = "Checksum";
    ObjectAttributes["ETAG"] = "ETag";
    ObjectAttributes["OBJECT_PARTS"] = "ObjectParts";
    ObjectAttributes["OBJECT_SIZE"] = "ObjectSize";
    ObjectAttributes["STORAGE_CLASS"] = "StorageClass";
})(ObjectAttributes || (ObjectAttributes = {}));
var NotFound = (function (_super) {
    __extends(NotFound, _super);
    function NotFound(opts) {
        var _this = _super.call(this, __assign({ name: "NotFound", $fault: "client" }, opts)) || this;
        _this.name = "NotFound";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NotFound.prototype);
        return _this;
    }
    return NotFound;
}(__BaseException));
export { NotFound };
var NoSuchBucket = (function (_super) {
    __extends(NoSuchBucket, _super);
    function NoSuchBucket(opts) {
        var _this = _super.call(this, __assign({ name: "NoSuchBucket", $fault: "client" }, opts)) || this;
        _this.name = "NoSuchBucket";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NoSuchBucket.prototype);
        return _this;
    }
    return NoSuchBucket;
}(__BaseException));
export { NoSuchBucket };
export var AbortIncompleteMultipartUploadFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AbortMultipartUploadOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AbortMultipartUploadRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccelerateConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GranteeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GrantFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OwnerFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccessControlPolicyFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccessControlTranslationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CompleteMultipartUploadOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING }))); };
export var CompletedPartFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CompletedMultipartUploadFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CompleteMultipartUploadRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var CopyObjectResultFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CopyObjectOutputFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING })), (obj.SSEKMSEncryptionContext && { SSEKMSEncryptionContext: SENSITIVE_STRING }))); };
export var CopyObjectRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING })), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING })), (obj.SSEKMSEncryptionContext && { SSEKMSEncryptionContext: SENSITIVE_STRING })), (obj.CopySourceSSECustomerKey && { CopySourceSSECustomerKey: SENSITIVE_STRING }))); };
export var CreateBucketOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateBucketConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateBucketRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateMultipartUploadOutputFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING })), (obj.SSEKMSEncryptionContext && { SSEKMSEncryptionContext: SENSITIVE_STRING }))); };
export var CreateMultipartUploadRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING })), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING })), (obj.SSEKMSEncryptionContext && { SSEKMSEncryptionContext: SENSITIVE_STRING }))); };
export var DeleteBucketRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketAnalyticsConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketCorsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketEncryptionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketIntelligentTieringConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketInventoryConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketLifecycleRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketMetricsConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketOwnershipControlsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketPolicyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketReplicationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketTaggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBucketWebsiteRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteObjectOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteObjectRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeletedObjectFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var _ErrorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteObjectsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ObjectIdentifierFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteObjectsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteObjectTaggingOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteObjectTaggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeletePublicAccessBlockRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketAccelerateConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketAccelerateConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketAclOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketAclRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TagFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AnalyticsAndOperatorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AnalyticsFilterFilterSensitiveLog = function (obj) {
    var _a;
    if (obj.Prefix !== undefined)
        return { Prefix: obj.Prefix };
    if (obj.Tag !== undefined)
        return { Tag: TagFilterSensitiveLog(obj.Tag) };
    if (obj.And !== undefined)
        return { And: AnalyticsAndOperatorFilterSensitiveLog(obj.And) };
    if (obj.$unknown !== undefined)
        return _a = {}, _a[obj.$unknown[0]] = "UNKNOWN", _a;
};
export var AnalyticsS3BucketDestinationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AnalyticsExportDestinationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StorageClassAnalysisDataExportFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StorageClassAnalysisFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AnalyticsConfigurationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Filter && { Filter: AnalyticsFilterFilterSensitiveLog(obj.Filter) }))); };
export var GetBucketAnalyticsConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AnalyticsConfiguration && {
    AnalyticsConfiguration: AnalyticsConfigurationFilterSensitiveLog(obj.AnalyticsConfiguration),
}))); };
export var GetBucketAnalyticsConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CORSRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketCorsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketCorsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ServerSideEncryptionByDefaultFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.KMSMasterKeyID && { KMSMasterKeyID: SENSITIVE_STRING }))); };
export var ServerSideEncryptionRuleFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ApplyServerSideEncryptionByDefault && {
    ApplyServerSideEncryptionByDefault: ServerSideEncryptionByDefaultFilterSensitiveLog(obj.ApplyServerSideEncryptionByDefault),
}))); };
export var ServerSideEncryptionConfigurationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Rules && { Rules: obj.Rules.map(function (item) { return ServerSideEncryptionRuleFilterSensitiveLog(item); }) }))); };
export var GetBucketEncryptionOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ServerSideEncryptionConfiguration && {
    ServerSideEncryptionConfiguration: ServerSideEncryptionConfigurationFilterSensitiveLog(obj.ServerSideEncryptionConfiguration),
}))); };
export var GetBucketEncryptionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var IntelligentTieringAndOperatorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var IntelligentTieringFilterFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TieringFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var IntelligentTieringConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketIntelligentTieringConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketIntelligentTieringConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SSEKMSFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.KeyId && { KeyId: SENSITIVE_STRING }))); };
export var SSES3FilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InventoryEncryptionFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMS && { SSEKMS: SSEKMSFilterSensitiveLog(obj.SSEKMS) }))); };
export var InventoryS3BucketDestinationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Encryption && { Encryption: InventoryEncryptionFilterSensitiveLog(obj.Encryption) }))); };
export var InventoryDestinationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.S3BucketDestination && {
    S3BucketDestination: InventoryS3BucketDestinationFilterSensitiveLog(obj.S3BucketDestination),
}))); };
export var InventoryFilterFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InventoryScheduleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InventoryConfigurationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Destination && { Destination: InventoryDestinationFilterSensitiveLog(obj.Destination) }))); };
export var GetBucketInventoryConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.InventoryConfiguration && {
    InventoryConfiguration: InventoryConfigurationFilterSensitiveLog(obj.InventoryConfiguration),
}))); };
export var GetBucketInventoryConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LifecycleExpirationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LifecycleRuleAndOperatorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LifecycleRuleFilterFilterSensitiveLog = function (obj) {
    var _a;
    if (obj.Prefix !== undefined)
        return { Prefix: obj.Prefix };
    if (obj.Tag !== undefined)
        return { Tag: TagFilterSensitiveLog(obj.Tag) };
    if (obj.ObjectSizeGreaterThan !== undefined)
        return { ObjectSizeGreaterThan: obj.ObjectSizeGreaterThan };
    if (obj.ObjectSizeLessThan !== undefined)
        return { ObjectSizeLessThan: obj.ObjectSizeLessThan };
    if (obj.And !== undefined)
        return { And: LifecycleRuleAndOperatorFilterSensitiveLog(obj.And) };
    if (obj.$unknown !== undefined)
        return _a = {}, _a[obj.$unknown[0]] = "UNKNOWN", _a;
};
export var NoncurrentVersionExpirationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NoncurrentVersionTransitionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TransitionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LifecycleRuleFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Filter && { Filter: LifecycleRuleFilterFilterSensitiveLog(obj.Filter) }))); };
export var GetBucketLifecycleConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Rules && { Rules: obj.Rules.map(function (item) { return LifecycleRuleFilterSensitiveLog(item); }) }))); };
export var GetBucketLifecycleConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketLocationOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketLocationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TargetGrantFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LoggingEnabledFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketLoggingOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketLoggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MetricsAndOperatorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MetricsFilterFilterSensitiveLog = function (obj) {
    var _a;
    if (obj.Prefix !== undefined)
        return { Prefix: obj.Prefix };
    if (obj.Tag !== undefined)
        return { Tag: TagFilterSensitiveLog(obj.Tag) };
    if (obj.AccessPointArn !== undefined)
        return { AccessPointArn: obj.AccessPointArn };
    if (obj.And !== undefined)
        return { And: MetricsAndOperatorFilterSensitiveLog(obj.And) };
    if (obj.$unknown !== undefined)
        return _a = {}, _a[obj.$unknown[0]] = "UNKNOWN", _a;
};
export var MetricsConfigurationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Filter && { Filter: MetricsFilterFilterSensitiveLog(obj.Filter) }))); };
export var GetBucketMetricsConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.MetricsConfiguration && {
    MetricsConfiguration: MetricsConfigurationFilterSensitiveLog(obj.MetricsConfiguration),
}))); };
export var GetBucketMetricsConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketNotificationConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventBridgeConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var FilterRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var S3KeyFilterFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NotificationConfigurationFilterFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LambdaFunctionConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var QueueConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TopicConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NotificationConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OwnershipControlsRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OwnershipControlsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketOwnershipControlsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketOwnershipControlsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketPolicyOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketPolicyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PolicyStatusFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketPolicyStatusOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketPolicyStatusRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteMarkerReplicationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EncryptionConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ReplicationTimeValueFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MetricsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ReplicationTimeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DestinationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ExistingObjectReplicationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ReplicationRuleAndOperatorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ReplicationRuleFilterFilterSensitiveLog = function (obj) {
    var _a;
    if (obj.Prefix !== undefined)
        return { Prefix: obj.Prefix };
    if (obj.Tag !== undefined)
        return { Tag: TagFilterSensitiveLog(obj.Tag) };
    if (obj.And !== undefined)
        return { And: ReplicationRuleAndOperatorFilterSensitiveLog(obj.And) };
    if (obj.$unknown !== undefined)
        return _a = {}, _a[obj.$unknown[0]] = "UNKNOWN", _a;
};
export var ReplicaModificationsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SseKmsEncryptedObjectsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SourceSelectionCriteriaFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ReplicationRuleFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Filter && { Filter: ReplicationRuleFilterFilterSensitiveLog(obj.Filter) }))); };
export var ReplicationConfigurationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Rules && { Rules: obj.Rules.map(function (item) { return ReplicationRuleFilterSensitiveLog(item); }) }))); };
export var GetBucketReplicationOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ReplicationConfiguration && {
    ReplicationConfiguration: ReplicationConfigurationFilterSensitiveLog(obj.ReplicationConfiguration),
}))); };
export var GetBucketReplicationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketRequestPaymentOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketRequestPaymentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketTaggingOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketTaggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketVersioningOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketVersioningRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ErrorDocumentFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var IndexDocumentFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RedirectAllRequestsToFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RedirectFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RoutingRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketWebsiteOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBucketWebsiteRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING }))); };
export var GetObjectRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var GetObjectAclOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectAclRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ChecksumFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ObjectPartFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectAttributesPartsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectAttributesOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectAttributesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var ObjectLockLegalHoldFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectLegalHoldOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectLegalHoldRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DefaultRetentionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ObjectLockRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ObjectLockConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectLockConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectLockConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ObjectLockRetentionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectRetentionOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectRetentionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectTaggingOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectTaggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectTorrentOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetObjectTorrentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PublicAccessBlockConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetPublicAccessBlockOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetPublicAccessBlockRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var HeadBucketRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var HeadObjectOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING }))); };
export var HeadObjectRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var ListBucketAnalyticsConfigurationsOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AnalyticsConfigurationList && {
    AnalyticsConfigurationList: obj.AnalyticsConfigurationList.map(function (item) {
        return AnalyticsConfigurationFilterSensitiveLog(item);
    }),
}))); };
export var ListBucketAnalyticsConfigurationsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListBucketIntelligentTieringConfigurationsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListBucketIntelligentTieringConfigurationsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListBucketInventoryConfigurationsOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.InventoryConfigurationList && {
    InventoryConfigurationList: obj.InventoryConfigurationList.map(function (item) {
        return InventoryConfigurationFilterSensitiveLog(item);
    }),
}))); };
export var ListBucketInventoryConfigurationsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListBucketMetricsConfigurationsOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.MetricsConfigurationList && {
    MetricsConfigurationList: obj.MetricsConfigurationList.map(function (item) { return MetricsConfigurationFilterSensitiveLog(item); }),
}))); };
export var ListBucketMetricsConfigurationsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var BucketFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListBucketsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CommonPrefixFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InitiatorFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MultipartUploadFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListMultipartUploadsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListMultipartUploadsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var _ObjectFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListObjectsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListObjectsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListObjectsV2OutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListObjectsV2RequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteMarkerEntryFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ObjectVersionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListObjectVersionsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListObjectVersionsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PartFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListPartsOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListPartsRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var PutBucketAccelerateConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketAclRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketAnalyticsConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AnalyticsConfiguration && {
    AnalyticsConfiguration: AnalyticsConfigurationFilterSensitiveLog(obj.AnalyticsConfiguration),
}))); };
export var CORSConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketCorsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketEncryptionRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ServerSideEncryptionConfiguration && {
    ServerSideEncryptionConfiguration: ServerSideEncryptionConfigurationFilterSensitiveLog(obj.ServerSideEncryptionConfiguration),
}))); };
export var PutBucketIntelligentTieringConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketInventoryConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.InventoryConfiguration && {
    InventoryConfiguration: InventoryConfigurationFilterSensitiveLog(obj.InventoryConfiguration),
}))); };
export var BucketLifecycleConfigurationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Rules && { Rules: obj.Rules.map(function (item) { return LifecycleRuleFilterSensitiveLog(item); }) }))); };
export var PutBucketLifecycleConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.LifecycleConfiguration && {
    LifecycleConfiguration: BucketLifecycleConfigurationFilterSensitiveLog(obj.LifecycleConfiguration),
}))); };
export var BucketLoggingStatusFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketLoggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketMetricsConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.MetricsConfiguration && {
    MetricsConfiguration: MetricsConfigurationFilterSensitiveLog(obj.MetricsConfiguration),
}))); };
export var PutBucketNotificationConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketOwnershipControlsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketPolicyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketReplicationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ReplicationConfiguration && {
    ReplicationConfiguration: ReplicationConfigurationFilterSensitiveLog(obj.ReplicationConfiguration),
}))); };
export var RequestPaymentConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketRequestPaymentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TaggingFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketTaggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VersioningConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketVersioningRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WebsiteConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutBucketWebsiteRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectOutputFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING })), (obj.SSEKMSEncryptionContext && { SSEKMSEncryptionContext: SENSITIVE_STRING }))); };
export var PutObjectRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING })), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING })), (obj.SSEKMSEncryptionContext && { SSEKMSEncryptionContext: SENSITIVE_STRING }))); };
export var PutObjectAclOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectAclRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectLegalHoldOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectLegalHoldRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectLockConfigurationOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectLockConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
