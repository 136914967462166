import { __assign, __awaiter, __generator } from "tslib";
import { validate as validateArn } from "@aws-sdk/util-arn-parser";
export function validateBucketNameMiddleware() {
    var _this = this;
    return function (next) {
        return function (args) { return __awaiter(_this, void 0, void 0, function () {
            var Bucket, err;
            return __generator(this, function (_a) {
                Bucket = args.input.Bucket;
                if (typeof Bucket === "string" && !validateArn(Bucket) && Bucket.indexOf("/") >= 0) {
                    err = new Error("Bucket name shouldn't contain '/', received '".concat(Bucket, "'"));
                    err.name = "InvalidBucketName";
                    throw err;
                }
                return [2, next(__assign({}, args))];
            });
        }); };
    };
}
export var validateBucketNameMiddlewareOptions = {
    step: "initialize",
    tags: ["VALIDATE_BUCKET_NAME"],
    name: "validateBucketNameMiddleware",
    override: true,
};
export var getValidateBucketNamePlugin = function (unused) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(validateBucketNameMiddleware(), validateBucketNameMiddlewareOptions);
    },
}); };
