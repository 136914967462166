import { __awaiter, __generator } from "tslib";
import { isStreaming } from "./isStreaming";
import { stringHasher } from "./stringHasher";
export var getChecksum = function (body, _a) {
    var streamHasher = _a.streamHasher, checksumAlgorithmFn = _a.checksumAlgorithmFn, base64Encoder = _a.base64Encoder;
    return __awaiter(void 0, void 0, void 0, function () {
        var digest, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    digest = isStreaming(body) ? streamHasher(checksumAlgorithmFn, body) : stringHasher(checksumAlgorithmFn, body);
                    _b = base64Encoder;
                    return [4, digest];
                case 1: return [2, _b.apply(void 0, [_c.sent()])];
            }
        });
    });
};
