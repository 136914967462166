import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
var CONTENT_LENGTH_HEADER = "content-length";
export function checkContentLengthHeader() {
    var _this = this;
    return function (next, context) {
        return function (args) { return __awaiter(_this, void 0, void 0, function () {
            var request, message;
            var _a;
            return __generator(this, function (_b) {
                request = args.request;
                if (HttpRequest.isInstance(request)) {
                    if (!request.headers[CONTENT_LENGTH_HEADER]) {
                        message = "Are you using a Stream of unknown length as the Body of a PutObject request? Consider using Upload instead from @aws-sdk/lib-storage.";
                        if (typeof ((_a = context === null || context === void 0 ? void 0 : context.logger) === null || _a === void 0 ? void 0 : _a.warn) === "function") {
                            context.logger.warn(message);
                        }
                        else {
                            console.warn(message);
                        }
                    }
                }
                return [2, next(__assign({}, args))];
            });
        }); };
    };
}
export var checkContentLengthHeaderMiddlewareOptions = {
    step: "finalizeRequest",
    tags: ["CHECK_CONTENT_LENGTH_HEADER"],
    name: "getCheckContentLengthHeaderPlugin",
    override: true,
};
export var getCheckContentLengthHeaderPlugin = function (unused) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(checkContentLengthHeader(), checkContentLengthHeaderMiddlewareOptions);
    },
}); };
