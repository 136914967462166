import { __assign, __extends } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
import { S3ServiceException as __BaseException } from "./S3ServiceException";
var ObjectAlreadyInActiveTierError = (function (_super) {
    __extends(ObjectAlreadyInActiveTierError, _super);
    function ObjectAlreadyInActiveTierError(opts) {
        var _this = _super.call(this, __assign({ name: "ObjectAlreadyInActiveTierError", $fault: "client" }, opts)) || this;
        _this.name = "ObjectAlreadyInActiveTierError";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ObjectAlreadyInActiveTierError.prototype);
        return _this;
    }
    return ObjectAlreadyInActiveTierError;
}(__BaseException));
export { ObjectAlreadyInActiveTierError };
export var FileHeaderInfo;
(function (FileHeaderInfo) {
    FileHeaderInfo["IGNORE"] = "IGNORE";
    FileHeaderInfo["NONE"] = "NONE";
    FileHeaderInfo["USE"] = "USE";
})(FileHeaderInfo || (FileHeaderInfo = {}));
export var JSONType;
(function (JSONType) {
    JSONType["DOCUMENT"] = "DOCUMENT";
    JSONType["LINES"] = "LINES";
})(JSONType || (JSONType = {}));
export var QuoteFields;
(function (QuoteFields) {
    QuoteFields["ALWAYS"] = "ALWAYS";
    QuoteFields["ASNEEDED"] = "ASNEEDED";
})(QuoteFields || (QuoteFields = {}));
export var RestoreRequestType;
(function (RestoreRequestType) {
    RestoreRequestType["SELECT"] = "SELECT";
})(RestoreRequestType || (RestoreRequestType = {}));
export var SelectObjectContentEventStream;
(function (SelectObjectContentEventStream) {
    SelectObjectContentEventStream.visit = function (value, visitor) {
        if (value.Records !== undefined)
            return visitor.Records(value.Records);
        if (value.Stats !== undefined)
            return visitor.Stats(value.Stats);
        if (value.Progress !== undefined)
            return visitor.Progress(value.Progress);
        if (value.Cont !== undefined)
            return visitor.Cont(value.Cont);
        if (value.End !== undefined)
            return visitor.End(value.End);
        return visitor._(value.$unknown[0], value.$unknown[1]);
    };
})(SelectObjectContentEventStream || (SelectObjectContentEventStream = {}));
export var PutObjectRetentionOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectRetentionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectTaggingOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutObjectTaggingRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutPublicAccessBlockRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RestoreObjectOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GlacierJobParametersFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EncryptionFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.KMSKeyId && { KMSKeyId: SENSITIVE_STRING }))); };
export var MetadataEntryFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var S3LocationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Encryption && { Encryption: EncryptionFilterSensitiveLog(obj.Encryption) }))); };
export var OutputLocationFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.S3 && { S3: S3LocationFilterSensitiveLog(obj.S3) }))); };
export var CSVInputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JSONInputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ParquetInputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InputSerializationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CSVOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JSONOutputFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OutputSerializationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SelectParametersFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RestoreRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.OutputLocation && { OutputLocation: OutputLocationFilterSensitiveLog(obj.OutputLocation) }))); };
export var RestoreObjectRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RestoreRequest && { RestoreRequest: RestoreRequestFilterSensitiveLog(obj.RestoreRequest) }))); };
export var ContinuationEventFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndEventFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ProgressFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ProgressEventFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RecordsEventFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StatsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StatsEventFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SelectObjectContentEventStreamFilterSensitiveLog = function (obj) {
    var _a;
    if (obj.Records !== undefined)
        return { Records: RecordsEventFilterSensitiveLog(obj.Records) };
    if (obj.Stats !== undefined)
        return { Stats: StatsEventFilterSensitiveLog(obj.Stats) };
    if (obj.Progress !== undefined)
        return { Progress: ProgressEventFilterSensitiveLog(obj.Progress) };
    if (obj.Cont !== undefined)
        return { Cont: ContinuationEventFilterSensitiveLog(obj.Cont) };
    if (obj.End !== undefined)
        return { End: EndEventFilterSensitiveLog(obj.End) };
    if (obj.$unknown !== undefined)
        return _a = {}, _a[obj.$unknown[0]] = "UNKNOWN", _a;
};
export var SelectObjectContentOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Payload && { Payload: "STREAMING_CONTENT" }))); };
export var RequestProgressFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ScanRangeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SelectObjectContentRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var UploadPartOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING }))); };
export var UploadPartRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING }))); };
export var CopyPartResultFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UploadPartCopyOutputFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING }))); };
export var UploadPartCopyRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.SSECustomerKey && { SSECustomerKey: SENSITIVE_STRING })), (obj.CopySourceSSECustomerKey && { CopySourceSSECustomerKey: SENSITIVE_STRING }))); };
export var WriteGetObjectResponseRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SSEKMSKeyId && { SSEKMSKeyId: SENSITIVE_STRING }))); };
