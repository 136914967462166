import { __awaiter, __generator } from "tslib";
import { blobReader } from "@aws-sdk/chunked-blob-reader";
export var blobHasher = function blobHasher(hashCtor, blob) {
    return __awaiter(this, void 0, void 0, function () {
        var hash;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    hash = new hashCtor();
                    return [4, blobReader(blob, function (chunk) {
                            hash.update(chunk);
                        })];
                case 1:
                    _a.sent();
                    return [2, hash.digest()];
            }
        });
    });
};
