// ../../react-shim.js
import React from "react";

// src/input.tsx
import { useFormControl } from "@chakra-ui/form-control";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { cx } from "@chakra-ui/shared-utils";
var Input = forwardRef(function Input2(props, ref) {
  const { htmlSize, ...rest } = props;
  const styles = useMultiStyleConfig("Input", rest);
  const ownProps = omitThemingProps(rest);
  const input = useFormControl(ownProps);
  const _className = cx("chakra-input", props.className);
  return /* @__PURE__ */ React.createElement(chakra.input, {
    size: htmlSize,
    ...input,
    __css: styles.field,
    ref,
    className: _className
  });
});
Input.displayName = "Input";
Input.id = "Input";

// src/input-addon.tsx
import { chakra as chakra3, forwardRef as forwardRef3 } from "@chakra-ui/system";
import { cx as cx3 } from "@chakra-ui/shared-utils";

// src/input-group.tsx
import { createContext } from "@chakra-ui/react-context";
import { getValidChildren } from "@chakra-ui/react-children-utils";
import {
  chakra as chakra2,
  forwardRef as forwardRef2,
  omitThemingProps as omitThemingProps2,
  useMultiStyleConfig as useMultiStyleConfig2
} from "@chakra-ui/system";
import { cx as cx2 } from "@chakra-ui/shared-utils";
import { compact } from "@chakra-ui/object-utils";
import { cloneElement } from "react";
var [InputGroupStylesProvider, useInputGroupStyles] = createContext({
  name: `InputGroupStylesContext`,
  errorMessage: `useInputGroupStyles returned is 'undefined'. Seems you forgot to wrap the components in "<InputGroup />" `
});
var InputGroup = forwardRef2(function InputGroup2(props, ref) {
  const styles = useMultiStyleConfig2("Input", props);
  const { children, className, ...rest } = omitThemingProps2(props);
  const _className = cx2("chakra-input__group", className);
  const groupStyles = {};
  const validChildren = getValidChildren(children);
  const input = styles.field;
  validChildren.forEach((child) => {
    if (!styles)
      return;
    if (input && child.type.id === "InputLeftElement") {
      groupStyles.paddingStart = input.height ?? input.h;
    }
    if (input && child.type.id === "InputRightElement") {
      groupStyles.paddingEnd = input.height ?? input.h;
    }
    if (child.type.id === "InputRightAddon") {
      groupStyles.borderEndRadius = 0;
    }
    if (child.type.id === "InputLeftAddon") {
      groupStyles.borderStartRadius = 0;
    }
  });
  const clones = validChildren.map((child) => {
    var _a, _b;
    const theming = compact({
      size: ((_a = child.props) == null ? void 0 : _a.size) || props.size,
      variant: ((_b = child.props) == null ? void 0 : _b.variant) || props.variant
    });
    return child.type.id !== "Input" ? cloneElement(child, theming) : cloneElement(child, Object.assign(theming, groupStyles, child.props));
  });
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    className: _className,
    ref,
    __css: {
      width: "100%",
      display: "flex",
      position: "relative"
    },
    ...rest
  }, /* @__PURE__ */ React.createElement(InputGroupStylesProvider, {
    value: styles
  }, clones));
});
InputGroup.displayName = "InputGroup";

// src/input-addon.tsx
var placements = {
  left: {
    marginEnd: "-1px",
    borderEndRadius: 0,
    borderEndColor: "transparent"
  },
  right: {
    marginStart: "-1px",
    borderStartRadius: 0,
    borderStartColor: "transparent"
  }
};
var StyledAddon = chakra3("div", {
  baseStyle: {
    flex: "0 0 auto",
    width: "auto",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap"
  }
});
var InputAddon = forwardRef3(function InputAddon2(props, ref) {
  const { placement = "left", ...rest } = props;
  const placementStyles = placements[placement] ?? {};
  const styles = useInputGroupStyles();
  return /* @__PURE__ */ React.createElement(StyledAddon, {
    ref,
    ...rest,
    __css: {
      ...styles.addon,
      ...placementStyles
    }
  });
});
InputAddon.displayName = "InputAddon";
var InputLeftAddon = forwardRef3(function InputLeftAddon2(props, ref) {
  return /* @__PURE__ */ React.createElement(InputAddon, {
    ref,
    placement: "left",
    ...props,
    className: cx3("chakra-input__left-addon", props.className)
  });
});
InputLeftAddon.displayName = "InputLeftAddon";
InputLeftAddon.id = "InputLeftAddon";
var InputRightAddon = forwardRef3(function InputRightAddon2(props, ref) {
  return /* @__PURE__ */ React.createElement(InputAddon, {
    ref,
    placement: "right",
    ...props,
    className: cx3("chakra-input__right-addon", props.className)
  });
});
InputRightAddon.displayName = "InputRightAddon";
InputRightAddon.id = "InputRightAddon";

// src/input-element.tsx
import {
  chakra as chakra4,
  forwardRef as forwardRef4
} from "@chakra-ui/system";
import { cx as cx4 } from "@chakra-ui/shared-utils";
var StyledInputElement = chakra4("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0",
    zIndex: 2
  }
});
var InputElement = forwardRef4(function InputElement2(props, ref) {
  const { placement = "left", ...rest } = props;
  const styles = useInputGroupStyles();
  const input = styles.field;
  const attr = placement === "left" ? "insetStart" : "insetEnd";
  const elementStyles = {
    [attr]: "0",
    width: (input == null ? void 0 : input.height) ?? (input == null ? void 0 : input.h),
    height: (input == null ? void 0 : input.height) ?? (input == null ? void 0 : input.h),
    fontSize: input == null ? void 0 : input.fontSize,
    ...styles.element
  };
  return /* @__PURE__ */ React.createElement(StyledInputElement, {
    ref,
    __css: elementStyles,
    ...rest
  });
});
InputElement.id = "InputElement";
InputElement.displayName = "InputElement";
var InputLeftElement = forwardRef4(function InputLeftElement2(props, ref) {
  const { className, ...rest } = props;
  const _className = cx4("chakra-input__left-element", className);
  return /* @__PURE__ */ React.createElement(InputElement, {
    ref,
    placement: "left",
    className: _className,
    ...rest
  });
});
InputLeftElement.id = "InputLeftElement";
InputLeftElement.displayName = "InputLeftElement";
var InputRightElement = forwardRef4(function InputRightElement2(props, ref) {
  const { className, ...rest } = props;
  const _className = cx4("chakra-input__right-element", className);
  return /* @__PURE__ */ React.createElement(InputElement, {
    ref,
    placement: "right",
    className: _className,
    ...rest
  });
});
InputRightElement.id = "InputRightElement";
InputRightElement.displayName = "InputRightElement";
export {
  Input,
  InputAddon,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  useInputGroupStyles
};
