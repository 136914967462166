import { __assign, __awaiter, __generator, __values } from "tslib";
export function ssecMiddleware(options) {
    var _this = this;
    return function (next) {
        return function (args) { return __awaiter(_this, void 0, void 0, function () {
            var input, properties, properties_1, properties_1_1, prop, value, valueView, encoded, hash, _a, _b, _c, _d, e_1_1;
            var e_1, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        input = __assign({}, args.input);
                        properties = [
                            {
                                target: "SSECustomerKey",
                                hash: "SSECustomerKeyMD5",
                            },
                            {
                                target: "CopySourceSSECustomerKey",
                                hash: "CopySourceSSECustomerKeyMD5",
                            },
                        ];
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 6, 7, 8]);
                        properties_1 = __values(properties), properties_1_1 = properties_1.next();
                        _g.label = 2;
                    case 2:
                        if (!!properties_1_1.done) return [3, 5];
                        prop = properties_1_1.value;
                        value = input[prop.target];
                        if (!value) return [3, 4];
                        valueView = ArrayBuffer.isView(value)
                            ? new Uint8Array(value.buffer, value.byteOffset, value.byteLength)
                            : typeof value === "string"
                                ? options.utf8Decoder(value)
                                : new Uint8Array(value);
                        encoded = options.base64Encoder(valueView);
                        hash = new options.md5();
                        hash.update(valueView);
                        _a = [__assign({}, input)];
                        _f = {}, _f[prop.target] = encoded;
                        _b = prop.hash;
                        _d = (_c = options).base64Encoder;
                        return [4, hash.digest()];
                    case 3:
                        input = __assign.apply(void 0, _a.concat([(_f[_b] = _d.apply(_c, [_g.sent()]), _f)]));
                        _g.label = 4;
                    case 4:
                        properties_1_1 = properties_1.next();
                        return [3, 2];
                    case 5: return [3, 8];
                    case 6:
                        e_1_1 = _g.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 8];
                    case 7:
                        try {
                            if (properties_1_1 && !properties_1_1.done && (_e = properties_1.return)) _e.call(properties_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 8: return [2, next(__assign(__assign({}, args), { input: input }))];
                }
            });
        }); };
    };
}
export var ssecMiddlewareOptions = {
    name: "ssecMiddleware",
    step: "initialize",
    tags: ["SSE"],
    override: true,
};
export var getSsecPlugin = function (config) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(ssecMiddleware(config), ssecMiddlewareOptions);
    },
}); };
