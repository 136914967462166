import { __awaiter, __generator } from "tslib";
export var getAwsChunkedEncodingStream = function (readableStream, options) {
    var base64Encoder = options.base64Encoder, bodyLengthChecker = options.bodyLengthChecker, checksumAlgorithmFn = options.checksumAlgorithmFn, checksumLocationName = options.checksumLocationName, streamHasher = options.streamHasher;
    var checksumRequired = base64Encoder !== undefined &&
        bodyLengthChecker !== undefined &&
        checksumAlgorithmFn !== undefined &&
        checksumLocationName !== undefined &&
        streamHasher !== undefined;
    var digest = checksumRequired ? streamHasher(checksumAlgorithmFn, readableStream) : undefined;
    var reader = readableStream.getReader();
    return new ReadableStream({
        pull: function (controller) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, value, done, checksum, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4, reader.read()];
                        case 1:
                            _a = _c.sent(), value = _a.value, done = _a.done;
                            if (!done) return [3, 4];
                            controller.enqueue("0\r\n");
                            if (!checksumRequired) return [3, 3];
                            _b = base64Encoder;
                            return [4, digest];
                        case 2:
                            checksum = _b.apply(void 0, [_c.sent()]);
                            controller.enqueue("".concat(checksumLocationName, ":").concat(checksum, "\r\n"));
                            controller.enqueue("\r\n");
                            _c.label = 3;
                        case 3:
                            controller.close();
                            return [3, 5];
                        case 4:
                            controller.enqueue("".concat((bodyLengthChecker(value) || 0).toString(16), "\r\n").concat(value, "\r\n"));
                            _c.label = 5;
                        case 5: return [2];
                    }
                });
            });
        },
    });
};
