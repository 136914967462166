import { __values } from "tslib";
import { escapeAttribute } from "./escape-attribute";
import { XmlText } from "./XmlText";
var XmlNode = (function () {
    function XmlNode(name, children) {
        if (children === void 0) { children = []; }
        this.name = name;
        this.children = children;
        this.attributes = {};
    }
    XmlNode.of = function (name, childText, withName) {
        var node = new XmlNode(name);
        if (childText !== undefined) {
            node.addChildNode(new XmlText(childText));
        }
        if (withName !== undefined) {
            node.withName(withName);
        }
        return node;
    };
    XmlNode.prototype.withName = function (name) {
        this.name = name;
        return this;
    };
    XmlNode.prototype.addAttribute = function (name, value) {
        this.attributes[name] = value;
        return this;
    };
    XmlNode.prototype.addChildNode = function (child) {
        this.children.push(child);
        return this;
    };
    XmlNode.prototype.removeAttribute = function (name) {
        delete this.attributes[name];
        return this;
    };
    XmlNode.prototype.toString = function () {
        var e_1, _a;
        var hasChildren = Boolean(this.children.length);
        var xmlText = "<".concat(this.name);
        var attributes = this.attributes;
        try {
            for (var _b = __values(Object.keys(attributes)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var attributeName = _c.value;
                var attribute = attributes[attributeName];
                if (typeof attribute !== "undefined" && attribute !== null) {
                    xmlText += " ".concat(attributeName, "=\"").concat(escapeAttribute("" + attribute), "\"");
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return (xmlText += !hasChildren ? "/>" : ">".concat(this.children.map(function (c) { return c.toString(); }).join(""), "</").concat(this.name, ">"));
    };
    return XmlNode;
}());
export { XmlNode };
