import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { parse as parseArn, validate as validateArn } from "@aws-sdk/util-arn-parser";
import { bucketHostname } from "./bucketHostname";
export var bucketEndpointMiddleware = function (options) {
    return function (next, context) {
        return function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var bucketName, replaceBucketInPath, request, bucketArn, clientRegion, useDualstackEndpoint, useFipsEndpoint, _a, partition, _b, signingRegion, useArnRegion, _c, hostname, bucketEndpoint, modifiedSigningRegion, signingService, _d, clientRegion, dualstackEndpoint, fipsEndpoint, _e, hostname, bucketEndpoint;
            var _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        bucketName = args.input.Bucket;
                        replaceBucketInPath = options.bucketEndpoint;
                        request = args.request;
                        if (!HttpRequest.isInstance(request)) return [3, 13];
                        if (!options.bucketEndpoint) return [3, 1];
                        request.hostname = bucketName;
                        return [3, 12];
                    case 1:
                        if (!validateArn(bucketName)) return [3, 8];
                        bucketArn = parseArn(bucketName);
                        return [4, options.region()];
                    case 2:
                        clientRegion = _g.sent();
                        return [4, options.useDualstackEndpoint()];
                    case 3:
                        useDualstackEndpoint = _g.sent();
                        return [4, options.useFipsEndpoint()];
                    case 4:
                        useFipsEndpoint = _g.sent();
                        return [4, options.regionInfoProvider(clientRegion, { useDualstackEndpoint: useDualstackEndpoint, useFipsEndpoint: useFipsEndpoint })];
                    case 5:
                        _a = (_g.sent()) || {}, partition = _a.partition, _b = _a.signingRegion, signingRegion = _b === void 0 ? clientRegion : _b;
                        return [4, options.useArnRegion()];
                    case 6:
                        useArnRegion = _g.sent();
                        _d = bucketHostname;
                        _f = {
                            bucketName: bucketArn,
                            baseHostname: request.hostname,
                            accelerateEndpoint: options.useAccelerateEndpoint,
                            dualstackEndpoint: useDualstackEndpoint,
                            fipsEndpoint: useFipsEndpoint,
                            pathStyleEndpoint: options.forcePathStyle,
                            tlsCompatible: request.protocol === "https:",
                            useArnRegion: useArnRegion,
                            clientPartition: partition,
                            clientSigningRegion: signingRegion,
                            clientRegion: clientRegion,
                            isCustomEndpoint: options.isCustomEndpoint
                        };
                        return [4, options.disableMultiregionAccessPoints()];
                    case 7:
                        _c = _d.apply(void 0, [(_f.disableMultiregionAccessPoints = _g.sent(),
                                _f)]), hostname = _c.hostname, bucketEndpoint = _c.bucketEndpoint, modifiedSigningRegion = _c.signingRegion, signingService = _c.signingService;
                        if (modifiedSigningRegion && modifiedSigningRegion !== signingRegion) {
                            context["signing_region"] = modifiedSigningRegion;
                        }
                        if (signingService && signingService !== "s3") {
                            context["signing_service"] = signingService;
                        }
                        request.hostname = hostname;
                        replaceBucketInPath = bucketEndpoint;
                        return [3, 12];
                    case 8: return [4, options.region()];
                    case 9:
                        clientRegion = _g.sent();
                        return [4, options.useDualstackEndpoint()];
                    case 10:
                        dualstackEndpoint = _g.sent();
                        return [4, options.useFipsEndpoint()];
                    case 11:
                        fipsEndpoint = _g.sent();
                        _e = bucketHostname({
                            bucketName: bucketName,
                            clientRegion: clientRegion,
                            baseHostname: request.hostname,
                            accelerateEndpoint: options.useAccelerateEndpoint,
                            dualstackEndpoint: dualstackEndpoint,
                            fipsEndpoint: fipsEndpoint,
                            pathStyleEndpoint: options.forcePathStyle,
                            tlsCompatible: request.protocol === "https:",
                            isCustomEndpoint: options.isCustomEndpoint,
                        }), hostname = _e.hostname, bucketEndpoint = _e.bucketEndpoint;
                        request.hostname = hostname;
                        replaceBucketInPath = bucketEndpoint;
                        _g.label = 12;
                    case 12:
                        if (replaceBucketInPath) {
                            request.path = request.path.replace(/^(\/)?[^\/]+/, "");
                            if (request.path === "") {
                                request.path = "/";
                            }
                        }
                        _g.label = 13;
                    case 13: return [2, next(__assign(__assign({}, args), { request: request }))];
                }
            });
        }); };
    };
};
export var bucketEndpointMiddlewareOptions = {
    tags: ["BUCKET_ENDPOINT"],
    name: "bucketEndpointMiddleware",
    relation: "before",
    toMiddleware: "hostHeaderMiddleware",
    override: true,
};
export var getBucketEndpointPlugin = function (options) { return ({
    applyToStack: function (clientStack) {
        clientStack.addRelativeTo(bucketEndpointMiddleware(options), bucketEndpointMiddlewareOptions);
    },
}); };
