import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest as __HttpRequest, isValidHostname as __isValidHostname, } from "@aws-sdk/protocol-http";
import { dateToUtcString as __dateToUtcString, decorateServiceException as __decorateServiceException, expectNonNull as __expectNonNull, expectObject as __expectObject, expectString as __expectString, expectUnion as __expectUnion, getArrayIfSingleItem as __getArrayIfSingleItem, getValueFromTextNode as __getValueFromTextNode, map as __map, parseBoolean as __parseBoolean, parseRfc3339DateTime as __parseRfc3339DateTime, parseRfc7231DateTime as __parseRfc7231DateTime, resolvedPath as __resolvedPath, strictParseInt32 as __strictParseInt32, strictParseLong as __strictParseLong, throwDefaultError, } from "@aws-sdk/smithy-client";
import { XmlNode as __XmlNode, XmlText as __XmlText } from "@aws-sdk/xml-builder";
import { decodeHTML } from "entities";
import { parse as xmlParse } from "fast-xml-parser";
import { AnalyticsFilter, BucketAlreadyExists, BucketAlreadyOwnedByYou, InvalidObjectState, LifecycleRuleFilter, MetricsFilter, NoSuchBucket, NoSuchKey, NoSuchUpload, NotFound, ObjectNotInActiveTierError, ReplicationRuleFilter, } from "../models/models_0";
import { ObjectAlreadyInActiveTierError, } from "../models/models_1";
import { S3ServiceException as __BaseException } from "../models/S3ServiceException";
export var serializeAws_restXmlAbortMultipartUploadCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "AbortMultipartUpload"],
                    uploadId: [, input.UploadId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlCompleteMultipartUploadCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-checksum-crc32": input.ChecksumCRC32,
                    "x-amz-checksum-crc32c": input.ChecksumCRC32C,
                    "x-amz-checksum-sha1": input.ChecksumSHA1,
                    "x-amz-checksum-sha256": input.ChecksumSHA256,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "CompleteMultipartUpload"],
                    uploadId: [, input.UploadId],
                });
                if (input.MultipartUpload !== undefined) {
                    body = serializeAws_restXmlCompletedMultipartUpload(input.MultipartUpload, context);
                }
                if (input.MultipartUpload !== undefined) {
                    contents = serializeAws_restXmlCompletedMultipartUpload(input.MultipartUpload, context);
                    contents = contents.withName("CompleteMultipartUpload");
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlCopyObjectCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, __assign({ "x-amz-acl": input.ACL, "cache-control": input.CacheControl, "x-amz-checksum-algorithm": input.ChecksumAlgorithm, "content-disposition": input.ContentDisposition, "content-encoding": input.ContentEncoding, "content-language": input.ContentLanguage, "content-type": input.ContentType, "x-amz-copy-source": input.CopySource, "x-amz-copy-source-if-match": input.CopySourceIfMatch, "x-amz-copy-source-if-modified-since": [
                        function () { return isSerializableHeaderValue(input.CopySourceIfModifiedSince); },
                        function () { return __dateToUtcString(input.CopySourceIfModifiedSince).toString(); },
                    ], "x-amz-copy-source-if-none-match": input.CopySourceIfNoneMatch, "x-amz-copy-source-if-unmodified-since": [
                        function () { return isSerializableHeaderValue(input.CopySourceIfUnmodifiedSince); },
                        function () { return __dateToUtcString(input.CopySourceIfUnmodifiedSince).toString(); },
                    ], expires: [function () { return isSerializableHeaderValue(input.Expires); }, function () { return __dateToUtcString(input.Expires).toString(); }], "x-amz-grant-full-control": input.GrantFullControl, "x-amz-grant-read": input.GrantRead, "x-amz-grant-read-acp": input.GrantReadACP, "x-amz-grant-write-acp": input.GrantWriteACP, "x-amz-metadata-directive": input.MetadataDirective, "x-amz-tagging-directive": input.TaggingDirective, "x-amz-server-side-encryption": input.ServerSideEncryption, "x-amz-storage-class": input.StorageClass, "x-amz-website-redirect-location": input.WebsiteRedirectLocation, "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm, "x-amz-server-side-encryption-customer-key": input.SSECustomerKey, "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5, "x-amz-server-side-encryption-aws-kms-key-id": input.SSEKMSKeyId, "x-amz-server-side-encryption-context": input.SSEKMSEncryptionContext, "x-amz-server-side-encryption-bucket-key-enabled": [
                        function () { return isSerializableHeaderValue(input.BucketKeyEnabled); },
                        function () { return input.BucketKeyEnabled.toString(); },
                    ], "x-amz-copy-source-server-side-encryption-customer-algorithm": input.CopySourceSSECustomerAlgorithm, "x-amz-copy-source-server-side-encryption-customer-key": input.CopySourceSSECustomerKey, "x-amz-copy-source-server-side-encryption-customer-key-md5": input.CopySourceSSECustomerKeyMD5, "x-amz-request-payer": input.RequestPayer, "x-amz-tagging": input.Tagging, "x-amz-object-lock-mode": input.ObjectLockMode, "x-amz-object-lock-retain-until-date": [
                        function () { return isSerializableHeaderValue(input.ObjectLockRetainUntilDate); },
                        function () { return (input.ObjectLockRetainUntilDate.toISOString().split(".")[0] + "Z").toString(); },
                    ], "x-amz-object-lock-legal-hold": input.ObjectLockLegalHoldStatus, "x-amz-expected-bucket-owner": input.ExpectedBucketOwner, "x-amz-source-expected-bucket-owner": input.ExpectedSourceBucketOwner }, (input.Metadata !== undefined &&
                    Object.keys(input.Metadata).reduce(function (acc, suffix) {
                        var _a;
                        return (__assign(__assign({}, acc), (_a = {}, _a["x-amz-meta-".concat(suffix.toLowerCase())] = input.Metadata[suffix], _a)));
                    }, {}))));
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "CopyObject"],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlCreateBucketCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-acl": input.ACL,
                    "x-amz-grant-full-control": input.GrantFullControl,
                    "x-amz-grant-read": input.GrantRead,
                    "x-amz-grant-read-acp": input.GrantReadACP,
                    "x-amz-grant-write": input.GrantWrite,
                    "x-amz-grant-write-acp": input.GrantWriteACP,
                    "x-amz-bucket-object-lock-enabled": [
                        function () { return isSerializableHeaderValue(input.ObjectLockEnabledForBucket); },
                        function () { return input.ObjectLockEnabledForBucket.toString(); },
                    ],
                    "x-amz-object-ownership": input.ObjectOwnership,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                if (input.CreateBucketConfiguration !== undefined) {
                    body = serializeAws_restXmlCreateBucketConfiguration(input.CreateBucketConfiguration, context);
                }
                if (input.CreateBucketConfiguration !== undefined) {
                    contents = serializeAws_restXmlCreateBucketConfiguration(input.CreateBucketConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlCreateMultipartUploadCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, __assign({ "x-amz-acl": input.ACL, "cache-control": input.CacheControl, "content-disposition": input.ContentDisposition, "content-encoding": input.ContentEncoding, "content-language": input.ContentLanguage, "content-type": input.ContentType, expires: [function () { return isSerializableHeaderValue(input.Expires); }, function () { return __dateToUtcString(input.Expires).toString(); }], "x-amz-grant-full-control": input.GrantFullControl, "x-amz-grant-read": input.GrantRead, "x-amz-grant-read-acp": input.GrantReadACP, "x-amz-grant-write-acp": input.GrantWriteACP, "x-amz-server-side-encryption": input.ServerSideEncryption, "x-amz-storage-class": input.StorageClass, "x-amz-website-redirect-location": input.WebsiteRedirectLocation, "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm, "x-amz-server-side-encryption-customer-key": input.SSECustomerKey, "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5, "x-amz-server-side-encryption-aws-kms-key-id": input.SSEKMSKeyId, "x-amz-server-side-encryption-context": input.SSEKMSEncryptionContext, "x-amz-server-side-encryption-bucket-key-enabled": [
                        function () { return isSerializableHeaderValue(input.BucketKeyEnabled); },
                        function () { return input.BucketKeyEnabled.toString(); },
                    ], "x-amz-request-payer": input.RequestPayer, "x-amz-tagging": input.Tagging, "x-amz-object-lock-mode": input.ObjectLockMode, "x-amz-object-lock-retain-until-date": [
                        function () { return isSerializableHeaderValue(input.ObjectLockRetainUntilDate); },
                        function () { return (input.ObjectLockRetainUntilDate.toISOString().split(".")[0] + "Z").toString(); },
                    ], "x-amz-object-lock-legal-hold": input.ObjectLockLegalHoldStatus, "x-amz-expected-bucket-owner": input.ExpectedBucketOwner, "x-amz-checksum-algorithm": input.ChecksumAlgorithm }, (input.Metadata !== undefined &&
                    Object.keys(input.Metadata).reduce(function (acc, suffix) {
                        var _a;
                        return (__assign(__assign({}, acc), (_a = {}, _a["x-amz-meta-".concat(suffix.toLowerCase())] = input.Metadata[suffix], _a)));
                    }, {}))));
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    uploads: [, ""],
                    "x-id": [, "CreateMultipartUpload"],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketAnalyticsConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    analytics: [, ""],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketCorsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    cors: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketEncryptionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    encryption: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketIntelligentTieringConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "intelligent-tiering": [, ""],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketInventoryConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    inventory: [, ""],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketLifecycleCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    lifecycle: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketMetricsConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    metrics: [, ""],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketOwnershipControlsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    ownershipControls: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketPolicyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    policy: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketReplicationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    replication: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketTaggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    tagging: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteBucketWebsiteCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    website: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteObjectCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-mfa": input.MFA,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-bypass-governance-retention": [
                        function () { return isSerializableHeaderValue(input.BypassGovernanceRetention); },
                        function () { return input.BypassGovernanceRetention.toString(); },
                    ],
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "DeleteObject"],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteObjectsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-mfa": input.MFA,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-bypass-governance-retention": [
                        function () { return isSerializableHeaderValue(input.BypassGovernanceRetention); },
                        function () { return input.BypassGovernanceRetention.toString(); },
                    ],
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    delete: [, ""],
                    "x-id": [, "DeleteObjects"],
                });
                if (input.Delete !== undefined) {
                    body = serializeAws_restXmlDelete(input.Delete, context);
                }
                if (input.Delete !== undefined) {
                    contents = serializeAws_restXmlDelete(input.Delete, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeleteObjectTaggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    tagging: [, ""],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlDeletePublicAccessBlockCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    publicAccessBlock: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketAccelerateConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    accelerate: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketAclCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    acl: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketAnalyticsConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    analytics: [, ""],
                    "x-id": [, "GetBucketAnalyticsConfiguration"],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketCorsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    cors: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketEncryptionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    encryption: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketIntelligentTieringConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "intelligent-tiering": [, ""],
                    "x-id": [, "GetBucketIntelligentTieringConfiguration"],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketInventoryConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    inventory: [, ""],
                    "x-id": [, "GetBucketInventoryConfiguration"],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketLifecycleConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    lifecycle: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketLocationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    location: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketLoggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    logging: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketMetricsConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    metrics: [, ""],
                    "x-id": [, "GetBucketMetricsConfiguration"],
                    id: [, input.Id],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketNotificationConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    notification: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketOwnershipControlsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    ownershipControls: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketPolicyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    policy: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketPolicyStatusCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    policyStatus: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketReplicationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    replication: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketRequestPaymentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    requestPayment: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketTaggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    tagging: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketVersioningCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    versioning: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetBucketWebsiteCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    website: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "if-match": input.IfMatch,
                    "if-modified-since": [
                        function () { return isSerializableHeaderValue(input.IfModifiedSince); },
                        function () { return __dateToUtcString(input.IfModifiedSince).toString(); },
                    ],
                    "if-none-match": input.IfNoneMatch,
                    "if-unmodified-since": [
                        function () { return isSerializableHeaderValue(input.IfUnmodifiedSince); },
                        function () { return __dateToUtcString(input.IfUnmodifiedSince).toString(); },
                    ],
                    range: input.Range,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-checksum-mode": input.ChecksumMode,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "GetObject"],
                    "response-cache-control": [, input.ResponseCacheControl],
                    "response-content-disposition": [, input.ResponseContentDisposition],
                    "response-content-encoding": [, input.ResponseContentEncoding],
                    "response-content-language": [, input.ResponseContentLanguage],
                    "response-content-type": [, input.ResponseContentType],
                    "response-expires": [
                        function () { return input.ResponseExpires !== void 0; },
                        function () { return __dateToUtcString(input.ResponseExpires).toString(); },
                    ],
                    versionId: [, input.VersionId],
                    partNumber: [function () { return input.PartNumber !== void 0; }, function () { return input.PartNumber.toString(); }],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectAclCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    acl: [, ""],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-max-parts": [function () { return isSerializableHeaderValue(input.MaxParts); }, function () { return input.MaxParts.toString(); }],
                    "x-amz-part-number-marker": input.PartNumberMarker,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-object-attributes": [
                        function () { return isSerializableHeaderValue(input.ObjectAttributes); },
                        function () { return (input.ObjectAttributes || []).map(function (_entry) { return _entry; }).join(", "); },
                    ],
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    attributes: [, ""],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectLegalHoldCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "legal-hold": [, ""],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectLockConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "object-lock": [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectRetentionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    retention: [, ""],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectTaggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-request-payer": input.RequestPayer,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    tagging: [, ""],
                    versionId: [, input.VersionId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetObjectTorrentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    torrent: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlGetPublicAccessBlockCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    publicAccessBlock: [, ""],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlHeadBucketCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "HEAD",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlHeadObjectCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "if-match": input.IfMatch,
                    "if-modified-since": [
                        function () { return isSerializableHeaderValue(input.IfModifiedSince); },
                        function () { return __dateToUtcString(input.IfModifiedSince).toString(); },
                    ],
                    "if-none-match": input.IfNoneMatch,
                    "if-unmodified-since": [
                        function () { return isSerializableHeaderValue(input.IfUnmodifiedSince); },
                        function () { return __dateToUtcString(input.IfUnmodifiedSince).toString(); },
                    ],
                    range: input.Range,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-checksum-mode": input.ChecksumMode,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    versionId: [, input.VersionId],
                    partNumber: [function () { return input.PartNumber !== void 0; }, function () { return input.PartNumber.toString(); }],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "HEAD",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListBucketAnalyticsConfigurationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    analytics: [, ""],
                    "x-id": [, "ListBucketAnalyticsConfigurations"],
                    "continuation-token": [, input.ContinuationToken],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListBucketIntelligentTieringConfigurationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "intelligent-tiering": [, ""],
                    "x-id": [, "ListBucketIntelligentTieringConfigurations"],
                    "continuation-token": [, input.ContinuationToken],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListBucketInventoryConfigurationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    inventory: [, ""],
                    "x-id": [, "ListBucketInventoryConfigurations"],
                    "continuation-token": [, input.ContinuationToken],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListBucketMetricsConfigurationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    metrics: [, ""],
                    "x-id": [, "ListBucketMetricsConfigurations"],
                    "continuation-token": [, input.ContinuationToken],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListBucketsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/xml",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/";
                body = "";
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListMultipartUploadsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    uploads: [, ""],
                    delimiter: [, input.Delimiter],
                    "encoding-type": [, input.EncodingType],
                    "key-marker": [, input.KeyMarker],
                    "max-uploads": [function () { return input.MaxUploads !== void 0; }, function () { return input.MaxUploads.toString(); }],
                    prefix: [, input.Prefix],
                    "upload-id-marker": [, input.UploadIdMarker],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListObjectsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    delimiter: [, input.Delimiter],
                    "encoding-type": [, input.EncodingType],
                    marker: [, input.Marker],
                    "max-keys": [function () { return input.MaxKeys !== void 0; }, function () { return input.MaxKeys.toString(); }],
                    prefix: [, input.Prefix],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListObjectsV2Command = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "list-type": [, "2"],
                    delimiter: [, input.Delimiter],
                    "encoding-type": [, input.EncodingType],
                    "max-keys": [function () { return input.MaxKeys !== void 0; }, function () { return input.MaxKeys.toString(); }],
                    prefix: [, input.Prefix],
                    "continuation-token": [, input.ContinuationToken],
                    "fetch-owner": [function () { return input.FetchOwner !== void 0; }, function () { return input.FetchOwner.toString(); }],
                    "start-after": [, input.StartAfter],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListObjectVersionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    versions: [, ""],
                    delimiter: [, input.Delimiter],
                    "encoding-type": [, input.EncodingType],
                    "key-marker": [, input.KeyMarker],
                    "max-keys": [function () { return input.MaxKeys !== void 0; }, function () { return input.MaxKeys.toString(); }],
                    prefix: [, input.Prefix],
                    "version-id-marker": [, input.VersionIdMarker],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlListPartsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "ListParts"],
                    "max-parts": [function () { return input.MaxParts !== void 0; }, function () { return input.MaxParts.toString(); }],
                    "part-number-marker": [, input.PartNumberMarker],
                    uploadId: [, input.UploadId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketAccelerateConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    accelerate: [, ""],
                });
                if (input.AccelerateConfiguration !== undefined) {
                    body = serializeAws_restXmlAccelerateConfiguration(input.AccelerateConfiguration, context);
                }
                if (input.AccelerateConfiguration !== undefined) {
                    contents = serializeAws_restXmlAccelerateConfiguration(input.AccelerateConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketAclCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-acl": input.ACL,
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-grant-full-control": input.GrantFullControl,
                    "x-amz-grant-read": input.GrantRead,
                    "x-amz-grant-read-acp": input.GrantReadACP,
                    "x-amz-grant-write": input.GrantWrite,
                    "x-amz-grant-write-acp": input.GrantWriteACP,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    acl: [, ""],
                });
                if (input.AccessControlPolicy !== undefined) {
                    body = serializeAws_restXmlAccessControlPolicy(input.AccessControlPolicy, context);
                }
                if (input.AccessControlPolicy !== undefined) {
                    contents = serializeAws_restXmlAccessControlPolicy(input.AccessControlPolicy, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketAnalyticsConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    analytics: [, ""],
                    id: [, input.Id],
                });
                if (input.AnalyticsConfiguration !== undefined) {
                    body = serializeAws_restXmlAnalyticsConfiguration(input.AnalyticsConfiguration, context);
                }
                if (input.AnalyticsConfiguration !== undefined) {
                    contents = serializeAws_restXmlAnalyticsConfiguration(input.AnalyticsConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketCorsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    cors: [, ""],
                });
                if (input.CORSConfiguration !== undefined) {
                    body = serializeAws_restXmlCORSConfiguration(input.CORSConfiguration, context);
                }
                if (input.CORSConfiguration !== undefined) {
                    contents = serializeAws_restXmlCORSConfiguration(input.CORSConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketEncryptionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    encryption: [, ""],
                });
                if (input.ServerSideEncryptionConfiguration !== undefined) {
                    body = serializeAws_restXmlServerSideEncryptionConfiguration(input.ServerSideEncryptionConfiguration, context);
                }
                if (input.ServerSideEncryptionConfiguration !== undefined) {
                    contents = serializeAws_restXmlServerSideEncryptionConfiguration(input.ServerSideEncryptionConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketIntelligentTieringConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/xml",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "intelligent-tiering": [, ""],
                    id: [, input.Id],
                });
                if (input.IntelligentTieringConfiguration !== undefined) {
                    body = serializeAws_restXmlIntelligentTieringConfiguration(input.IntelligentTieringConfiguration, context);
                }
                if (input.IntelligentTieringConfiguration !== undefined) {
                    contents = serializeAws_restXmlIntelligentTieringConfiguration(input.IntelligentTieringConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketInventoryConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    inventory: [, ""],
                    id: [, input.Id],
                });
                if (input.InventoryConfiguration !== undefined) {
                    body = serializeAws_restXmlInventoryConfiguration(input.InventoryConfiguration, context);
                }
                if (input.InventoryConfiguration !== undefined) {
                    contents = serializeAws_restXmlInventoryConfiguration(input.InventoryConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketLifecycleConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    lifecycle: [, ""],
                });
                if (input.LifecycleConfiguration !== undefined) {
                    body = serializeAws_restXmlBucketLifecycleConfiguration(input.LifecycleConfiguration, context);
                }
                if (input.LifecycleConfiguration !== undefined) {
                    contents = serializeAws_restXmlBucketLifecycleConfiguration(input.LifecycleConfiguration, context);
                    contents = contents.withName("LifecycleConfiguration");
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketLoggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    logging: [, ""],
                });
                if (input.BucketLoggingStatus !== undefined) {
                    body = serializeAws_restXmlBucketLoggingStatus(input.BucketLoggingStatus, context);
                }
                if (input.BucketLoggingStatus !== undefined) {
                    contents = serializeAws_restXmlBucketLoggingStatus(input.BucketLoggingStatus, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketMetricsConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    metrics: [, ""],
                    id: [, input.Id],
                });
                if (input.MetricsConfiguration !== undefined) {
                    body = serializeAws_restXmlMetricsConfiguration(input.MetricsConfiguration, context);
                }
                if (input.MetricsConfiguration !== undefined) {
                    contents = serializeAws_restXmlMetricsConfiguration(input.MetricsConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketNotificationConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-skip-destination-validation": [
                        function () { return isSerializableHeaderValue(input.SkipDestinationValidation); },
                        function () { return input.SkipDestinationValidation.toString(); },
                    ],
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    notification: [, ""],
                });
                if (input.NotificationConfiguration !== undefined) {
                    body = serializeAws_restXmlNotificationConfiguration(input.NotificationConfiguration, context);
                }
                if (input.NotificationConfiguration !== undefined) {
                    contents = serializeAws_restXmlNotificationConfiguration(input.NotificationConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketOwnershipControlsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    ownershipControls: [, ""],
                });
                if (input.OwnershipControls !== undefined) {
                    body = serializeAws_restXmlOwnershipControls(input.OwnershipControls, context);
                }
                if (input.OwnershipControls !== undefined) {
                    contents = serializeAws_restXmlOwnershipControls(input.OwnershipControls, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketPolicyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "text/plain",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-confirm-remove-self-bucket-access": [
                        function () { return isSerializableHeaderValue(input.ConfirmRemoveSelfBucketAccess); },
                        function () { return input.ConfirmRemoveSelfBucketAccess.toString(); },
                    ],
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    policy: [, ""],
                });
                if (input.Policy !== undefined) {
                    body = input.Policy;
                }
                if (input.Policy !== undefined) {
                    contents = input.Policy;
                    body = contents;
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketReplicationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-bucket-object-lock-token": input.Token,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    replication: [, ""],
                });
                if (input.ReplicationConfiguration !== undefined) {
                    body = serializeAws_restXmlReplicationConfiguration(input.ReplicationConfiguration, context);
                }
                if (input.ReplicationConfiguration !== undefined) {
                    contents = serializeAws_restXmlReplicationConfiguration(input.ReplicationConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketRequestPaymentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    requestPayment: [, ""],
                });
                if (input.RequestPaymentConfiguration !== undefined) {
                    body = serializeAws_restXmlRequestPaymentConfiguration(input.RequestPaymentConfiguration, context);
                }
                if (input.RequestPaymentConfiguration !== undefined) {
                    contents = serializeAws_restXmlRequestPaymentConfiguration(input.RequestPaymentConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketTaggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    tagging: [, ""],
                });
                if (input.Tagging !== undefined) {
                    body = serializeAws_restXmlTagging(input.Tagging, context);
                }
                if (input.Tagging !== undefined) {
                    contents = serializeAws_restXmlTagging(input.Tagging, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketVersioningCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-mfa": input.MFA,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    versioning: [, ""],
                });
                if (input.VersioningConfiguration !== undefined) {
                    body = serializeAws_restXmlVersioningConfiguration(input.VersioningConfiguration, context);
                }
                if (input.VersioningConfiguration !== undefined) {
                    contents = serializeAws_restXmlVersioningConfiguration(input.VersioningConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutBucketWebsiteCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    website: [, ""],
                });
                if (input.WebsiteConfiguration !== undefined) {
                    body = serializeAws_restXmlWebsiteConfiguration(input.WebsiteConfiguration, context);
                }
                if (input.WebsiteConfiguration !== undefined) {
                    contents = serializeAws_restXmlWebsiteConfiguration(input.WebsiteConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutObjectCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, __assign({ "content-type": input.ContentType || "application/octet-stream", "x-amz-acl": input.ACL, "cache-control": input.CacheControl, "content-disposition": input.ContentDisposition, "content-encoding": input.ContentEncoding, "content-language": input.ContentLanguage, "content-length": [function () { return isSerializableHeaderValue(input.ContentLength); }, function () { return input.ContentLength.toString(); }], "content-md5": input.ContentMD5, "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm, "x-amz-checksum-crc32": input.ChecksumCRC32, "x-amz-checksum-crc32c": input.ChecksumCRC32C, "x-amz-checksum-sha1": input.ChecksumSHA1, "x-amz-checksum-sha256": input.ChecksumSHA256, expires: [function () { return isSerializableHeaderValue(input.Expires); }, function () { return __dateToUtcString(input.Expires).toString(); }], "x-amz-grant-full-control": input.GrantFullControl, "x-amz-grant-read": input.GrantRead, "x-amz-grant-read-acp": input.GrantReadACP, "x-amz-grant-write-acp": input.GrantWriteACP, "x-amz-server-side-encryption": input.ServerSideEncryption, "x-amz-storage-class": input.StorageClass, "x-amz-website-redirect-location": input.WebsiteRedirectLocation, "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm, "x-amz-server-side-encryption-customer-key": input.SSECustomerKey, "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5, "x-amz-server-side-encryption-aws-kms-key-id": input.SSEKMSKeyId, "x-amz-server-side-encryption-context": input.SSEKMSEncryptionContext, "x-amz-server-side-encryption-bucket-key-enabled": [
                        function () { return isSerializableHeaderValue(input.BucketKeyEnabled); },
                        function () { return input.BucketKeyEnabled.toString(); },
                    ], "x-amz-request-payer": input.RequestPayer, "x-amz-tagging": input.Tagging, "x-amz-object-lock-mode": input.ObjectLockMode, "x-amz-object-lock-retain-until-date": [
                        function () { return isSerializableHeaderValue(input.ObjectLockRetainUntilDate); },
                        function () { return (input.ObjectLockRetainUntilDate.toISOString().split(".")[0] + "Z").toString(); },
                    ], "x-amz-object-lock-legal-hold": input.ObjectLockLegalHoldStatus, "x-amz-expected-bucket-owner": input.ExpectedBucketOwner }, (input.Metadata !== undefined &&
                    Object.keys(input.Metadata).reduce(function (acc, suffix) {
                        var _a;
                        return (__assign(__assign({}, acc), (_a = {}, _a["x-amz-meta-".concat(suffix.toLowerCase())] = input.Metadata[suffix], _a)));
                    }, {}))));
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "PutObject"],
                });
                if (input.Body !== undefined) {
                    body = input.Body;
                }
                if (input.Body !== undefined) {
                    contents = input.Body;
                    body = contents;
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutObjectAclCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-acl": input.ACL,
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-grant-full-control": input.GrantFullControl,
                    "x-amz-grant-read": input.GrantRead,
                    "x-amz-grant-read-acp": input.GrantReadACP,
                    "x-amz-grant-write": input.GrantWrite,
                    "x-amz-grant-write-acp": input.GrantWriteACP,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    acl: [, ""],
                    versionId: [, input.VersionId],
                });
                if (input.AccessControlPolicy !== undefined) {
                    body = serializeAws_restXmlAccessControlPolicy(input.AccessControlPolicy, context);
                }
                if (input.AccessControlPolicy !== undefined) {
                    contents = serializeAws_restXmlAccessControlPolicy(input.AccessControlPolicy, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutObjectLegalHoldCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-request-payer": input.RequestPayer,
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "legal-hold": [, ""],
                    versionId: [, input.VersionId],
                });
                if (input.LegalHold !== undefined) {
                    body = serializeAws_restXmlObjectLockLegalHold(input.LegalHold, context);
                }
                if (input.LegalHold !== undefined) {
                    contents = serializeAws_restXmlObjectLockLegalHold(input.LegalHold, context);
                    contents = contents.withName("LegalHold");
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutObjectLockConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-bucket-object-lock-token": input.Token,
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    "object-lock": [, ""],
                });
                if (input.ObjectLockConfiguration !== undefined) {
                    body = serializeAws_restXmlObjectLockConfiguration(input.ObjectLockConfiguration, context);
                }
                if (input.ObjectLockConfiguration !== undefined) {
                    contents = serializeAws_restXmlObjectLockConfiguration(input.ObjectLockConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutObjectRetentionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-bypass-governance-retention": [
                        function () { return isSerializableHeaderValue(input.BypassGovernanceRetention); },
                        function () { return input.BypassGovernanceRetention.toString(); },
                    ],
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    retention: [, ""],
                    versionId: [, input.VersionId],
                });
                if (input.Retention !== undefined) {
                    body = serializeAws_restXmlObjectLockRetention(input.Retention, context);
                }
                if (input.Retention !== undefined) {
                    contents = serializeAws_restXmlObjectLockRetention(input.Retention, context);
                    contents = contents.withName("Retention");
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutObjectTaggingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-request-payer": input.RequestPayer,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    tagging: [, ""],
                    versionId: [, input.VersionId],
                });
                if (input.Tagging !== undefined) {
                    body = serializeAws_restXmlTagging(input.Tagging, context);
                }
                if (input.Tagging !== undefined) {
                    contents = serializeAws_restXmlTagging(input.Tagging, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlPutPublicAccessBlockCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                query = map({
                    publicAccessBlock: [, ""],
                });
                if (input.PublicAccessBlockConfiguration !== undefined) {
                    body = serializeAws_restXmlPublicAccessBlockConfiguration(input.PublicAccessBlockConfiguration, context);
                }
                if (input.PublicAccessBlockConfiguration !== undefined) {
                    contents = serializeAws_restXmlPublicAccessBlockConfiguration(input.PublicAccessBlockConfiguration, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlRestoreObjectCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    restore: [, ""],
                    "x-id": [, "RestoreObject"],
                    versionId: [, input.VersionId],
                });
                if (input.RestoreRequest !== undefined) {
                    body = serializeAws_restXmlRestoreRequest(input.RestoreRequest, context);
                }
                if (input.RestoreRequest !== undefined) {
                    contents = serializeAws_restXmlRestoreRequest(input.RestoreRequest, context);
                    body = '<?xml version="1.0" encoding="UTF-8"?>';
                    contents.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                    body += contents.toString();
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlSelectObjectContentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, bodyNode, node, node, node, node, node, node;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/xml",
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    select: [, ""],
                    "select-type": [, "2"],
                    "x-id": [, "SelectObjectContent"],
                });
                body = '<?xml version="1.0" encoding="UTF-8"?>';
                bodyNode = new __XmlNode("SelectObjectContentRequest");
                bodyNode.addAttribute("xmlns", "http://s3.amazonaws.com/doc/2006-03-01/");
                if (input.Expression !== undefined) {
                    node = __XmlNode.of("Expression", input.Expression).withName("Expression");
                    bodyNode.addChildNode(node);
                }
                if (input.ExpressionType !== undefined) {
                    node = __XmlNode.of("ExpressionType", input.ExpressionType).withName("ExpressionType");
                    bodyNode.addChildNode(node);
                }
                if (input.InputSerialization !== undefined) {
                    node = serializeAws_restXmlInputSerialization(input.InputSerialization, context).withName("InputSerialization");
                    bodyNode.addChildNode(node);
                }
                if (input.OutputSerialization !== undefined) {
                    node = serializeAws_restXmlOutputSerialization(input.OutputSerialization, context).withName("OutputSerialization");
                    bodyNode.addChildNode(node);
                }
                if (input.RequestProgress !== undefined) {
                    node = serializeAws_restXmlRequestProgress(input.RequestProgress, context).withName("RequestProgress");
                    bodyNode.addChildNode(node);
                }
                if (input.ScanRange !== undefined) {
                    node = serializeAws_restXmlScanRange(input.ScanRange, context).withName("ScanRange");
                    bodyNode.addChildNode(node);
                }
                body += bodyNode.toString();
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlUploadPartCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "content-type": "application/octet-stream",
                    "content-length": [function () { return isSerializableHeaderValue(input.ContentLength); }, function () { return input.ContentLength.toString(); }],
                    "content-md5": input.ContentMD5,
                    "x-amz-sdk-checksum-algorithm": input.ChecksumAlgorithm,
                    "x-amz-checksum-crc32": input.ChecksumCRC32,
                    "x-amz-checksum-crc32c": input.ChecksumCRC32C,
                    "x-amz-checksum-sha1": input.ChecksumSHA1,
                    "x-amz-checksum-sha256": input.ChecksumSHA256,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "UploadPart"],
                    partNumber: [function () { return input.PartNumber !== void 0; }, function () { return input.PartNumber.toString(); }],
                    uploadId: [, input.UploadId],
                });
                if (input.Body !== undefined) {
                    body = input.Body;
                }
                if (input.Body !== undefined) {
                    contents = input.Body;
                    body = contents;
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlUploadPartCopyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, {
                    "x-amz-copy-source": input.CopySource,
                    "x-amz-copy-source-if-match": input.CopySourceIfMatch,
                    "x-amz-copy-source-if-modified-since": [
                        function () { return isSerializableHeaderValue(input.CopySourceIfModifiedSince); },
                        function () { return __dateToUtcString(input.CopySourceIfModifiedSince).toString(); },
                    ],
                    "x-amz-copy-source-if-none-match": input.CopySourceIfNoneMatch,
                    "x-amz-copy-source-if-unmodified-since": [
                        function () { return isSerializableHeaderValue(input.CopySourceIfUnmodifiedSince); },
                        function () { return __dateToUtcString(input.CopySourceIfUnmodifiedSince).toString(); },
                    ],
                    "x-amz-copy-source-range": input.CopySourceRange,
                    "x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm,
                    "x-amz-server-side-encryption-customer-key": input.SSECustomerKey,
                    "x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5,
                    "x-amz-copy-source-server-side-encryption-customer-algorithm": input.CopySourceSSECustomerAlgorithm,
                    "x-amz-copy-source-server-side-encryption-customer-key": input.CopySourceSSECustomerKey,
                    "x-amz-copy-source-server-side-encryption-customer-key-md5": input.CopySourceSSECustomerKeyMD5,
                    "x-amz-request-payer": input.RequestPayer,
                    "x-amz-expected-bucket-owner": input.ExpectedBucketOwner,
                    "x-amz-source-expected-bucket-owner": input.ExpectedSourceBucketOwner,
                });
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/{Bucket}/{Key+}";
                resolvedPath = __resolvedPath(resolvedPath, input, "Bucket", function () { return input.Bucket; }, "{Bucket}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Key", function () { return input.Key; }, "{Key+}", true);
                query = map({
                    "x-id": [, "UploadPartCopy"],
                    partNumber: [function () { return input.PartNumber !== void 0; }, function () { return input.PartNumber.toString(); }],
                    uploadId: [, input.UploadId],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restXmlWriteGetObjectResponseCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body, contents, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = map({}, isSerializableHeaderValue, __assign({ "x-amz-content-sha256": "UNSIGNED-PAYLOAD", "content-type": "application/octet-stream", "x-amz-request-route": input.RequestRoute, "x-amz-request-token": input.RequestToken, "x-amz-fwd-status": [function () { return isSerializableHeaderValue(input.StatusCode); }, function () { return input.StatusCode.toString(); }], "x-amz-fwd-error-code": input.ErrorCode, "x-amz-fwd-error-message": input.ErrorMessage, "x-amz-fwd-header-accept-ranges": input.AcceptRanges, "x-amz-fwd-header-cache-control": input.CacheControl, "x-amz-fwd-header-content-disposition": input.ContentDisposition, "x-amz-fwd-header-content-encoding": input.ContentEncoding, "x-amz-fwd-header-content-language": input.ContentLanguage, "content-length": [function () { return isSerializableHeaderValue(input.ContentLength); }, function () { return input.ContentLength.toString(); }], "x-amz-fwd-header-content-range": input.ContentRange, "x-amz-fwd-header-content-type": input.ContentType, "x-amz-fwd-header-x-amz-checksum-crc32": input.ChecksumCRC32, "x-amz-fwd-header-x-amz-checksum-crc32c": input.ChecksumCRC32C, "x-amz-fwd-header-x-amz-checksum-sha1": input.ChecksumSHA1, "x-amz-fwd-header-x-amz-checksum-sha256": input.ChecksumSHA256, "x-amz-fwd-header-x-amz-delete-marker": [
                        function () { return isSerializableHeaderValue(input.DeleteMarker); },
                        function () { return input.DeleteMarker.toString(); },
                    ], "x-amz-fwd-header-etag": input.ETag, "x-amz-fwd-header-expires": [
                        function () { return isSerializableHeaderValue(input.Expires); },
                        function () { return __dateToUtcString(input.Expires).toString(); },
                    ], "x-amz-fwd-header-x-amz-expiration": input.Expiration, "x-amz-fwd-header-last-modified": [
                        function () { return isSerializableHeaderValue(input.LastModified); },
                        function () { return __dateToUtcString(input.LastModified).toString(); },
                    ], "x-amz-fwd-header-x-amz-missing-meta": [
                        function () { return isSerializableHeaderValue(input.MissingMeta); },
                        function () { return input.MissingMeta.toString(); },
                    ], "x-amz-fwd-header-x-amz-object-lock-mode": input.ObjectLockMode, "x-amz-fwd-header-x-amz-object-lock-legal-hold": input.ObjectLockLegalHoldStatus, "x-amz-fwd-header-x-amz-object-lock-retain-until-date": [
                        function () { return isSerializableHeaderValue(input.ObjectLockRetainUntilDate); },
                        function () { return (input.ObjectLockRetainUntilDate.toISOString().split(".")[0] + "Z").toString(); },
                    ], "x-amz-fwd-header-x-amz-mp-parts-count": [
                        function () { return isSerializableHeaderValue(input.PartsCount); },
                        function () { return input.PartsCount.toString(); },
                    ], "x-amz-fwd-header-x-amz-replication-status": input.ReplicationStatus, "x-amz-fwd-header-x-amz-request-charged": input.RequestCharged, "x-amz-fwd-header-x-amz-restore": input.Restore, "x-amz-fwd-header-x-amz-server-side-encryption": input.ServerSideEncryption, "x-amz-fwd-header-x-amz-server-side-encryption-customer-algorithm": input.SSECustomerAlgorithm, "x-amz-fwd-header-x-amz-server-side-encryption-aws-kms-key-id": input.SSEKMSKeyId, "x-amz-fwd-header-x-amz-server-side-encryption-customer-key-md5": input.SSECustomerKeyMD5, "x-amz-fwd-header-x-amz-storage-class": input.StorageClass, "x-amz-fwd-header-x-amz-tagging-count": [
                        function () { return isSerializableHeaderValue(input.TagCount); },
                        function () { return input.TagCount.toString(); },
                    ], "x-amz-fwd-header-x-amz-version-id": input.VersionId, "x-amz-fwd-header-x-amz-server-side-encryption-bucket-key-enabled": [
                        function () { return isSerializableHeaderValue(input.BucketKeyEnabled); },
                        function () { return input.BucketKeyEnabled.toString(); },
                    ] }, (input.Metadata !== undefined &&
                    Object.keys(input.Metadata).reduce(function (acc, suffix) {
                        var _a;
                        return (__assign(__assign({}, acc), (_a = {}, _a["x-amz-meta-".concat(suffix.toLowerCase())] = input.Metadata[suffix], _a)));
                    }, {}))));
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/WriteGetObjectResponse";
                query = map({
                    "x-id": [, "WriteGetObjectResponse"],
                });
                if (input.Body !== undefined) {
                    body = input.Body;
                }
                if (input.Body !== undefined) {
                    contents = input.Body;
                    body = contents;
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "{RequestRoute}." + resolvedHostname;
                    if (input.RequestRoute === undefined) {
                        throw new Error("Empty value provided for input host prefix: RequestRoute.");
                    }
                    resolvedHostname = resolvedHostname.replace("{RequestRoute}", input.RequestRoute);
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var deserializeAws_restXmlAbortMultipartUploadCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlAbortMultipartUploadCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlAbortMultipartUploadCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NoSuchUpload": return [3, 2];
                    case "com.amazonaws.s3#NoSuchUpload": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNoSuchUploadResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlCompleteMultipartUploadCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlCompleteMultipartUploadCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    Expiration: [, output.headers["x-amz-expiration"]],
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    VersionId: [, output.headers["x-amz-version-id"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Bucket"] !== undefined) {
                    contents.Bucket = __expectString(data["Bucket"]);
                }
                if (data["ChecksumCRC32"] !== undefined) {
                    contents.ChecksumCRC32 = __expectString(data["ChecksumCRC32"]);
                }
                if (data["ChecksumCRC32C"] !== undefined) {
                    contents.ChecksumCRC32C = __expectString(data["ChecksumCRC32C"]);
                }
                if (data["ChecksumSHA1"] !== undefined) {
                    contents.ChecksumSHA1 = __expectString(data["ChecksumSHA1"]);
                }
                if (data["ChecksumSHA256"] !== undefined) {
                    contents.ChecksumSHA256 = __expectString(data["ChecksumSHA256"]);
                }
                if (data["ETag"] !== undefined) {
                    contents.ETag = __expectString(data["ETag"]);
                }
                if (data["Key"] !== undefined) {
                    contents.Key = __expectString(data["Key"]);
                }
                if (data["Location"] !== undefined) {
                    contents.Location = __expectString(data["Location"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlCompleteMultipartUploadCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlCopyObjectCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlCopyObjectCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    Expiration: [, output.headers["x-amz-expiration"]],
                    CopySourceVersionId: [, output.headers["x-amz-copy-source-version-id"]],
                    VersionId: [, output.headers["x-amz-version-id"]],
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
                    SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    SSEKMSEncryptionContext: [, output.headers["x-amz-server-side-encryption-context"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CopyObjectResult = deserializeAws_restXmlCopyObjectResult(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlCopyObjectCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ObjectNotInActiveTierError": return [3, 2];
                    case "com.amazonaws.s3#ObjectNotInActiveTierError": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlObjectNotInActiveTierErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlCreateBucketCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlCreateBucketCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    Location: [, output.headers["location"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlCreateBucketCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BucketAlreadyExists": return [3, 2];
                    case "com.amazonaws.s3#BucketAlreadyExists": return [3, 2];
                    case "BucketAlreadyOwnedByYou": return [3, 4];
                    case "com.amazonaws.s3#BucketAlreadyOwnedByYou": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_restXmlBucketAlreadyExistsResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restXmlBucketAlreadyOwnedByYouResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 7;
            case 7: return [2];
        }
    });
}); };
export var deserializeAws_restXmlCreateMultipartUploadCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlCreateMultipartUploadCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    AbortDate: [
                        function () { return void 0 !== output.headers["x-amz-abort-date"]; },
                        function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["x-amz-abort-date"])); },
                    ],
                    AbortRuleId: [, output.headers["x-amz-abort-rule-id"]],
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
                    SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    SSEKMSEncryptionContext: [, output.headers["x-amz-server-side-encryption-context"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                    ChecksumAlgorithm: [, output.headers["x-amz-checksum-algorithm"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Bucket"] !== undefined) {
                    contents.Bucket = __expectString(data["Bucket"]);
                }
                if (data["Key"] !== undefined) {
                    contents.Key = __expectString(data["Key"]);
                }
                if (data["UploadId"] !== undefined) {
                    contents.UploadId = __expectString(data["UploadId"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlCreateMultipartUploadCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketAnalyticsConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketAnalyticsConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketAnalyticsConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketCorsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketCorsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketCorsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketEncryptionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketEncryptionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketEncryptionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketIntelligentTieringConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketIntelligentTieringConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketIntelligentTieringConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketInventoryConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketInventoryConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketInventoryConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketLifecycleCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketLifecycleCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketLifecycleCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketMetricsConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketMetricsConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketMetricsConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketOwnershipControlsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketOwnershipControlsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketOwnershipControlsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketPolicyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketPolicyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketPolicyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketReplicationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketReplicationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketReplicationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketTaggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketTaggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketTaggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteBucketWebsiteCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteBucketWebsiteCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteBucketWebsiteCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteObjectCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteObjectCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    DeleteMarker: [
                        function () { return void 0 !== output.headers["x-amz-delete-marker"]; },
                        function () { return __parseBoolean(output.headers["x-amz-delete-marker"]); },
                    ],
                    VersionId: [, output.headers["x-amz-version-id"]],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteObjectCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteObjectsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteObjectsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Deleted === "") {
                    contents.Deleted = [];
                }
                else if (data["Deleted"] !== undefined) {
                    contents.Deleted = deserializeAws_restXmlDeletedObjects(__getArrayIfSingleItem(data["Deleted"]), context);
                }
                if (data.Error === "") {
                    contents.Errors = [];
                }
                else if (data["Error"] !== undefined) {
                    contents.Errors = deserializeAws_restXmlErrors(__getArrayIfSingleItem(data["Error"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteObjectsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeleteObjectTaggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeleteObjectTaggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    VersionId: [, output.headers["x-amz-version-id"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeleteObjectTaggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlDeletePublicAccessBlockCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlDeletePublicAccessBlockCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlDeletePublicAccessBlockCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketAccelerateConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketAccelerateConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Status"] !== undefined) {
                    contents.Status = __expectString(data["Status"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketAccelerateConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketAclCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketAclCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.AccessControlList === "") {
                    contents.Grants = [];
                }
                else if (data["AccessControlList"] !== undefined && data["AccessControlList"]["Grant"] !== undefined) {
                    contents.Grants = deserializeAws_restXmlGrants(__getArrayIfSingleItem(data["AccessControlList"]["Grant"]), context);
                }
                if (data["Owner"] !== undefined) {
                    contents.Owner = deserializeAws_restXmlOwner(data["Owner"], context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketAclCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketAnalyticsConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketAnalyticsConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.AnalyticsConfiguration = deserializeAws_restXmlAnalyticsConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketAnalyticsConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketCorsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketCorsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CORSRule === "") {
                    contents.CORSRules = [];
                }
                else if (data["CORSRule"] !== undefined) {
                    contents.CORSRules = deserializeAws_restXmlCORSRules(__getArrayIfSingleItem(data["CORSRule"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketCorsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketEncryptionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketEncryptionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ServerSideEncryptionConfiguration = deserializeAws_restXmlServerSideEncryptionConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketEncryptionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketIntelligentTieringConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketIntelligentTieringConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.IntelligentTieringConfiguration = deserializeAws_restXmlIntelligentTieringConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketIntelligentTieringConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketInventoryConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketInventoryConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.InventoryConfiguration = deserializeAws_restXmlInventoryConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketInventoryConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketLifecycleConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketLifecycleConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Rule === "") {
                    contents.Rules = [];
                }
                else if (data["Rule"] !== undefined) {
                    contents.Rules = deserializeAws_restXmlLifecycleRules(__getArrayIfSingleItem(data["Rule"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketLifecycleConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketLocationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketLocationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["LocationConstraint"] !== undefined) {
                    contents.LocationConstraint = __expectString(data["LocationConstraint"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketLocationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketLoggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketLoggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["LoggingEnabled"] !== undefined) {
                    contents.LoggingEnabled = deserializeAws_restXmlLoggingEnabled(data["LoggingEnabled"], context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketLoggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketMetricsConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketMetricsConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MetricsConfiguration = deserializeAws_restXmlMetricsConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketMetricsConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketNotificationConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketNotificationConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["EventBridgeConfiguration"] !== undefined) {
                    contents.EventBridgeConfiguration = deserializeAws_restXmlEventBridgeConfiguration(data["EventBridgeConfiguration"], context);
                }
                if (data.CloudFunctionConfiguration === "") {
                    contents.LambdaFunctionConfigurations = [];
                }
                else if (data["CloudFunctionConfiguration"] !== undefined) {
                    contents.LambdaFunctionConfigurations = deserializeAws_restXmlLambdaFunctionConfigurationList(__getArrayIfSingleItem(data["CloudFunctionConfiguration"]), context);
                }
                if (data.QueueConfiguration === "") {
                    contents.QueueConfigurations = [];
                }
                else if (data["QueueConfiguration"] !== undefined) {
                    contents.QueueConfigurations = deserializeAws_restXmlQueueConfigurationList(__getArrayIfSingleItem(data["QueueConfiguration"]), context);
                }
                if (data.TopicConfiguration === "") {
                    contents.TopicConfigurations = [];
                }
                else if (data["TopicConfiguration"] !== undefined) {
                    contents.TopicConfigurations = deserializeAws_restXmlTopicConfigurationList(__getArrayIfSingleItem(data["TopicConfiguration"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketNotificationConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketOwnershipControlsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketOwnershipControlsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.OwnershipControls = deserializeAws_restXmlOwnershipControls(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketOwnershipControlsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketPolicyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketPolicyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBodyString(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Policy = __expectString(data);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketPolicyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketPolicyStatusCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketPolicyStatusCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.PolicyStatus = deserializeAws_restXmlPolicyStatus(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketPolicyStatusCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketReplicationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketReplicationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ReplicationConfiguration = deserializeAws_restXmlReplicationConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketReplicationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketRequestPaymentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketRequestPaymentCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Payer"] !== undefined) {
                    contents.Payer = __expectString(data["Payer"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketRequestPaymentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketTaggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketTaggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.TagSet === "") {
                    contents.TagSet = [];
                }
                else if (data["TagSet"] !== undefined && data["TagSet"]["Tag"] !== undefined) {
                    contents.TagSet = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(data["TagSet"]["Tag"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketTaggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketVersioningCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketVersioningCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["MfaDelete"] !== undefined) {
                    contents.MFADelete = __expectString(data["MfaDelete"]);
                }
                if (data["Status"] !== undefined) {
                    contents.Status = __expectString(data["Status"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketVersioningCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetBucketWebsiteCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetBucketWebsiteCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["ErrorDocument"] !== undefined) {
                    contents.ErrorDocument = deserializeAws_restXmlErrorDocument(data["ErrorDocument"], context);
                }
                if (data["IndexDocument"] !== undefined) {
                    contents.IndexDocument = deserializeAws_restXmlIndexDocument(data["IndexDocument"], context);
                }
                if (data["RedirectAllRequestsTo"] !== undefined) {
                    contents.RedirectAllRequestsTo = deserializeAws_restXmlRedirectAllRequestsTo(data["RedirectAllRequestsTo"], context);
                }
                if (data.RoutingRules === "") {
                    contents.RoutingRules = [];
                }
                else if (data["RoutingRules"] !== undefined && data["RoutingRules"]["RoutingRule"] !== undefined) {
                    contents.RoutingRules = deserializeAws_restXmlRoutingRules(__getArrayIfSingleItem(data["RoutingRules"]["RoutingRule"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetBucketWebsiteCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        if (output.statusCode !== 200 && output.statusCode >= 300) {
            return [2, deserializeAws_restXmlGetObjectCommandError(output, context)];
        }
        contents = map({
            $metadata: deserializeMetadata(output),
            DeleteMarker: [
                function () { return void 0 !== output.headers["x-amz-delete-marker"]; },
                function () { return __parseBoolean(output.headers["x-amz-delete-marker"]); },
            ],
            AcceptRanges: [, output.headers["accept-ranges"]],
            Expiration: [, output.headers["x-amz-expiration"]],
            Restore: [, output.headers["x-amz-restore"]],
            LastModified: [
                function () { return void 0 !== output.headers["last-modified"]; },
                function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["last-modified"])); },
            ],
            ContentLength: [
                function () { return void 0 !== output.headers["content-length"]; },
                function () { return __strictParseLong(output.headers["content-length"]); },
            ],
            ETag: [, output.headers["etag"]],
            ChecksumCRC32: [, output.headers["x-amz-checksum-crc32"]],
            ChecksumCRC32C: [, output.headers["x-amz-checksum-crc32c"]],
            ChecksumSHA1: [, output.headers["x-amz-checksum-sha1"]],
            ChecksumSHA256: [, output.headers["x-amz-checksum-sha256"]],
            MissingMeta: [
                function () { return void 0 !== output.headers["x-amz-missing-meta"]; },
                function () { return __strictParseInt32(output.headers["x-amz-missing-meta"]); },
            ],
            VersionId: [, output.headers["x-amz-version-id"]],
            CacheControl: [, output.headers["cache-control"]],
            ContentDisposition: [, output.headers["content-disposition"]],
            ContentEncoding: [, output.headers["content-encoding"]],
            ContentLanguage: [, output.headers["content-language"]],
            ContentRange: [, output.headers["content-range"]],
            ContentType: [, output.headers["content-type"]],
            Expires: [
                function () { return void 0 !== output.headers["expires"]; },
                function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["expires"])); },
            ],
            WebsiteRedirectLocation: [, output.headers["x-amz-website-redirect-location"]],
            ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
            SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
            SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
            SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
            BucketKeyEnabled: [
                function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
            ],
            StorageClass: [, output.headers["x-amz-storage-class"]],
            RequestCharged: [, output.headers["x-amz-request-charged"]],
            ReplicationStatus: [, output.headers["x-amz-replication-status"]],
            PartsCount: [
                function () { return void 0 !== output.headers["x-amz-mp-parts-count"]; },
                function () { return __strictParseInt32(output.headers["x-amz-mp-parts-count"]); },
            ],
            TagCount: [
                function () { return void 0 !== output.headers["x-amz-tagging-count"]; },
                function () { return __strictParseInt32(output.headers["x-amz-tagging-count"]); },
            ],
            ObjectLockMode: [, output.headers["x-amz-object-lock-mode"]],
            ObjectLockRetainUntilDate: [
                function () { return void 0 !== output.headers["x-amz-object-lock-retain-until-date"]; },
                function () { return __expectNonNull(__parseRfc3339DateTime(output.headers["x-amz-object-lock-retain-until-date"])); },
            ],
            ObjectLockLegalHoldStatus: [, output.headers["x-amz-object-lock-legal-hold"]],
            Metadata: [
                ,
                Object.keys(output.headers)
                    .filter(function (header) { return header.startsWith("x-amz-meta-"); })
                    .reduce(function (acc, header) {
                    acc[header.substring(11)] = output.headers[header];
                    return acc;
                }, {}),
            ],
        });
        data = output.body;
        contents.Body = data;
        return [2, contents];
    });
}); };
var deserializeAws_restXmlGetObjectCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InvalidObjectState": return [3, 2];
                    case "com.amazonaws.s3#InvalidObjectState": return [3, 2];
                    case "NoSuchKey": return [3, 4];
                    case "com.amazonaws.s3#NoSuchKey": return [3, 4];
                }
                return [3, 6];
            case 2: return [4, deserializeAws_restXmlInvalidObjectStateResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restXmlNoSuchKeyResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 7;
            case 7: return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectAclCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetObjectAclCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.AccessControlList === "") {
                    contents.Grants = [];
                }
                else if (data["AccessControlList"] !== undefined && data["AccessControlList"]["Grant"] !== undefined) {
                    contents.Grants = deserializeAws_restXmlGrants(__getArrayIfSingleItem(data["AccessControlList"]["Grant"]), context);
                }
                if (data["Owner"] !== undefined) {
                    contents.Owner = deserializeAws_restXmlOwner(data["Owner"], context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetObjectAclCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NoSuchKey": return [3, 2];
                    case "com.amazonaws.s3#NoSuchKey": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNoSuchKeyResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetObjectAttributesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    DeleteMarker: [
                        function () { return void 0 !== output.headers["x-amz-delete-marker"]; },
                        function () { return __parseBoolean(output.headers["x-amz-delete-marker"]); },
                    ],
                    LastModified: [
                        function () { return void 0 !== output.headers["last-modified"]; },
                        function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["last-modified"])); },
                    ],
                    VersionId: [, output.headers["x-amz-version-id"]],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Checksum"] !== undefined) {
                    contents.Checksum = deserializeAws_restXmlChecksum(data["Checksum"], context);
                }
                if (data["ETag"] !== undefined) {
                    contents.ETag = __expectString(data["ETag"]);
                }
                if (data["ObjectParts"] !== undefined) {
                    contents.ObjectParts = deserializeAws_restXmlGetObjectAttributesParts(data["ObjectParts"], context);
                }
                if (data["ObjectSize"] !== undefined) {
                    contents.ObjectSize = __strictParseLong(data["ObjectSize"]);
                }
                if (data["StorageClass"] !== undefined) {
                    contents.StorageClass = __expectString(data["StorageClass"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetObjectAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NoSuchKey": return [3, 2];
                    case "com.amazonaws.s3#NoSuchKey": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNoSuchKeyResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectLegalHoldCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetObjectLegalHoldCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.LegalHold = deserializeAws_restXmlObjectLockLegalHold(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetObjectLegalHoldCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectLockConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetObjectLockConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ObjectLockConfiguration = deserializeAws_restXmlObjectLockConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetObjectLockConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectRetentionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetObjectRetentionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.Retention = deserializeAws_restXmlObjectLockRetention(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetObjectRetentionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectTaggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetObjectTaggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    VersionId: [, output.headers["x-amz-version-id"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.TagSet === "") {
                    contents.TagSet = [];
                }
                else if (data["TagSet"] !== undefined && data["TagSet"]["Tag"] !== undefined) {
                    contents.TagSet = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(data["TagSet"]["Tag"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetObjectTaggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetObjectTorrentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        if (output.statusCode !== 200 && output.statusCode >= 300) {
            return [2, deserializeAws_restXmlGetObjectTorrentCommandError(output, context)];
        }
        contents = map({
            $metadata: deserializeMetadata(output),
            RequestCharged: [, output.headers["x-amz-request-charged"]],
        });
        data = output.body;
        contents.Body = data;
        return [2, contents];
    });
}); };
var deserializeAws_restXmlGetObjectTorrentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlGetPublicAccessBlockCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlGetPublicAccessBlockCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.PublicAccessBlockConfiguration = deserializeAws_restXmlPublicAccessBlockConfiguration(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlGetPublicAccessBlockCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlHeadBucketCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlHeadBucketCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlHeadBucketCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NotFound": return [3, 2];
                    case "com.amazonaws.s3#NotFound": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNotFoundResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlHeadObjectCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlHeadObjectCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    DeleteMarker: [
                        function () { return void 0 !== output.headers["x-amz-delete-marker"]; },
                        function () { return __parseBoolean(output.headers["x-amz-delete-marker"]); },
                    ],
                    AcceptRanges: [, output.headers["accept-ranges"]],
                    Expiration: [, output.headers["x-amz-expiration"]],
                    Restore: [, output.headers["x-amz-restore"]],
                    ArchiveStatus: [, output.headers["x-amz-archive-status"]],
                    LastModified: [
                        function () { return void 0 !== output.headers["last-modified"]; },
                        function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["last-modified"])); },
                    ],
                    ContentLength: [
                        function () { return void 0 !== output.headers["content-length"]; },
                        function () { return __strictParseLong(output.headers["content-length"]); },
                    ],
                    ChecksumCRC32: [, output.headers["x-amz-checksum-crc32"]],
                    ChecksumCRC32C: [, output.headers["x-amz-checksum-crc32c"]],
                    ChecksumSHA1: [, output.headers["x-amz-checksum-sha1"]],
                    ChecksumSHA256: [, output.headers["x-amz-checksum-sha256"]],
                    ETag: [, output.headers["etag"]],
                    MissingMeta: [
                        function () { return void 0 !== output.headers["x-amz-missing-meta"]; },
                        function () { return __strictParseInt32(output.headers["x-amz-missing-meta"]); },
                    ],
                    VersionId: [, output.headers["x-amz-version-id"]],
                    CacheControl: [, output.headers["cache-control"]],
                    ContentDisposition: [, output.headers["content-disposition"]],
                    ContentEncoding: [, output.headers["content-encoding"]],
                    ContentLanguage: [, output.headers["content-language"]],
                    ContentType: [, output.headers["content-type"]],
                    Expires: [
                        function () { return void 0 !== output.headers["expires"]; },
                        function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["expires"])); },
                    ],
                    WebsiteRedirectLocation: [, output.headers["x-amz-website-redirect-location"]],
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
                    SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    StorageClass: [, output.headers["x-amz-storage-class"]],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                    ReplicationStatus: [, output.headers["x-amz-replication-status"]],
                    PartsCount: [
                        function () { return void 0 !== output.headers["x-amz-mp-parts-count"]; },
                        function () { return __strictParseInt32(output.headers["x-amz-mp-parts-count"]); },
                    ],
                    ObjectLockMode: [, output.headers["x-amz-object-lock-mode"]],
                    ObjectLockRetainUntilDate: [
                        function () { return void 0 !== output.headers["x-amz-object-lock-retain-until-date"]; },
                        function () { return __expectNonNull(__parseRfc3339DateTime(output.headers["x-amz-object-lock-retain-until-date"])); },
                    ],
                    ObjectLockLegalHoldStatus: [, output.headers["x-amz-object-lock-legal-hold"]],
                    Metadata: [
                        ,
                        Object.keys(output.headers)
                            .filter(function (header) { return header.startsWith("x-amz-meta-"); })
                            .reduce(function (acc, header) {
                            acc[header.substring(11)] = output.headers[header];
                            return acc;
                        }, {}),
                    ],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlHeadObjectCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NotFound": return [3, 2];
                    case "com.amazonaws.s3#NotFound": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNotFoundResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlListBucketAnalyticsConfigurationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListBucketAnalyticsConfigurationsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.AnalyticsConfiguration === "") {
                    contents.AnalyticsConfigurationList = [];
                }
                else if (data["AnalyticsConfiguration"] !== undefined) {
                    contents.AnalyticsConfigurationList = deserializeAws_restXmlAnalyticsConfigurationList(__getArrayIfSingleItem(data["AnalyticsConfiguration"]), context);
                }
                if (data["ContinuationToken"] !== undefined) {
                    contents.ContinuationToken = __expectString(data["ContinuationToken"]);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["NextContinuationToken"] !== undefined) {
                    contents.NextContinuationToken = __expectString(data["NextContinuationToken"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListBucketAnalyticsConfigurationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListBucketIntelligentTieringConfigurationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListBucketIntelligentTieringConfigurationsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["ContinuationToken"] !== undefined) {
                    contents.ContinuationToken = __expectString(data["ContinuationToken"]);
                }
                if (data.IntelligentTieringConfiguration === "") {
                    contents.IntelligentTieringConfigurationList = [];
                }
                else if (data["IntelligentTieringConfiguration"] !== undefined) {
                    contents.IntelligentTieringConfigurationList = deserializeAws_restXmlIntelligentTieringConfigurationList(__getArrayIfSingleItem(data["IntelligentTieringConfiguration"]), context);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["NextContinuationToken"] !== undefined) {
                    contents.NextContinuationToken = __expectString(data["NextContinuationToken"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListBucketIntelligentTieringConfigurationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListBucketInventoryConfigurationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListBucketInventoryConfigurationsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["ContinuationToken"] !== undefined) {
                    contents.ContinuationToken = __expectString(data["ContinuationToken"]);
                }
                if (data.InventoryConfiguration === "") {
                    contents.InventoryConfigurationList = [];
                }
                else if (data["InventoryConfiguration"] !== undefined) {
                    contents.InventoryConfigurationList = deserializeAws_restXmlInventoryConfigurationList(__getArrayIfSingleItem(data["InventoryConfiguration"]), context);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["NextContinuationToken"] !== undefined) {
                    contents.NextContinuationToken = __expectString(data["NextContinuationToken"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListBucketInventoryConfigurationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListBucketMetricsConfigurationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListBucketMetricsConfigurationsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["ContinuationToken"] !== undefined) {
                    contents.ContinuationToken = __expectString(data["ContinuationToken"]);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data.MetricsConfiguration === "") {
                    contents.MetricsConfigurationList = [];
                }
                else if (data["MetricsConfiguration"] !== undefined) {
                    contents.MetricsConfigurationList = deserializeAws_restXmlMetricsConfigurationList(__getArrayIfSingleItem(data["MetricsConfiguration"]), context);
                }
                if (data["NextContinuationToken"] !== undefined) {
                    contents.NextContinuationToken = __expectString(data["NextContinuationToken"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListBucketMetricsConfigurationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListBucketsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListBucketsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Buckets === "") {
                    contents.Buckets = [];
                }
                else if (data["Buckets"] !== undefined && data["Buckets"]["Bucket"] !== undefined) {
                    contents.Buckets = deserializeAws_restXmlBuckets(__getArrayIfSingleItem(data["Buckets"]["Bucket"]), context);
                }
                if (data["Owner"] !== undefined) {
                    contents.Owner = deserializeAws_restXmlOwner(data["Owner"], context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListBucketsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListMultipartUploadsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListMultipartUploadsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Bucket"] !== undefined) {
                    contents.Bucket = __expectString(data["Bucket"]);
                }
                if (data.CommonPrefixes === "") {
                    contents.CommonPrefixes = [];
                }
                else if (data["CommonPrefixes"] !== undefined) {
                    contents.CommonPrefixes = deserializeAws_restXmlCommonPrefixList(__getArrayIfSingleItem(data["CommonPrefixes"]), context);
                }
                if (data["Delimiter"] !== undefined) {
                    contents.Delimiter = __expectString(data["Delimiter"]);
                }
                if (data["EncodingType"] !== undefined) {
                    contents.EncodingType = __expectString(data["EncodingType"]);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["KeyMarker"] !== undefined) {
                    contents.KeyMarker = __expectString(data["KeyMarker"]);
                }
                if (data["MaxUploads"] !== undefined) {
                    contents.MaxUploads = __strictParseInt32(data["MaxUploads"]);
                }
                if (data["NextKeyMarker"] !== undefined) {
                    contents.NextKeyMarker = __expectString(data["NextKeyMarker"]);
                }
                if (data["NextUploadIdMarker"] !== undefined) {
                    contents.NextUploadIdMarker = __expectString(data["NextUploadIdMarker"]);
                }
                if (data["Prefix"] !== undefined) {
                    contents.Prefix = __expectString(data["Prefix"]);
                }
                if (data["UploadIdMarker"] !== undefined) {
                    contents.UploadIdMarker = __expectString(data["UploadIdMarker"]);
                }
                if (data.Upload === "") {
                    contents.Uploads = [];
                }
                else if (data["Upload"] !== undefined) {
                    contents.Uploads = deserializeAws_restXmlMultipartUploadList(__getArrayIfSingleItem(data["Upload"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListMultipartUploadsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListObjectsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListObjectsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CommonPrefixes === "") {
                    contents.CommonPrefixes = [];
                }
                else if (data["CommonPrefixes"] !== undefined) {
                    contents.CommonPrefixes = deserializeAws_restXmlCommonPrefixList(__getArrayIfSingleItem(data["CommonPrefixes"]), context);
                }
                if (data.Contents === "") {
                    contents.Contents = [];
                }
                else if (data["Contents"] !== undefined) {
                    contents.Contents = deserializeAws_restXmlObjectList(__getArrayIfSingleItem(data["Contents"]), context);
                }
                if (data["Delimiter"] !== undefined) {
                    contents.Delimiter = __expectString(data["Delimiter"]);
                }
                if (data["EncodingType"] !== undefined) {
                    contents.EncodingType = __expectString(data["EncodingType"]);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["Marker"] !== undefined) {
                    contents.Marker = __expectString(data["Marker"]);
                }
                if (data["MaxKeys"] !== undefined) {
                    contents.MaxKeys = __strictParseInt32(data["MaxKeys"]);
                }
                if (data["Name"] !== undefined) {
                    contents.Name = __expectString(data["Name"]);
                }
                if (data["NextMarker"] !== undefined) {
                    contents.NextMarker = __expectString(data["NextMarker"]);
                }
                if (data["Prefix"] !== undefined) {
                    contents.Prefix = __expectString(data["Prefix"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListObjectsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NoSuchBucket": return [3, 2];
                    case "com.amazonaws.s3#NoSuchBucket": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNoSuchBucketResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlListObjectsV2Command = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListObjectsV2CommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CommonPrefixes === "") {
                    contents.CommonPrefixes = [];
                }
                else if (data["CommonPrefixes"] !== undefined) {
                    contents.CommonPrefixes = deserializeAws_restXmlCommonPrefixList(__getArrayIfSingleItem(data["CommonPrefixes"]), context);
                }
                if (data.Contents === "") {
                    contents.Contents = [];
                }
                else if (data["Contents"] !== undefined) {
                    contents.Contents = deserializeAws_restXmlObjectList(__getArrayIfSingleItem(data["Contents"]), context);
                }
                if (data["ContinuationToken"] !== undefined) {
                    contents.ContinuationToken = __expectString(data["ContinuationToken"]);
                }
                if (data["Delimiter"] !== undefined) {
                    contents.Delimiter = __expectString(data["Delimiter"]);
                }
                if (data["EncodingType"] !== undefined) {
                    contents.EncodingType = __expectString(data["EncodingType"]);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["KeyCount"] !== undefined) {
                    contents.KeyCount = __strictParseInt32(data["KeyCount"]);
                }
                if (data["MaxKeys"] !== undefined) {
                    contents.MaxKeys = __strictParseInt32(data["MaxKeys"]);
                }
                if (data["Name"] !== undefined) {
                    contents.Name = __expectString(data["Name"]);
                }
                if (data["NextContinuationToken"] !== undefined) {
                    contents.NextContinuationToken = __expectString(data["NextContinuationToken"]);
                }
                if (data["Prefix"] !== undefined) {
                    contents.Prefix = __expectString(data["Prefix"]);
                }
                if (data["StartAfter"] !== undefined) {
                    contents.StartAfter = __expectString(data["StartAfter"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListObjectsV2CommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NoSuchBucket": return [3, 2];
                    case "com.amazonaws.s3#NoSuchBucket": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNoSuchBucketResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlListObjectVersionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListObjectVersionsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CommonPrefixes === "") {
                    contents.CommonPrefixes = [];
                }
                else if (data["CommonPrefixes"] !== undefined) {
                    contents.CommonPrefixes = deserializeAws_restXmlCommonPrefixList(__getArrayIfSingleItem(data["CommonPrefixes"]), context);
                }
                if (data.DeleteMarker === "") {
                    contents.DeleteMarkers = [];
                }
                else if (data["DeleteMarker"] !== undefined) {
                    contents.DeleteMarkers = deserializeAws_restXmlDeleteMarkers(__getArrayIfSingleItem(data["DeleteMarker"]), context);
                }
                if (data["Delimiter"] !== undefined) {
                    contents.Delimiter = __expectString(data["Delimiter"]);
                }
                if (data["EncodingType"] !== undefined) {
                    contents.EncodingType = __expectString(data["EncodingType"]);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["KeyMarker"] !== undefined) {
                    contents.KeyMarker = __expectString(data["KeyMarker"]);
                }
                if (data["MaxKeys"] !== undefined) {
                    contents.MaxKeys = __strictParseInt32(data["MaxKeys"]);
                }
                if (data["Name"] !== undefined) {
                    contents.Name = __expectString(data["Name"]);
                }
                if (data["NextKeyMarker"] !== undefined) {
                    contents.NextKeyMarker = __expectString(data["NextKeyMarker"]);
                }
                if (data["NextVersionIdMarker"] !== undefined) {
                    contents.NextVersionIdMarker = __expectString(data["NextVersionIdMarker"]);
                }
                if (data["Prefix"] !== undefined) {
                    contents.Prefix = __expectString(data["Prefix"]);
                }
                if (data["VersionIdMarker"] !== undefined) {
                    contents.VersionIdMarker = __expectString(data["VersionIdMarker"]);
                }
                if (data.Version === "") {
                    contents.Versions = [];
                }
                else if (data["Version"] !== undefined) {
                    contents.Versions = deserializeAws_restXmlObjectVersionList(__getArrayIfSingleItem(data["Version"]), context);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListObjectVersionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlListPartsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlListPartsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    AbortDate: [
                        function () { return void 0 !== output.headers["x-amz-abort-date"]; },
                        function () { return __expectNonNull(__parseRfc7231DateTime(output.headers["x-amz-abort-date"])); },
                    ],
                    AbortRuleId: [, output.headers["x-amz-abort-rule-id"]],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data["Bucket"] !== undefined) {
                    contents.Bucket = __expectString(data["Bucket"]);
                }
                if (data["ChecksumAlgorithm"] !== undefined) {
                    contents.ChecksumAlgorithm = __expectString(data["ChecksumAlgorithm"]);
                }
                if (data["Initiator"] !== undefined) {
                    contents.Initiator = deserializeAws_restXmlInitiator(data["Initiator"], context);
                }
                if (data["IsTruncated"] !== undefined) {
                    contents.IsTruncated = __parseBoolean(data["IsTruncated"]);
                }
                if (data["Key"] !== undefined) {
                    contents.Key = __expectString(data["Key"]);
                }
                if (data["MaxParts"] !== undefined) {
                    contents.MaxParts = __strictParseInt32(data["MaxParts"]);
                }
                if (data["NextPartNumberMarker"] !== undefined) {
                    contents.NextPartNumberMarker = __expectString(data["NextPartNumberMarker"]);
                }
                if (data["Owner"] !== undefined) {
                    contents.Owner = deserializeAws_restXmlOwner(data["Owner"], context);
                }
                if (data["PartNumberMarker"] !== undefined) {
                    contents.PartNumberMarker = __expectString(data["PartNumberMarker"]);
                }
                if (data.Part === "") {
                    contents.Parts = [];
                }
                else if (data["Part"] !== undefined) {
                    contents.Parts = deserializeAws_restXmlParts(__getArrayIfSingleItem(data["Part"]), context);
                }
                if (data["StorageClass"] !== undefined) {
                    contents.StorageClass = __expectString(data["StorageClass"]);
                }
                if (data["UploadId"] !== undefined) {
                    contents.UploadId = __expectString(data["UploadId"]);
                }
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlListPartsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketAccelerateConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketAccelerateConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketAccelerateConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketAclCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketAclCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketAclCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketAnalyticsConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketAnalyticsConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketAnalyticsConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketCorsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketCorsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketCorsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketEncryptionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketEncryptionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketEncryptionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketIntelligentTieringConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketIntelligentTieringConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketIntelligentTieringConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketInventoryConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketInventoryConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketInventoryConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketLifecycleConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketLifecycleConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketLifecycleConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketLoggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketLoggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketLoggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketMetricsConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketMetricsConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketMetricsConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketNotificationConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketNotificationConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketNotificationConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketOwnershipControlsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketOwnershipControlsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketOwnershipControlsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketPolicyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketPolicyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketPolicyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketReplicationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketReplicationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketReplicationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketRequestPaymentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketRequestPaymentCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketRequestPaymentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketTaggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketTaggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketTaggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketVersioningCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketVersioningCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketVersioningCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutBucketWebsiteCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutBucketWebsiteCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutBucketWebsiteCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutObjectCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutObjectCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    Expiration: [, output.headers["x-amz-expiration"]],
                    ETag: [, output.headers["etag"]],
                    ChecksumCRC32: [, output.headers["x-amz-checksum-crc32"]],
                    ChecksumCRC32C: [, output.headers["x-amz-checksum-crc32c"]],
                    ChecksumSHA1: [, output.headers["x-amz-checksum-sha1"]],
                    ChecksumSHA256: [, output.headers["x-amz-checksum-sha256"]],
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    VersionId: [, output.headers["x-amz-version-id"]],
                    SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
                    SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    SSEKMSEncryptionContext: [, output.headers["x-amz-server-side-encryption-context"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutObjectCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutObjectAclCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutObjectAclCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutObjectAclCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "NoSuchKey": return [3, 2];
                    case "com.amazonaws.s3#NoSuchKey": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlNoSuchKeyResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutObjectLegalHoldCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutObjectLegalHoldCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutObjectLegalHoldCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutObjectLockConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutObjectLockConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutObjectLockConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutObjectRetentionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutObjectRetentionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutObjectRetentionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutObjectTaggingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutObjectTaggingCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    VersionId: [, output.headers["x-amz-version-id"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutObjectTaggingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlPutPublicAccessBlockCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlPutPublicAccessBlockCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlPutPublicAccessBlockCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlRestoreObjectCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlRestoreObjectCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                    RestoreOutputPath: [, output.headers["x-amz-restore-output-path"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlRestoreObjectCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ObjectAlreadyInActiveTierError": return [3, 2];
                    case "com.amazonaws.s3#ObjectAlreadyInActiveTierError": return [3, 2];
                }
                return [3, 4];
            case 2: return [4, deserializeAws_restXmlObjectAlreadyInActiveTierErrorResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 5;
            case 5: return [2];
        }
    });
}); };
export var deserializeAws_restXmlSelectObjectContentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        if (output.statusCode !== 200 && output.statusCode >= 300) {
            return [2, deserializeAws_restXmlSelectObjectContentCommandError(output, context)];
        }
        contents = map({
            $metadata: deserializeMetadata(output),
        });
        data = output.body;
        contents.Payload = deserializeAws_restXmlSelectObjectContentEventStream(data, context);
        return [2, contents];
    });
}); };
var deserializeAws_restXmlSelectObjectContentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlUploadPartCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlUploadPartCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    ETag: [, output.headers["etag"]],
                    ChecksumCRC32: [, output.headers["x-amz-checksum-crc32"]],
                    ChecksumCRC32C: [, output.headers["x-amz-checksum-crc32c"]],
                    ChecksumSHA1: [, output.headers["x-amz-checksum-sha1"]],
                    ChecksumSHA256: [, output.headers["x-amz-checksum-sha256"]],
                    SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
                    SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlUploadPartCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlUploadPartCopyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlUploadPartCopyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                    CopySourceVersionId: [, output.headers["x-amz-copy-source-version-id"]],
                    ServerSideEncryption: [, output.headers["x-amz-server-side-encryption"]],
                    SSECustomerAlgorithm: [, output.headers["x-amz-server-side-encryption-customer-algorithm"]],
                    SSECustomerKeyMD5: [, output.headers["x-amz-server-side-encryption-customer-key-md5"]],
                    SSEKMSKeyId: [, output.headers["x-amz-server-side-encryption-aws-kms-key-id"]],
                    BucketKeyEnabled: [
                        function () { return void 0 !== output.headers["x-amz-server-side-encryption-bucket-key-enabled"]; },
                        function () { return __parseBoolean(output.headers["x-amz-server-side-encryption-bucket-key-enabled"]); },
                    ],
                    RequestCharged: [, output.headers["x-amz-request-charged"]],
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CopyPartResult = deserializeAws_restXmlCopyPartResult(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlUploadPartCopyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restXmlWriteGetObjectResponseCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restXmlWriteGetObjectResponseCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlWriteGetObjectResponseCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestXmlErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
var map = __map;
var deserializeAws_restXmlBucketAlreadyExistsResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new BucketAlreadyExists(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlBucketAlreadyOwnedByYouResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new BucketAlreadyOwnedByYou(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlInvalidObjectStateResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data["AccessTier"] !== undefined) {
            contents.AccessTier = __expectString(data["AccessTier"]);
        }
        if (data["StorageClass"] !== undefined) {
            contents.StorageClass = __expectString(data["StorageClass"]);
        }
        exception = new InvalidObjectState(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlNoSuchBucketResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new NoSuchBucket(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlNoSuchKeyResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new NoSuchKey(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlNoSuchUploadResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new NoSuchUpload(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlNotFoundResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new NotFound(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlObjectAlreadyInActiveTierErrorResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new ObjectAlreadyInActiveTierError(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlObjectNotInActiveTierErrorResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        exception = new ObjectNotInActiveTierError(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restXmlSelectObjectContentEventStream = function (output, context) {
    return context.eventStreamMarshaller.deserialize(output, function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (!(event["Records"] != null)) return [3, 2];
                    _a = {};
                    return [4, deserializeAws_restXmlRecordsEvent_event(event["Records"], context)];
                case 1: return [2, (_a.Records = _f.sent(),
                        _a)];
                case 2:
                    if (!(event["Stats"] != null)) return [3, 4];
                    _b = {};
                    return [4, deserializeAws_restXmlStatsEvent_event(event["Stats"], context)];
                case 3: return [2, (_b.Stats = _f.sent(),
                        _b)];
                case 4:
                    if (!(event["Progress"] != null)) return [3, 6];
                    _c = {};
                    return [4, deserializeAws_restXmlProgressEvent_event(event["Progress"], context)];
                case 5: return [2, (_c.Progress = _f.sent(),
                        _c)];
                case 6:
                    if (!(event["Cont"] != null)) return [3, 8];
                    _d = {};
                    return [4, deserializeAws_restXmlContinuationEvent_event(event["Cont"], context)];
                case 7: return [2, (_d.Cont = _f.sent(),
                        _d)];
                case 8:
                    if (!(event["End"] != null)) return [3, 10];
                    _e = {};
                    return [4, deserializeAws_restXmlEndEvent_event(event["End"], context)];
                case 9: return [2, (_e.End = _f.sent(),
                        _e)];
                case 10: return [2, { $unknown: output }];
            }
        });
    }); });
};
var deserializeAws_restXmlContinuationEvent_event = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                contents = {};
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                Object.assign(contents, deserializeAws_restXmlContinuationEvent(data, context));
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlEndEvent_event = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                contents = {};
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                Object.assign(contents, deserializeAws_restXmlEndEvent(data, context));
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlProgressEvent_event = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                contents = {};
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Details = deserializeAws_restXmlProgress(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restXmlRecordsEvent_event = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        contents = {};
        contents.Payload = output.body;
        return [2, contents];
    });
}); };
var deserializeAws_restXmlStatsEvent_event = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                contents = {};
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Details = deserializeAws_restXmlStats(data, context);
                return [2, contents];
        }
    });
}); };
var serializeAws_restXmlAbortIncompleteMultipartUpload = function (input, context) {
    var bodyNode = new __XmlNode("AbortIncompleteMultipartUpload");
    if (input.DaysAfterInitiation != null) {
        var node = __XmlNode.of("DaysAfterInitiation", String(input.DaysAfterInitiation)).withName("DaysAfterInitiation");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlAccelerateConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("AccelerateConfiguration");
    if (input.Status != null) {
        var node = __XmlNode.of("BucketAccelerateStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlAccessControlPolicy = function (input, context) {
    var bodyNode = new __XmlNode("AccessControlPolicy");
    if (input.Grants != null) {
        var nodes = serializeAws_restXmlGrants(input.Grants, context);
        var containerNode_1 = new __XmlNode("AccessControlList");
        nodes.map(function (node) {
            containerNode_1.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_1);
    }
    if (input.Owner != null) {
        var node = serializeAws_restXmlOwner(input.Owner, context).withName("Owner");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlAccessControlTranslation = function (input, context) {
    var bodyNode = new __XmlNode("AccessControlTranslation");
    if (input.Owner != null) {
        var node = __XmlNode.of("OwnerOverride", input.Owner).withName("Owner");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlAllowedHeaders = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = __XmlNode.of("AllowedHeader", entry);
        return node.withName("member");
    });
};
var serializeAws_restXmlAllowedMethods = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = __XmlNode.of("AllowedMethod", entry);
        return node.withName("member");
    });
};
var serializeAws_restXmlAllowedOrigins = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = __XmlNode.of("AllowedOrigin", entry);
        return node.withName("member");
    });
};
var serializeAws_restXmlAnalyticsAndOperator = function (input, context) {
    var bodyNode = new __XmlNode("AnalyticsAndOperator");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Tags != null) {
        var nodes = serializeAws_restXmlTagSet(input.Tags, context);
        nodes.map(function (node) {
            node = node.withName("Tag");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlAnalyticsConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("AnalyticsConfiguration");
    if (input.Id != null) {
        var node = __XmlNode.of("AnalyticsId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlAnalyticsFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    if (input.StorageClassAnalysis != null) {
        var node = serializeAws_restXmlStorageClassAnalysis(input.StorageClassAnalysis, context).withName("StorageClassAnalysis");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlAnalyticsExportDestination = function (input, context) {
    var bodyNode = new __XmlNode("AnalyticsExportDestination");
    if (input.S3BucketDestination != null) {
        var node = serializeAws_restXmlAnalyticsS3BucketDestination(input.S3BucketDestination, context).withName("S3BucketDestination");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlAnalyticsFilter = function (input, context) {
    var bodyNode = new __XmlNode("AnalyticsFilter");
    AnalyticsFilter.visit(input, {
        Prefix: function (value) {
            var node = __XmlNode.of("Prefix", value).withName("Prefix");
            bodyNode.addChildNode(node);
        },
        Tag: function (value) {
            var node = serializeAws_restXmlTag(value, context).withName("Tag");
            bodyNode.addChildNode(node);
        },
        And: function (value) {
            var node = serializeAws_restXmlAnalyticsAndOperator(value, context).withName("And");
            bodyNode.addChildNode(node);
        },
        _: function (name, value) {
            if (!(value instanceof __XmlNode || value instanceof __XmlText)) {
                throw new Error("Unable to serialize unknown union members in XML.");
            }
            bodyNode.addChildNode(new __XmlNode(name).addChildNode(value));
        },
    });
    return bodyNode;
};
var serializeAws_restXmlAnalyticsS3BucketDestination = function (input, context) {
    var bodyNode = new __XmlNode("AnalyticsS3BucketDestination");
    if (input.Format != null) {
        var node = __XmlNode.of("AnalyticsS3ExportFileFormat", input.Format).withName("Format");
        bodyNode.addChildNode(node);
    }
    if (input.BucketAccountId != null) {
        var node = __XmlNode.of("AccountId", input.BucketAccountId).withName("BucketAccountId");
        bodyNode.addChildNode(node);
    }
    if (input.Bucket != null) {
        var node = __XmlNode.of("BucketName", input.Bucket).withName("Bucket");
        bodyNode.addChildNode(node);
    }
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlBucketLifecycleConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("BucketLifecycleConfiguration");
    if (input.Rules != null) {
        var nodes = serializeAws_restXmlLifecycleRules(input.Rules, context);
        nodes.map(function (node) {
            node = node.withName("Rule");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlBucketLoggingStatus = function (input, context) {
    var bodyNode = new __XmlNode("BucketLoggingStatus");
    if (input.LoggingEnabled != null) {
        var node = serializeAws_restXmlLoggingEnabled(input.LoggingEnabled, context).withName("LoggingEnabled");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlCompletedMultipartUpload = function (input, context) {
    var bodyNode = new __XmlNode("CompletedMultipartUpload");
    if (input.Parts != null) {
        var nodes = serializeAws_restXmlCompletedPartList(input.Parts, context);
        nodes.map(function (node) {
            node = node.withName("Part");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlCompletedPart = function (input, context) {
    var bodyNode = new __XmlNode("CompletedPart");
    if (input.ETag != null) {
        var node = __XmlNode.of("ETag", input.ETag).withName("ETag");
        bodyNode.addChildNode(node);
    }
    if (input.ChecksumCRC32 != null) {
        var node = __XmlNode.of("ChecksumCRC32", input.ChecksumCRC32).withName("ChecksumCRC32");
        bodyNode.addChildNode(node);
    }
    if (input.ChecksumCRC32C != null) {
        var node = __XmlNode.of("ChecksumCRC32C", input.ChecksumCRC32C).withName("ChecksumCRC32C");
        bodyNode.addChildNode(node);
    }
    if (input.ChecksumSHA1 != null) {
        var node = __XmlNode.of("ChecksumSHA1", input.ChecksumSHA1).withName("ChecksumSHA1");
        bodyNode.addChildNode(node);
    }
    if (input.ChecksumSHA256 != null) {
        var node = __XmlNode.of("ChecksumSHA256", input.ChecksumSHA256).withName("ChecksumSHA256");
        bodyNode.addChildNode(node);
    }
    if (input.PartNumber != null) {
        var node = __XmlNode.of("PartNumber", String(input.PartNumber)).withName("PartNumber");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlCompletedPartList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlCompletedPart(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlCondition = function (input, context) {
    var bodyNode = new __XmlNode("Condition");
    if (input.HttpErrorCodeReturnedEquals != null) {
        var node = __XmlNode
            .of("HttpErrorCodeReturnedEquals", input.HttpErrorCodeReturnedEquals)
            .withName("HttpErrorCodeReturnedEquals");
        bodyNode.addChildNode(node);
    }
    if (input.KeyPrefixEquals != null) {
        var node = __XmlNode.of("KeyPrefixEquals", input.KeyPrefixEquals).withName("KeyPrefixEquals");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlCORSConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("CORSConfiguration");
    if (input.CORSRules != null) {
        var nodes = serializeAws_restXmlCORSRules(input.CORSRules, context);
        nodes.map(function (node) {
            node = node.withName("CORSRule");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlCORSRule = function (input, context) {
    var bodyNode = new __XmlNode("CORSRule");
    if (input.ID != null) {
        var node = __XmlNode.of("ID", input.ID).withName("ID");
        bodyNode.addChildNode(node);
    }
    if (input.AllowedHeaders != null) {
        var nodes = serializeAws_restXmlAllowedHeaders(input.AllowedHeaders, context);
        nodes.map(function (node) {
            node = node.withName("AllowedHeader");
            bodyNode.addChildNode(node);
        });
    }
    if (input.AllowedMethods != null) {
        var nodes = serializeAws_restXmlAllowedMethods(input.AllowedMethods, context);
        nodes.map(function (node) {
            node = node.withName("AllowedMethod");
            bodyNode.addChildNode(node);
        });
    }
    if (input.AllowedOrigins != null) {
        var nodes = serializeAws_restXmlAllowedOrigins(input.AllowedOrigins, context);
        nodes.map(function (node) {
            node = node.withName("AllowedOrigin");
            bodyNode.addChildNode(node);
        });
    }
    if (input.ExposeHeaders != null) {
        var nodes = serializeAws_restXmlExposeHeaders(input.ExposeHeaders, context);
        nodes.map(function (node) {
            node = node.withName("ExposeHeader");
            bodyNode.addChildNode(node);
        });
    }
    if (input.MaxAgeSeconds != null) {
        var node = __XmlNode.of("MaxAgeSeconds", String(input.MaxAgeSeconds)).withName("MaxAgeSeconds");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlCORSRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlCORSRule(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlCreateBucketConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("CreateBucketConfiguration");
    if (input.LocationConstraint != null) {
        var node = __XmlNode.of("BucketLocationConstraint", input.LocationConstraint).withName("LocationConstraint");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlCSVInput = function (input, context) {
    var bodyNode = new __XmlNode("CSVInput");
    if (input.FileHeaderInfo != null) {
        var node = __XmlNode.of("FileHeaderInfo", input.FileHeaderInfo).withName("FileHeaderInfo");
        bodyNode.addChildNode(node);
    }
    if (input.Comments != null) {
        var node = __XmlNode.of("Comments", input.Comments).withName("Comments");
        bodyNode.addChildNode(node);
    }
    if (input.QuoteEscapeCharacter != null) {
        var node = __XmlNode.of("QuoteEscapeCharacter", input.QuoteEscapeCharacter).withName("QuoteEscapeCharacter");
        bodyNode.addChildNode(node);
    }
    if (input.RecordDelimiter != null) {
        var node = __XmlNode.of("RecordDelimiter", input.RecordDelimiter).withName("RecordDelimiter");
        bodyNode.addChildNode(node);
    }
    if (input.FieldDelimiter != null) {
        var node = __XmlNode.of("FieldDelimiter", input.FieldDelimiter).withName("FieldDelimiter");
        bodyNode.addChildNode(node);
    }
    if (input.QuoteCharacter != null) {
        var node = __XmlNode.of("QuoteCharacter", input.QuoteCharacter).withName("QuoteCharacter");
        bodyNode.addChildNode(node);
    }
    if (input.AllowQuotedRecordDelimiter != null) {
        var node = __XmlNode
            .of("AllowQuotedRecordDelimiter", String(input.AllowQuotedRecordDelimiter))
            .withName("AllowQuotedRecordDelimiter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlCSVOutput = function (input, context) {
    var bodyNode = new __XmlNode("CSVOutput");
    if (input.QuoteFields != null) {
        var node = __XmlNode.of("QuoteFields", input.QuoteFields).withName("QuoteFields");
        bodyNode.addChildNode(node);
    }
    if (input.QuoteEscapeCharacter != null) {
        var node = __XmlNode.of("QuoteEscapeCharacter", input.QuoteEscapeCharacter).withName("QuoteEscapeCharacter");
        bodyNode.addChildNode(node);
    }
    if (input.RecordDelimiter != null) {
        var node = __XmlNode.of("RecordDelimiter", input.RecordDelimiter).withName("RecordDelimiter");
        bodyNode.addChildNode(node);
    }
    if (input.FieldDelimiter != null) {
        var node = __XmlNode.of("FieldDelimiter", input.FieldDelimiter).withName("FieldDelimiter");
        bodyNode.addChildNode(node);
    }
    if (input.QuoteCharacter != null) {
        var node = __XmlNode.of("QuoteCharacter", input.QuoteCharacter).withName("QuoteCharacter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlDefaultRetention = function (input, context) {
    var bodyNode = new __XmlNode("DefaultRetention");
    if (input.Mode != null) {
        var node = __XmlNode.of("ObjectLockRetentionMode", input.Mode).withName("Mode");
        bodyNode.addChildNode(node);
    }
    if (input.Days != null) {
        var node = __XmlNode.of("Days", String(input.Days)).withName("Days");
        bodyNode.addChildNode(node);
    }
    if (input.Years != null) {
        var node = __XmlNode.of("Years", String(input.Years)).withName("Years");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlDelete = function (input, context) {
    var bodyNode = new __XmlNode("Delete");
    if (input.Objects != null) {
        var nodes = serializeAws_restXmlObjectIdentifierList(input.Objects, context);
        nodes.map(function (node) {
            node = node.withName("Object");
            bodyNode.addChildNode(node);
        });
    }
    if (input.Quiet != null) {
        var node = __XmlNode.of("Quiet", String(input.Quiet)).withName("Quiet");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlDeleteMarkerReplication = function (input, context) {
    var bodyNode = new __XmlNode("DeleteMarkerReplication");
    if (input.Status != null) {
        var node = __XmlNode.of("DeleteMarkerReplicationStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlDestination = function (input, context) {
    var bodyNode = new __XmlNode("Destination");
    if (input.Bucket != null) {
        var node = __XmlNode.of("BucketName", input.Bucket).withName("Bucket");
        bodyNode.addChildNode(node);
    }
    if (input.Account != null) {
        var node = __XmlNode.of("AccountId", input.Account).withName("Account");
        bodyNode.addChildNode(node);
    }
    if (input.StorageClass != null) {
        var node = __XmlNode.of("StorageClass", input.StorageClass).withName("StorageClass");
        bodyNode.addChildNode(node);
    }
    if (input.AccessControlTranslation != null) {
        var node = serializeAws_restXmlAccessControlTranslation(input.AccessControlTranslation, context).withName("AccessControlTranslation");
        bodyNode.addChildNode(node);
    }
    if (input.EncryptionConfiguration != null) {
        var node = serializeAws_restXmlEncryptionConfiguration(input.EncryptionConfiguration, context).withName("EncryptionConfiguration");
        bodyNode.addChildNode(node);
    }
    if (input.ReplicationTime != null) {
        var node = serializeAws_restXmlReplicationTime(input.ReplicationTime, context).withName("ReplicationTime");
        bodyNode.addChildNode(node);
    }
    if (input.Metrics != null) {
        var node = serializeAws_restXmlMetrics(input.Metrics, context).withName("Metrics");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlEncryption = function (input, context) {
    var bodyNode = new __XmlNode("Encryption");
    if (input.EncryptionType != null) {
        var node = __XmlNode.of("ServerSideEncryption", input.EncryptionType).withName("EncryptionType");
        bodyNode.addChildNode(node);
    }
    if (input.KMSKeyId != null) {
        var node = __XmlNode.of("SSEKMSKeyId", input.KMSKeyId).withName("KMSKeyId");
        bodyNode.addChildNode(node);
    }
    if (input.KMSContext != null) {
        var node = __XmlNode.of("KMSContext", input.KMSContext).withName("KMSContext");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlEncryptionConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("EncryptionConfiguration");
    if (input.ReplicaKmsKeyID != null) {
        var node = __XmlNode.of("ReplicaKmsKeyID", input.ReplicaKmsKeyID).withName("ReplicaKmsKeyID");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlErrorDocument = function (input, context) {
    var bodyNode = new __XmlNode("ErrorDocument");
    if (input.Key != null) {
        var node = __XmlNode.of("ObjectKey", input.Key).withName("Key");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlEventBridgeConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("EventBridgeConfiguration");
    return bodyNode;
};
var serializeAws_restXmlEventList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = __XmlNode.of("Event", entry);
        return node.withName("member");
    });
};
var serializeAws_restXmlExistingObjectReplication = function (input, context) {
    var bodyNode = new __XmlNode("ExistingObjectReplication");
    if (input.Status != null) {
        var node = __XmlNode.of("ExistingObjectReplicationStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlExposeHeaders = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = __XmlNode.of("ExposeHeader", entry);
        return node.withName("member");
    });
};
var serializeAws_restXmlFilterRule = function (input, context) {
    var bodyNode = new __XmlNode("FilterRule");
    if (input.Name != null) {
        var node = __XmlNode.of("FilterRuleName", input.Name).withName("Name");
        bodyNode.addChildNode(node);
    }
    if (input.Value != null) {
        var node = __XmlNode.of("FilterRuleValue", input.Value).withName("Value");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlFilterRuleList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlFilterRule(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlGlacierJobParameters = function (input, context) {
    var bodyNode = new __XmlNode("GlacierJobParameters");
    if (input.Tier != null) {
        var node = __XmlNode.of("Tier", input.Tier).withName("Tier");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlGrant = function (input, context) {
    var bodyNode = new __XmlNode("Grant");
    if (input.Grantee != null) {
        var node = serializeAws_restXmlGrantee(input.Grantee, context).withName("Grantee");
        node.addAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance");
        bodyNode.addChildNode(node);
    }
    if (input.Permission != null) {
        var node = __XmlNode.of("Permission", input.Permission).withName("Permission");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlGrantee = function (input, context) {
    var bodyNode = new __XmlNode("Grantee");
    if (input.DisplayName != null) {
        var node = __XmlNode.of("DisplayName", input.DisplayName).withName("DisplayName");
        bodyNode.addChildNode(node);
    }
    if (input.EmailAddress != null) {
        var node = __XmlNode.of("EmailAddress", input.EmailAddress).withName("EmailAddress");
        bodyNode.addChildNode(node);
    }
    if (input.ID != null) {
        var node = __XmlNode.of("ID", input.ID).withName("ID");
        bodyNode.addChildNode(node);
    }
    if (input.URI != null) {
        var node = __XmlNode.of("URI", input.URI).withName("URI");
        bodyNode.addChildNode(node);
    }
    if (input.Type != null) {
        bodyNode.addAttribute("xsi:type", input.Type);
    }
    return bodyNode;
};
var serializeAws_restXmlGrants = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlGrant(entry, context);
        return node.withName("Grant");
    });
};
var serializeAws_restXmlIndexDocument = function (input, context) {
    var bodyNode = new __XmlNode("IndexDocument");
    if (input.Suffix != null) {
        var node = __XmlNode.of("Suffix", input.Suffix).withName("Suffix");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInputSerialization = function (input, context) {
    var bodyNode = new __XmlNode("InputSerialization");
    if (input.CSV != null) {
        var node = serializeAws_restXmlCSVInput(input.CSV, context).withName("CSV");
        bodyNode.addChildNode(node);
    }
    if (input.CompressionType != null) {
        var node = __XmlNode.of("CompressionType", input.CompressionType).withName("CompressionType");
        bodyNode.addChildNode(node);
    }
    if (input.JSON != null) {
        var node = serializeAws_restXmlJSONInput(input.JSON, context).withName("JSON");
        bodyNode.addChildNode(node);
    }
    if (input.Parquet != null) {
        var node = serializeAws_restXmlParquetInput(input.Parquet, context).withName("Parquet");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlIntelligentTieringAndOperator = function (input, context) {
    var bodyNode = new __XmlNode("IntelligentTieringAndOperator");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Tags != null) {
        var nodes = serializeAws_restXmlTagSet(input.Tags, context);
        nodes.map(function (node) {
            node = node.withName("Tag");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlIntelligentTieringConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("IntelligentTieringConfiguration");
    if (input.Id != null) {
        var node = __XmlNode.of("IntelligentTieringId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlIntelligentTieringFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    if (input.Status != null) {
        var node = __XmlNode.of("IntelligentTieringStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    if (input.Tierings != null) {
        var nodes = serializeAws_restXmlTieringList(input.Tierings, context);
        nodes.map(function (node) {
            node = node.withName("Tiering");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlIntelligentTieringFilter = function (input, context) {
    var bodyNode = new __XmlNode("IntelligentTieringFilter");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Tag != null) {
        var node = serializeAws_restXmlTag(input.Tag, context).withName("Tag");
        bodyNode.addChildNode(node);
    }
    if (input.And != null) {
        var node = serializeAws_restXmlIntelligentTieringAndOperator(input.And, context).withName("And");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInventoryConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("InventoryConfiguration");
    if (input.Destination != null) {
        var node = serializeAws_restXmlInventoryDestination(input.Destination, context).withName("Destination");
        bodyNode.addChildNode(node);
    }
    if (input.IsEnabled != null) {
        var node = __XmlNode.of("IsEnabled", String(input.IsEnabled)).withName("IsEnabled");
        bodyNode.addChildNode(node);
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlInventoryFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    if (input.Id != null) {
        var node = __XmlNode.of("InventoryId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.IncludedObjectVersions != null) {
        var node = __XmlNode
            .of("InventoryIncludedObjectVersions", input.IncludedObjectVersions)
            .withName("IncludedObjectVersions");
        bodyNode.addChildNode(node);
    }
    if (input.OptionalFields != null) {
        var nodes = serializeAws_restXmlInventoryOptionalFields(input.OptionalFields, context);
        var containerNode_2 = new __XmlNode("OptionalFields");
        nodes.map(function (node) {
            containerNode_2.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_2);
    }
    if (input.Schedule != null) {
        var node = serializeAws_restXmlInventorySchedule(input.Schedule, context).withName("Schedule");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInventoryDestination = function (input, context) {
    var bodyNode = new __XmlNode("InventoryDestination");
    if (input.S3BucketDestination != null) {
        var node = serializeAws_restXmlInventoryS3BucketDestination(input.S3BucketDestination, context).withName("S3BucketDestination");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInventoryEncryption = function (input, context) {
    var bodyNode = new __XmlNode("InventoryEncryption");
    if (input.SSES3 != null) {
        var node = serializeAws_restXmlSSES3(input.SSES3, context).withName("SSE-S3");
        bodyNode.addChildNode(node);
    }
    if (input.SSEKMS != null) {
        var node = serializeAws_restXmlSSEKMS(input.SSEKMS, context).withName("SSE-KMS");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInventoryFilter = function (input, context) {
    var bodyNode = new __XmlNode("InventoryFilter");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInventoryOptionalFields = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = __XmlNode.of("InventoryOptionalField", entry);
        return node.withName("Field");
    });
};
var serializeAws_restXmlInventoryS3BucketDestination = function (input, context) {
    var bodyNode = new __XmlNode("InventoryS3BucketDestination");
    if (input.AccountId != null) {
        var node = __XmlNode.of("AccountId", input.AccountId).withName("AccountId");
        bodyNode.addChildNode(node);
    }
    if (input.Bucket != null) {
        var node = __XmlNode.of("BucketName", input.Bucket).withName("Bucket");
        bodyNode.addChildNode(node);
    }
    if (input.Format != null) {
        var node = __XmlNode.of("InventoryFormat", input.Format).withName("Format");
        bodyNode.addChildNode(node);
    }
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Encryption != null) {
        var node = serializeAws_restXmlInventoryEncryption(input.Encryption, context).withName("Encryption");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlInventorySchedule = function (input, context) {
    var bodyNode = new __XmlNode("InventorySchedule");
    if (input.Frequency != null) {
        var node = __XmlNode.of("InventoryFrequency", input.Frequency).withName("Frequency");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlJSONInput = function (input, context) {
    var bodyNode = new __XmlNode("JSONInput");
    if (input.Type != null) {
        var node = __XmlNode.of("JSONType", input.Type).withName("Type");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlJSONOutput = function (input, context) {
    var bodyNode = new __XmlNode("JSONOutput");
    if (input.RecordDelimiter != null) {
        var node = __XmlNode.of("RecordDelimiter", input.RecordDelimiter).withName("RecordDelimiter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlLambdaFunctionConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("LambdaFunctionConfiguration");
    if (input.Id != null) {
        var node = __XmlNode.of("NotificationId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.LambdaFunctionArn != null) {
        var node = __XmlNode.of("LambdaFunctionArn", input.LambdaFunctionArn).withName("CloudFunction");
        bodyNode.addChildNode(node);
    }
    if (input.Events != null) {
        var nodes = serializeAws_restXmlEventList(input.Events, context);
        nodes.map(function (node) {
            node = node.withName("Event");
            bodyNode.addChildNode(node);
        });
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlNotificationConfigurationFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlLambdaFunctionConfigurationList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlLambdaFunctionConfiguration(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlLifecycleExpiration = function (input, context) {
    var bodyNode = new __XmlNode("LifecycleExpiration");
    if (input.Date != null) {
        var node = __XmlNode.of("Date", input.Date.toISOString().split(".")[0] + "Z").withName("Date");
        bodyNode.addChildNode(node);
    }
    if (input.Days != null) {
        var node = __XmlNode.of("Days", String(input.Days)).withName("Days");
        bodyNode.addChildNode(node);
    }
    if (input.ExpiredObjectDeleteMarker != null) {
        var node = __XmlNode
            .of("ExpiredObjectDeleteMarker", String(input.ExpiredObjectDeleteMarker))
            .withName("ExpiredObjectDeleteMarker");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlLifecycleRule = function (input, context) {
    var bodyNode = new __XmlNode("LifecycleRule");
    if (input.Expiration != null) {
        var node = serializeAws_restXmlLifecycleExpiration(input.Expiration, context).withName("Expiration");
        bodyNode.addChildNode(node);
    }
    if (input.ID != null) {
        var node = __XmlNode.of("ID", input.ID).withName("ID");
        bodyNode.addChildNode(node);
    }
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlLifecycleRuleFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    if (input.Status != null) {
        var node = __XmlNode.of("ExpirationStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    if (input.Transitions != null) {
        var nodes = serializeAws_restXmlTransitionList(input.Transitions, context);
        nodes.map(function (node) {
            node = node.withName("Transition");
            bodyNode.addChildNode(node);
        });
    }
    if (input.NoncurrentVersionTransitions != null) {
        var nodes = serializeAws_restXmlNoncurrentVersionTransitionList(input.NoncurrentVersionTransitions, context);
        nodes.map(function (node) {
            node = node.withName("NoncurrentVersionTransition");
            bodyNode.addChildNode(node);
        });
    }
    if (input.NoncurrentVersionExpiration != null) {
        var node = serializeAws_restXmlNoncurrentVersionExpiration(input.NoncurrentVersionExpiration, context).withName("NoncurrentVersionExpiration");
        bodyNode.addChildNode(node);
    }
    if (input.AbortIncompleteMultipartUpload != null) {
        var node = serializeAws_restXmlAbortIncompleteMultipartUpload(input.AbortIncompleteMultipartUpload, context).withName("AbortIncompleteMultipartUpload");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlLifecycleRuleAndOperator = function (input, context) {
    var bodyNode = new __XmlNode("LifecycleRuleAndOperator");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Tags != null) {
        var nodes = serializeAws_restXmlTagSet(input.Tags, context);
        nodes.map(function (node) {
            node = node.withName("Tag");
            bodyNode.addChildNode(node);
        });
    }
    if (input.ObjectSizeGreaterThan != null) {
        var node = __XmlNode
            .of("ObjectSizeGreaterThanBytes", String(input.ObjectSizeGreaterThan))
            .withName("ObjectSizeGreaterThan");
        bodyNode.addChildNode(node);
    }
    if (input.ObjectSizeLessThan != null) {
        var node = __XmlNode
            .of("ObjectSizeLessThanBytes", String(input.ObjectSizeLessThan))
            .withName("ObjectSizeLessThan");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlLifecycleRuleFilter = function (input, context) {
    var bodyNode = new __XmlNode("LifecycleRuleFilter");
    LifecycleRuleFilter.visit(input, {
        Prefix: function (value) {
            var node = __XmlNode.of("Prefix", value).withName("Prefix");
            bodyNode.addChildNode(node);
        },
        Tag: function (value) {
            var node = serializeAws_restXmlTag(value, context).withName("Tag");
            bodyNode.addChildNode(node);
        },
        ObjectSizeGreaterThan: function (value) {
            var node = __XmlNode.of("ObjectSizeGreaterThanBytes", String(value)).withName("ObjectSizeGreaterThan");
            bodyNode.addChildNode(node);
        },
        ObjectSizeLessThan: function (value) {
            var node = __XmlNode.of("ObjectSizeLessThanBytes", String(value)).withName("ObjectSizeLessThan");
            bodyNode.addChildNode(node);
        },
        And: function (value) {
            var node = serializeAws_restXmlLifecycleRuleAndOperator(value, context).withName("And");
            bodyNode.addChildNode(node);
        },
        _: function (name, value) {
            if (!(value instanceof __XmlNode || value instanceof __XmlText)) {
                throw new Error("Unable to serialize unknown union members in XML.");
            }
            bodyNode.addChildNode(new __XmlNode(name).addChildNode(value));
        },
    });
    return bodyNode;
};
var serializeAws_restXmlLifecycleRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlLifecycleRule(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlLoggingEnabled = function (input, context) {
    var bodyNode = new __XmlNode("LoggingEnabled");
    if (input.TargetBucket != null) {
        var node = __XmlNode.of("TargetBucket", input.TargetBucket).withName("TargetBucket");
        bodyNode.addChildNode(node);
    }
    if (input.TargetGrants != null) {
        var nodes = serializeAws_restXmlTargetGrants(input.TargetGrants, context);
        var containerNode_3 = new __XmlNode("TargetGrants");
        nodes.map(function (node) {
            containerNode_3.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_3);
    }
    if (input.TargetPrefix != null) {
        var node = __XmlNode.of("TargetPrefix", input.TargetPrefix).withName("TargetPrefix");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlMetadataEntry = function (input, context) {
    var bodyNode = new __XmlNode("MetadataEntry");
    if (input.Name != null) {
        var node = __XmlNode.of("MetadataKey", input.Name).withName("Name");
        bodyNode.addChildNode(node);
    }
    if (input.Value != null) {
        var node = __XmlNode.of("MetadataValue", input.Value).withName("Value");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlMetrics = function (input, context) {
    var bodyNode = new __XmlNode("Metrics");
    if (input.Status != null) {
        var node = __XmlNode.of("MetricsStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    if (input.EventThreshold != null) {
        var node = serializeAws_restXmlReplicationTimeValue(input.EventThreshold, context).withName("EventThreshold");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlMetricsAndOperator = function (input, context) {
    var bodyNode = new __XmlNode("MetricsAndOperator");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Tags != null) {
        var nodes = serializeAws_restXmlTagSet(input.Tags, context);
        nodes.map(function (node) {
            node = node.withName("Tag");
            bodyNode.addChildNode(node);
        });
    }
    if (input.AccessPointArn != null) {
        var node = __XmlNode.of("AccessPointArn", input.AccessPointArn).withName("AccessPointArn");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlMetricsConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("MetricsConfiguration");
    if (input.Id != null) {
        var node = __XmlNode.of("MetricsId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlMetricsFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlMetricsFilter = function (input, context) {
    var bodyNode = new __XmlNode("MetricsFilter");
    MetricsFilter.visit(input, {
        Prefix: function (value) {
            var node = __XmlNode.of("Prefix", value).withName("Prefix");
            bodyNode.addChildNode(node);
        },
        Tag: function (value) {
            var node = serializeAws_restXmlTag(value, context).withName("Tag");
            bodyNode.addChildNode(node);
        },
        AccessPointArn: function (value) {
            var node = __XmlNode.of("AccessPointArn", value).withName("AccessPointArn");
            bodyNode.addChildNode(node);
        },
        And: function (value) {
            var node = serializeAws_restXmlMetricsAndOperator(value, context).withName("And");
            bodyNode.addChildNode(node);
        },
        _: function (name, value) {
            if (!(value instanceof __XmlNode || value instanceof __XmlText)) {
                throw new Error("Unable to serialize unknown union members in XML.");
            }
            bodyNode.addChildNode(new __XmlNode(name).addChildNode(value));
        },
    });
    return bodyNode;
};
var serializeAws_restXmlNoncurrentVersionExpiration = function (input, context) {
    var bodyNode = new __XmlNode("NoncurrentVersionExpiration");
    if (input.NoncurrentDays != null) {
        var node = __XmlNode.of("Days", String(input.NoncurrentDays)).withName("NoncurrentDays");
        bodyNode.addChildNode(node);
    }
    if (input.NewerNoncurrentVersions != null) {
        var node = __XmlNode
            .of("VersionCount", String(input.NewerNoncurrentVersions))
            .withName("NewerNoncurrentVersions");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlNoncurrentVersionTransition = function (input, context) {
    var bodyNode = new __XmlNode("NoncurrentVersionTransition");
    if (input.NoncurrentDays != null) {
        var node = __XmlNode.of("Days", String(input.NoncurrentDays)).withName("NoncurrentDays");
        bodyNode.addChildNode(node);
    }
    if (input.StorageClass != null) {
        var node = __XmlNode.of("TransitionStorageClass", input.StorageClass).withName("StorageClass");
        bodyNode.addChildNode(node);
    }
    if (input.NewerNoncurrentVersions != null) {
        var node = __XmlNode
            .of("VersionCount", String(input.NewerNoncurrentVersions))
            .withName("NewerNoncurrentVersions");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlNoncurrentVersionTransitionList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlNoncurrentVersionTransition(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlNotificationConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("NotificationConfiguration");
    if (input.TopicConfigurations != null) {
        var nodes = serializeAws_restXmlTopicConfigurationList(input.TopicConfigurations, context);
        nodes.map(function (node) {
            node = node.withName("TopicConfiguration");
            bodyNode.addChildNode(node);
        });
    }
    if (input.QueueConfigurations != null) {
        var nodes = serializeAws_restXmlQueueConfigurationList(input.QueueConfigurations, context);
        nodes.map(function (node) {
            node = node.withName("QueueConfiguration");
            bodyNode.addChildNode(node);
        });
    }
    if (input.LambdaFunctionConfigurations != null) {
        var nodes = serializeAws_restXmlLambdaFunctionConfigurationList(input.LambdaFunctionConfigurations, context);
        nodes.map(function (node) {
            node = node.withName("CloudFunctionConfiguration");
            bodyNode.addChildNode(node);
        });
    }
    if (input.EventBridgeConfiguration != null) {
        var node = serializeAws_restXmlEventBridgeConfiguration(input.EventBridgeConfiguration, context).withName("EventBridgeConfiguration");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlNotificationConfigurationFilter = function (input, context) {
    var bodyNode = new __XmlNode("NotificationConfigurationFilter");
    if (input.Key != null) {
        var node = serializeAws_restXmlS3KeyFilter(input.Key, context).withName("S3Key");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlObjectIdentifier = function (input, context) {
    var bodyNode = new __XmlNode("ObjectIdentifier");
    if (input.Key != null) {
        var node = __XmlNode.of("ObjectKey", input.Key).withName("Key");
        bodyNode.addChildNode(node);
    }
    if (input.VersionId != null) {
        var node = __XmlNode.of("ObjectVersionId", input.VersionId).withName("VersionId");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlObjectIdentifierList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlObjectIdentifier(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlObjectLockConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("ObjectLockConfiguration");
    if (input.ObjectLockEnabled != null) {
        var node = __XmlNode.of("ObjectLockEnabled", input.ObjectLockEnabled).withName("ObjectLockEnabled");
        bodyNode.addChildNode(node);
    }
    if (input.Rule != null) {
        var node = serializeAws_restXmlObjectLockRule(input.Rule, context).withName("Rule");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlObjectLockLegalHold = function (input, context) {
    var bodyNode = new __XmlNode("ObjectLockLegalHold");
    if (input.Status != null) {
        var node = __XmlNode.of("ObjectLockLegalHoldStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlObjectLockRetention = function (input, context) {
    var bodyNode = new __XmlNode("ObjectLockRetention");
    if (input.Mode != null) {
        var node = __XmlNode.of("ObjectLockRetentionMode", input.Mode).withName("Mode");
        bodyNode.addChildNode(node);
    }
    if (input.RetainUntilDate != null) {
        var node = __XmlNode
            .of("Date", input.RetainUntilDate.toISOString().split(".")[0] + "Z")
            .withName("RetainUntilDate");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlObjectLockRule = function (input, context) {
    var bodyNode = new __XmlNode("ObjectLockRule");
    if (input.DefaultRetention != null) {
        var node = serializeAws_restXmlDefaultRetention(input.DefaultRetention, context).withName("DefaultRetention");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlOutputLocation = function (input, context) {
    var bodyNode = new __XmlNode("OutputLocation");
    if (input.S3 != null) {
        var node = serializeAws_restXmlS3Location(input.S3, context).withName("S3");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlOutputSerialization = function (input, context) {
    var bodyNode = new __XmlNode("OutputSerialization");
    if (input.CSV != null) {
        var node = serializeAws_restXmlCSVOutput(input.CSV, context).withName("CSV");
        bodyNode.addChildNode(node);
    }
    if (input.JSON != null) {
        var node = serializeAws_restXmlJSONOutput(input.JSON, context).withName("JSON");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlOwner = function (input, context) {
    var bodyNode = new __XmlNode("Owner");
    if (input.DisplayName != null) {
        var node = __XmlNode.of("DisplayName", input.DisplayName).withName("DisplayName");
        bodyNode.addChildNode(node);
    }
    if (input.ID != null) {
        var node = __XmlNode.of("ID", input.ID).withName("ID");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlOwnershipControls = function (input, context) {
    var bodyNode = new __XmlNode("OwnershipControls");
    if (input.Rules != null) {
        var nodes = serializeAws_restXmlOwnershipControlsRules(input.Rules, context);
        nodes.map(function (node) {
            node = node.withName("Rule");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlOwnershipControlsRule = function (input, context) {
    var bodyNode = new __XmlNode("OwnershipControlsRule");
    if (input.ObjectOwnership != null) {
        var node = __XmlNode.of("ObjectOwnership", input.ObjectOwnership).withName("ObjectOwnership");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlOwnershipControlsRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlOwnershipControlsRule(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlParquetInput = function (input, context) {
    var bodyNode = new __XmlNode("ParquetInput");
    return bodyNode;
};
var serializeAws_restXmlPublicAccessBlockConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("PublicAccessBlockConfiguration");
    if (input.BlockPublicAcls != null) {
        var node = __XmlNode.of("Setting", String(input.BlockPublicAcls)).withName("BlockPublicAcls");
        bodyNode.addChildNode(node);
    }
    if (input.IgnorePublicAcls != null) {
        var node = __XmlNode.of("Setting", String(input.IgnorePublicAcls)).withName("IgnorePublicAcls");
        bodyNode.addChildNode(node);
    }
    if (input.BlockPublicPolicy != null) {
        var node = __XmlNode.of("Setting", String(input.BlockPublicPolicy)).withName("BlockPublicPolicy");
        bodyNode.addChildNode(node);
    }
    if (input.RestrictPublicBuckets != null) {
        var node = __XmlNode.of("Setting", String(input.RestrictPublicBuckets)).withName("RestrictPublicBuckets");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlQueueConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("QueueConfiguration");
    if (input.Id != null) {
        var node = __XmlNode.of("NotificationId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.QueueArn != null) {
        var node = __XmlNode.of("QueueArn", input.QueueArn).withName("Queue");
        bodyNode.addChildNode(node);
    }
    if (input.Events != null) {
        var nodes = serializeAws_restXmlEventList(input.Events, context);
        nodes.map(function (node) {
            node = node.withName("Event");
            bodyNode.addChildNode(node);
        });
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlNotificationConfigurationFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlQueueConfigurationList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlQueueConfiguration(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlRedirect = function (input, context) {
    var bodyNode = new __XmlNode("Redirect");
    if (input.HostName != null) {
        var node = __XmlNode.of("HostName", input.HostName).withName("HostName");
        bodyNode.addChildNode(node);
    }
    if (input.HttpRedirectCode != null) {
        var node = __XmlNode.of("HttpRedirectCode", input.HttpRedirectCode).withName("HttpRedirectCode");
        bodyNode.addChildNode(node);
    }
    if (input.Protocol != null) {
        var node = __XmlNode.of("Protocol", input.Protocol).withName("Protocol");
        bodyNode.addChildNode(node);
    }
    if (input.ReplaceKeyPrefixWith != null) {
        var node = __XmlNode.of("ReplaceKeyPrefixWith", input.ReplaceKeyPrefixWith).withName("ReplaceKeyPrefixWith");
        bodyNode.addChildNode(node);
    }
    if (input.ReplaceKeyWith != null) {
        var node = __XmlNode.of("ReplaceKeyWith", input.ReplaceKeyWith).withName("ReplaceKeyWith");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlRedirectAllRequestsTo = function (input, context) {
    var bodyNode = new __XmlNode("RedirectAllRequestsTo");
    if (input.HostName != null) {
        var node = __XmlNode.of("HostName", input.HostName).withName("HostName");
        bodyNode.addChildNode(node);
    }
    if (input.Protocol != null) {
        var node = __XmlNode.of("Protocol", input.Protocol).withName("Protocol");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlReplicaModifications = function (input, context) {
    var bodyNode = new __XmlNode("ReplicaModifications");
    if (input.Status != null) {
        var node = __XmlNode.of("ReplicaModificationsStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlReplicationConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("ReplicationConfiguration");
    if (input.Role != null) {
        var node = __XmlNode.of("Role", input.Role).withName("Role");
        bodyNode.addChildNode(node);
    }
    if (input.Rules != null) {
        var nodes = serializeAws_restXmlReplicationRules(input.Rules, context);
        nodes.map(function (node) {
            node = node.withName("Rule");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlReplicationRule = function (input, context) {
    var bodyNode = new __XmlNode("ReplicationRule");
    if (input.ID != null) {
        var node = __XmlNode.of("ID", input.ID).withName("ID");
        bodyNode.addChildNode(node);
    }
    if (input.Priority != null) {
        var node = __XmlNode.of("Priority", String(input.Priority)).withName("Priority");
        bodyNode.addChildNode(node);
    }
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlReplicationRuleFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    if (input.Status != null) {
        var node = __XmlNode.of("ReplicationRuleStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    if (input.SourceSelectionCriteria != null) {
        var node = serializeAws_restXmlSourceSelectionCriteria(input.SourceSelectionCriteria, context).withName("SourceSelectionCriteria");
        bodyNode.addChildNode(node);
    }
    if (input.ExistingObjectReplication != null) {
        var node = serializeAws_restXmlExistingObjectReplication(input.ExistingObjectReplication, context).withName("ExistingObjectReplication");
        bodyNode.addChildNode(node);
    }
    if (input.Destination != null) {
        var node = serializeAws_restXmlDestination(input.Destination, context).withName("Destination");
        bodyNode.addChildNode(node);
    }
    if (input.DeleteMarkerReplication != null) {
        var node = serializeAws_restXmlDeleteMarkerReplication(input.DeleteMarkerReplication, context).withName("DeleteMarkerReplication");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlReplicationRuleAndOperator = function (input, context) {
    var bodyNode = new __XmlNode("ReplicationRuleAndOperator");
    if (input.Prefix != null) {
        var node = __XmlNode.of("Prefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Tags != null) {
        var nodes = serializeAws_restXmlTagSet(input.Tags, context);
        nodes.map(function (node) {
            node = node.withName("Tag");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlReplicationRuleFilter = function (input, context) {
    var bodyNode = new __XmlNode("ReplicationRuleFilter");
    ReplicationRuleFilter.visit(input, {
        Prefix: function (value) {
            var node = __XmlNode.of("Prefix", value).withName("Prefix");
            bodyNode.addChildNode(node);
        },
        Tag: function (value) {
            var node = serializeAws_restXmlTag(value, context).withName("Tag");
            bodyNode.addChildNode(node);
        },
        And: function (value) {
            var node = serializeAws_restXmlReplicationRuleAndOperator(value, context).withName("And");
            bodyNode.addChildNode(node);
        },
        _: function (name, value) {
            if (!(value instanceof __XmlNode || value instanceof __XmlText)) {
                throw new Error("Unable to serialize unknown union members in XML.");
            }
            bodyNode.addChildNode(new __XmlNode(name).addChildNode(value));
        },
    });
    return bodyNode;
};
var serializeAws_restXmlReplicationRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlReplicationRule(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlReplicationTime = function (input, context) {
    var bodyNode = new __XmlNode("ReplicationTime");
    if (input.Status != null) {
        var node = __XmlNode.of("ReplicationTimeStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    if (input.Time != null) {
        var node = serializeAws_restXmlReplicationTimeValue(input.Time, context).withName("Time");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlReplicationTimeValue = function (input, context) {
    var bodyNode = new __XmlNode("ReplicationTimeValue");
    if (input.Minutes != null) {
        var node = __XmlNode.of("Minutes", String(input.Minutes)).withName("Minutes");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlRequestPaymentConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("RequestPaymentConfiguration");
    if (input.Payer != null) {
        var node = __XmlNode.of("Payer", input.Payer).withName("Payer");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlRequestProgress = function (input, context) {
    var bodyNode = new __XmlNode("RequestProgress");
    if (input.Enabled != null) {
        var node = __XmlNode.of("EnableRequestProgress", String(input.Enabled)).withName("Enabled");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlRestoreRequest = function (input, context) {
    var bodyNode = new __XmlNode("RestoreRequest");
    if (input.Days != null) {
        var node = __XmlNode.of("Days", String(input.Days)).withName("Days");
        bodyNode.addChildNode(node);
    }
    if (input.GlacierJobParameters != null) {
        var node = serializeAws_restXmlGlacierJobParameters(input.GlacierJobParameters, context).withName("GlacierJobParameters");
        bodyNode.addChildNode(node);
    }
    if (input.Type != null) {
        var node = __XmlNode.of("RestoreRequestType", input.Type).withName("Type");
        bodyNode.addChildNode(node);
    }
    if (input.Tier != null) {
        var node = __XmlNode.of("Tier", input.Tier).withName("Tier");
        bodyNode.addChildNode(node);
    }
    if (input.Description != null) {
        var node = __XmlNode.of("Description", input.Description).withName("Description");
        bodyNode.addChildNode(node);
    }
    if (input.SelectParameters != null) {
        var node = serializeAws_restXmlSelectParameters(input.SelectParameters, context).withName("SelectParameters");
        bodyNode.addChildNode(node);
    }
    if (input.OutputLocation != null) {
        var node = serializeAws_restXmlOutputLocation(input.OutputLocation, context).withName("OutputLocation");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlRoutingRule = function (input, context) {
    var bodyNode = new __XmlNode("RoutingRule");
    if (input.Condition != null) {
        var node = serializeAws_restXmlCondition(input.Condition, context).withName("Condition");
        bodyNode.addChildNode(node);
    }
    if (input.Redirect != null) {
        var node = serializeAws_restXmlRedirect(input.Redirect, context).withName("Redirect");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlRoutingRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlRoutingRule(entry, context);
        return node.withName("RoutingRule");
    });
};
var serializeAws_restXmlS3KeyFilter = function (input, context) {
    var bodyNode = new __XmlNode("S3KeyFilter");
    if (input.FilterRules != null) {
        var nodes = serializeAws_restXmlFilterRuleList(input.FilterRules, context);
        nodes.map(function (node) {
            node = node.withName("FilterRule");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlS3Location = function (input, context) {
    var bodyNode = new __XmlNode("S3Location");
    if (input.BucketName != null) {
        var node = __XmlNode.of("BucketName", input.BucketName).withName("BucketName");
        bodyNode.addChildNode(node);
    }
    if (input.Prefix != null) {
        var node = __XmlNode.of("LocationPrefix", input.Prefix).withName("Prefix");
        bodyNode.addChildNode(node);
    }
    if (input.Encryption != null) {
        var node = serializeAws_restXmlEncryption(input.Encryption, context).withName("Encryption");
        bodyNode.addChildNode(node);
    }
    if (input.CannedACL != null) {
        var node = __XmlNode.of("ObjectCannedACL", input.CannedACL).withName("CannedACL");
        bodyNode.addChildNode(node);
    }
    if (input.AccessControlList != null) {
        var nodes = serializeAws_restXmlGrants(input.AccessControlList, context);
        var containerNode_4 = new __XmlNode("AccessControlList");
        nodes.map(function (node) {
            containerNode_4.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_4);
    }
    if (input.Tagging != null) {
        var node = serializeAws_restXmlTagging(input.Tagging, context).withName("Tagging");
        bodyNode.addChildNode(node);
    }
    if (input.UserMetadata != null) {
        var nodes = serializeAws_restXmlUserMetadata(input.UserMetadata, context);
        var containerNode_5 = new __XmlNode("UserMetadata");
        nodes.map(function (node) {
            containerNode_5.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_5);
    }
    if (input.StorageClass != null) {
        var node = __XmlNode.of("StorageClass", input.StorageClass).withName("StorageClass");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlScanRange = function (input, context) {
    var bodyNode = new __XmlNode("ScanRange");
    if (input.Start != null) {
        var node = __XmlNode.of("Start", String(input.Start)).withName("Start");
        bodyNode.addChildNode(node);
    }
    if (input.End != null) {
        var node = __XmlNode.of("End", String(input.End)).withName("End");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlSelectParameters = function (input, context) {
    var bodyNode = new __XmlNode("SelectParameters");
    if (input.InputSerialization != null) {
        var node = serializeAws_restXmlInputSerialization(input.InputSerialization, context).withName("InputSerialization");
        bodyNode.addChildNode(node);
    }
    if (input.ExpressionType != null) {
        var node = __XmlNode.of("ExpressionType", input.ExpressionType).withName("ExpressionType");
        bodyNode.addChildNode(node);
    }
    if (input.Expression != null) {
        var node = __XmlNode.of("Expression", input.Expression).withName("Expression");
        bodyNode.addChildNode(node);
    }
    if (input.OutputSerialization != null) {
        var node = serializeAws_restXmlOutputSerialization(input.OutputSerialization, context).withName("OutputSerialization");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlServerSideEncryptionByDefault = function (input, context) {
    var bodyNode = new __XmlNode("ServerSideEncryptionByDefault");
    if (input.SSEAlgorithm != null) {
        var node = __XmlNode.of("ServerSideEncryption", input.SSEAlgorithm).withName("SSEAlgorithm");
        bodyNode.addChildNode(node);
    }
    if (input.KMSMasterKeyID != null) {
        var node = __XmlNode.of("SSEKMSKeyId", input.KMSMasterKeyID).withName("KMSMasterKeyID");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlServerSideEncryptionConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("ServerSideEncryptionConfiguration");
    if (input.Rules != null) {
        var nodes = serializeAws_restXmlServerSideEncryptionRules(input.Rules, context);
        nodes.map(function (node) {
            node = node.withName("Rule");
            bodyNode.addChildNode(node);
        });
    }
    return bodyNode;
};
var serializeAws_restXmlServerSideEncryptionRule = function (input, context) {
    var bodyNode = new __XmlNode("ServerSideEncryptionRule");
    if (input.ApplyServerSideEncryptionByDefault != null) {
        var node = serializeAws_restXmlServerSideEncryptionByDefault(input.ApplyServerSideEncryptionByDefault, context).withName("ApplyServerSideEncryptionByDefault");
        bodyNode.addChildNode(node);
    }
    if (input.BucketKeyEnabled != null) {
        var node = __XmlNode.of("BucketKeyEnabled", String(input.BucketKeyEnabled)).withName("BucketKeyEnabled");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlServerSideEncryptionRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlServerSideEncryptionRule(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlSourceSelectionCriteria = function (input, context) {
    var bodyNode = new __XmlNode("SourceSelectionCriteria");
    if (input.SseKmsEncryptedObjects != null) {
        var node = serializeAws_restXmlSseKmsEncryptedObjects(input.SseKmsEncryptedObjects, context).withName("SseKmsEncryptedObjects");
        bodyNode.addChildNode(node);
    }
    if (input.ReplicaModifications != null) {
        var node = serializeAws_restXmlReplicaModifications(input.ReplicaModifications, context).withName("ReplicaModifications");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlSSEKMS = function (input, context) {
    var bodyNode = new __XmlNode("SSE-KMS");
    if (input.KeyId != null) {
        var node = __XmlNode.of("SSEKMSKeyId", input.KeyId).withName("KeyId");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlSseKmsEncryptedObjects = function (input, context) {
    var bodyNode = new __XmlNode("SseKmsEncryptedObjects");
    if (input.Status != null) {
        var node = __XmlNode.of("SseKmsEncryptedObjectsStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlSSES3 = function (input, context) {
    var bodyNode = new __XmlNode("SSE-S3");
    return bodyNode;
};
var serializeAws_restXmlStorageClassAnalysis = function (input, context) {
    var bodyNode = new __XmlNode("StorageClassAnalysis");
    if (input.DataExport != null) {
        var node = serializeAws_restXmlStorageClassAnalysisDataExport(input.DataExport, context).withName("DataExport");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlStorageClassAnalysisDataExport = function (input, context) {
    var bodyNode = new __XmlNode("StorageClassAnalysisDataExport");
    if (input.OutputSchemaVersion != null) {
        var node = __XmlNode
            .of("StorageClassAnalysisSchemaVersion", input.OutputSchemaVersion)
            .withName("OutputSchemaVersion");
        bodyNode.addChildNode(node);
    }
    if (input.Destination != null) {
        var node = serializeAws_restXmlAnalyticsExportDestination(input.Destination, context).withName("Destination");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlTag = function (input, context) {
    var bodyNode = new __XmlNode("Tag");
    if (input.Key != null) {
        var node = __XmlNode.of("ObjectKey", input.Key).withName("Key");
        bodyNode.addChildNode(node);
    }
    if (input.Value != null) {
        var node = __XmlNode.of("Value", input.Value).withName("Value");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlTagging = function (input, context) {
    var bodyNode = new __XmlNode("Tagging");
    if (input.TagSet != null) {
        var nodes = serializeAws_restXmlTagSet(input.TagSet, context);
        var containerNode_6 = new __XmlNode("TagSet");
        nodes.map(function (node) {
            containerNode_6.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_6);
    }
    return bodyNode;
};
var serializeAws_restXmlTagSet = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlTag(entry, context);
        return node.withName("Tag");
    });
};
var serializeAws_restXmlTargetGrant = function (input, context) {
    var bodyNode = new __XmlNode("TargetGrant");
    if (input.Grantee != null) {
        var node = serializeAws_restXmlGrantee(input.Grantee, context).withName("Grantee");
        node.addAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance");
        bodyNode.addChildNode(node);
    }
    if (input.Permission != null) {
        var node = __XmlNode.of("BucketLogsPermission", input.Permission).withName("Permission");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlTargetGrants = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlTargetGrant(entry, context);
        return node.withName("Grant");
    });
};
var serializeAws_restXmlTiering = function (input, context) {
    var bodyNode = new __XmlNode("Tiering");
    if (input.Days != null) {
        var node = __XmlNode.of("IntelligentTieringDays", String(input.Days)).withName("Days");
        bodyNode.addChildNode(node);
    }
    if (input.AccessTier != null) {
        var node = __XmlNode.of("IntelligentTieringAccessTier", input.AccessTier).withName("AccessTier");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlTieringList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlTiering(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlTopicConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("TopicConfiguration");
    if (input.Id != null) {
        var node = __XmlNode.of("NotificationId", input.Id).withName("Id");
        bodyNode.addChildNode(node);
    }
    if (input.TopicArn != null) {
        var node = __XmlNode.of("TopicArn", input.TopicArn).withName("Topic");
        bodyNode.addChildNode(node);
    }
    if (input.Events != null) {
        var nodes = serializeAws_restXmlEventList(input.Events, context);
        nodes.map(function (node) {
            node = node.withName("Event");
            bodyNode.addChildNode(node);
        });
    }
    if (input.Filter != null) {
        var node = serializeAws_restXmlNotificationConfigurationFilter(input.Filter, context).withName("Filter");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlTopicConfigurationList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlTopicConfiguration(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlTransition = function (input, context) {
    var bodyNode = new __XmlNode("Transition");
    if (input.Date != null) {
        var node = __XmlNode.of("Date", input.Date.toISOString().split(".")[0] + "Z").withName("Date");
        bodyNode.addChildNode(node);
    }
    if (input.Days != null) {
        var node = __XmlNode.of("Days", String(input.Days)).withName("Days");
        bodyNode.addChildNode(node);
    }
    if (input.StorageClass != null) {
        var node = __XmlNode.of("TransitionStorageClass", input.StorageClass).withName("StorageClass");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlTransitionList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlTransition(entry, context);
        return node.withName("member");
    });
};
var serializeAws_restXmlUserMetadata = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        var node = serializeAws_restXmlMetadataEntry(entry, context);
        return node.withName("MetadataEntry");
    });
};
var serializeAws_restXmlVersioningConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("VersioningConfiguration");
    if (input.MFADelete != null) {
        var node = __XmlNode.of("MFADelete", input.MFADelete).withName("MfaDelete");
        bodyNode.addChildNode(node);
    }
    if (input.Status != null) {
        var node = __XmlNode.of("BucketVersioningStatus", input.Status).withName("Status");
        bodyNode.addChildNode(node);
    }
    return bodyNode;
};
var serializeAws_restXmlWebsiteConfiguration = function (input, context) {
    var bodyNode = new __XmlNode("WebsiteConfiguration");
    if (input.ErrorDocument != null) {
        var node = serializeAws_restXmlErrorDocument(input.ErrorDocument, context).withName("ErrorDocument");
        bodyNode.addChildNode(node);
    }
    if (input.IndexDocument != null) {
        var node = serializeAws_restXmlIndexDocument(input.IndexDocument, context).withName("IndexDocument");
        bodyNode.addChildNode(node);
    }
    if (input.RedirectAllRequestsTo != null) {
        var node = serializeAws_restXmlRedirectAllRequestsTo(input.RedirectAllRequestsTo, context).withName("RedirectAllRequestsTo");
        bodyNode.addChildNode(node);
    }
    if (input.RoutingRules != null) {
        var nodes = serializeAws_restXmlRoutingRules(input.RoutingRules, context);
        var containerNode_7 = new __XmlNode("RoutingRules");
        nodes.map(function (node) {
            containerNode_7.addChildNode(node);
        });
        bodyNode.addChildNode(containerNode_7);
    }
    return bodyNode;
};
var deserializeAws_restXmlAbortIncompleteMultipartUpload = function (output, context) {
    var contents = {
        DaysAfterInitiation: undefined,
    };
    if (output["DaysAfterInitiation"] !== undefined) {
        contents.DaysAfterInitiation = __strictParseInt32(output["DaysAfterInitiation"]);
    }
    return contents;
};
var deserializeAws_restXmlAccessControlTranslation = function (output, context) {
    var contents = {
        Owner: undefined,
    };
    if (output["Owner"] !== undefined) {
        contents.Owner = __expectString(output["Owner"]);
    }
    return contents;
};
var deserializeAws_restXmlAllowedHeaders = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlAllowedMethods = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlAllowedOrigins = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlAnalyticsAndOperator = function (output, context) {
    var contents = {
        Prefix: undefined,
        Tags: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Tag === "") {
        contents.Tags = [];
    }
    else if (output["Tag"] !== undefined) {
        contents.Tags = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(output["Tag"]), context);
    }
    return contents;
};
var deserializeAws_restXmlAnalyticsConfiguration = function (output, context) {
    var contents = {
        Id: undefined,
        Filter: undefined,
        StorageClassAnalysis: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output.Filter === "") {
    }
    else if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlAnalyticsFilter(__expectUnion(output["Filter"]), context);
    }
    if (output["StorageClassAnalysis"] !== undefined) {
        contents.StorageClassAnalysis = deserializeAws_restXmlStorageClassAnalysis(output["StorageClassAnalysis"], context);
    }
    return contents;
};
var deserializeAws_restXmlAnalyticsConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlAnalyticsConfiguration(entry, context);
    });
};
var deserializeAws_restXmlAnalyticsExportDestination = function (output, context) {
    var contents = {
        S3BucketDestination: undefined,
    };
    if (output["S3BucketDestination"] !== undefined) {
        contents.S3BucketDestination = deserializeAws_restXmlAnalyticsS3BucketDestination(output["S3BucketDestination"], context);
    }
    return contents;
};
var deserializeAws_restXmlAnalyticsFilter = function (output, context) {
    if (output["Prefix"] !== undefined) {
        return {
            Prefix: __expectString(output["Prefix"]),
        };
    }
    if (output["Tag"] !== undefined) {
        return {
            Tag: deserializeAws_restXmlTag(output["Tag"], context),
        };
    }
    if (output["And"] !== undefined) {
        return {
            And: deserializeAws_restXmlAnalyticsAndOperator(output["And"], context),
        };
    }
    return { $unknown: Object.entries(output)[0] };
};
var deserializeAws_restXmlAnalyticsS3BucketDestination = function (output, context) {
    var contents = {
        Format: undefined,
        BucketAccountId: undefined,
        Bucket: undefined,
        Prefix: undefined,
    };
    if (output["Format"] !== undefined) {
        contents.Format = __expectString(output["Format"]);
    }
    if (output["BucketAccountId"] !== undefined) {
        contents.BucketAccountId = __expectString(output["BucketAccountId"]);
    }
    if (output["Bucket"] !== undefined) {
        contents.Bucket = __expectString(output["Bucket"]);
    }
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    return contents;
};
var deserializeAws_restXmlBucket = function (output, context) {
    var contents = {
        Name: undefined,
        CreationDate: undefined,
    };
    if (output["Name"] !== undefined) {
        contents.Name = __expectString(output["Name"]);
    }
    if (output["CreationDate"] !== undefined) {
        contents.CreationDate = __expectNonNull(__parseRfc3339DateTime(output["CreationDate"]));
    }
    return contents;
};
var deserializeAws_restXmlBuckets = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlBucket(entry, context);
    });
};
var deserializeAws_restXmlChecksum = function (output, context) {
    var contents = {
        ChecksumCRC32: undefined,
        ChecksumCRC32C: undefined,
        ChecksumSHA1: undefined,
        ChecksumSHA256: undefined,
    };
    if (output["ChecksumCRC32"] !== undefined) {
        contents.ChecksumCRC32 = __expectString(output["ChecksumCRC32"]);
    }
    if (output["ChecksumCRC32C"] !== undefined) {
        contents.ChecksumCRC32C = __expectString(output["ChecksumCRC32C"]);
    }
    if (output["ChecksumSHA1"] !== undefined) {
        contents.ChecksumSHA1 = __expectString(output["ChecksumSHA1"]);
    }
    if (output["ChecksumSHA256"] !== undefined) {
        contents.ChecksumSHA256 = __expectString(output["ChecksumSHA256"]);
    }
    return contents;
};
var deserializeAws_restXmlChecksumAlgorithmList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlCommonPrefix = function (output, context) {
    var contents = {
        Prefix: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    return contents;
};
var deserializeAws_restXmlCommonPrefixList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlCommonPrefix(entry, context);
    });
};
var deserializeAws_restXmlCondition = function (output, context) {
    var contents = {
        HttpErrorCodeReturnedEquals: undefined,
        KeyPrefixEquals: undefined,
    };
    if (output["HttpErrorCodeReturnedEquals"] !== undefined) {
        contents.HttpErrorCodeReturnedEquals = __expectString(output["HttpErrorCodeReturnedEquals"]);
    }
    if (output["KeyPrefixEquals"] !== undefined) {
        contents.KeyPrefixEquals = __expectString(output["KeyPrefixEquals"]);
    }
    return contents;
};
var deserializeAws_restXmlContinuationEvent = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_restXmlCopyObjectResult = function (output, context) {
    var contents = {
        ETag: undefined,
        LastModified: undefined,
        ChecksumCRC32: undefined,
        ChecksumCRC32C: undefined,
        ChecksumSHA1: undefined,
        ChecksumSHA256: undefined,
    };
    if (output["ETag"] !== undefined) {
        contents.ETag = __expectString(output["ETag"]);
    }
    if (output["LastModified"] !== undefined) {
        contents.LastModified = __expectNonNull(__parseRfc3339DateTime(output["LastModified"]));
    }
    if (output["ChecksumCRC32"] !== undefined) {
        contents.ChecksumCRC32 = __expectString(output["ChecksumCRC32"]);
    }
    if (output["ChecksumCRC32C"] !== undefined) {
        contents.ChecksumCRC32C = __expectString(output["ChecksumCRC32C"]);
    }
    if (output["ChecksumSHA1"] !== undefined) {
        contents.ChecksumSHA1 = __expectString(output["ChecksumSHA1"]);
    }
    if (output["ChecksumSHA256"] !== undefined) {
        contents.ChecksumSHA256 = __expectString(output["ChecksumSHA256"]);
    }
    return contents;
};
var deserializeAws_restXmlCopyPartResult = function (output, context) {
    var contents = {
        ETag: undefined,
        LastModified: undefined,
        ChecksumCRC32: undefined,
        ChecksumCRC32C: undefined,
        ChecksumSHA1: undefined,
        ChecksumSHA256: undefined,
    };
    if (output["ETag"] !== undefined) {
        contents.ETag = __expectString(output["ETag"]);
    }
    if (output["LastModified"] !== undefined) {
        contents.LastModified = __expectNonNull(__parseRfc3339DateTime(output["LastModified"]));
    }
    if (output["ChecksumCRC32"] !== undefined) {
        contents.ChecksumCRC32 = __expectString(output["ChecksumCRC32"]);
    }
    if (output["ChecksumCRC32C"] !== undefined) {
        contents.ChecksumCRC32C = __expectString(output["ChecksumCRC32C"]);
    }
    if (output["ChecksumSHA1"] !== undefined) {
        contents.ChecksumSHA1 = __expectString(output["ChecksumSHA1"]);
    }
    if (output["ChecksumSHA256"] !== undefined) {
        contents.ChecksumSHA256 = __expectString(output["ChecksumSHA256"]);
    }
    return contents;
};
var deserializeAws_restXmlCORSRule = function (output, context) {
    var contents = {
        ID: undefined,
        AllowedHeaders: undefined,
        AllowedMethods: undefined,
        AllowedOrigins: undefined,
        ExposeHeaders: undefined,
        MaxAgeSeconds: undefined,
    };
    if (output["ID"] !== undefined) {
        contents.ID = __expectString(output["ID"]);
    }
    if (output.AllowedHeader === "") {
        contents.AllowedHeaders = [];
    }
    else if (output["AllowedHeader"] !== undefined) {
        contents.AllowedHeaders = deserializeAws_restXmlAllowedHeaders(__getArrayIfSingleItem(output["AllowedHeader"]), context);
    }
    if (output.AllowedMethod === "") {
        contents.AllowedMethods = [];
    }
    else if (output["AllowedMethod"] !== undefined) {
        contents.AllowedMethods = deserializeAws_restXmlAllowedMethods(__getArrayIfSingleItem(output["AllowedMethod"]), context);
    }
    if (output.AllowedOrigin === "") {
        contents.AllowedOrigins = [];
    }
    else if (output["AllowedOrigin"] !== undefined) {
        contents.AllowedOrigins = deserializeAws_restXmlAllowedOrigins(__getArrayIfSingleItem(output["AllowedOrigin"]), context);
    }
    if (output.ExposeHeader === "") {
        contents.ExposeHeaders = [];
    }
    else if (output["ExposeHeader"] !== undefined) {
        contents.ExposeHeaders = deserializeAws_restXmlExposeHeaders(__getArrayIfSingleItem(output["ExposeHeader"]), context);
    }
    if (output["MaxAgeSeconds"] !== undefined) {
        contents.MaxAgeSeconds = __strictParseInt32(output["MaxAgeSeconds"]);
    }
    return contents;
};
var deserializeAws_restXmlCORSRules = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlCORSRule(entry, context);
    });
};
var deserializeAws_restXmlDefaultRetention = function (output, context) {
    var contents = {
        Mode: undefined,
        Days: undefined,
        Years: undefined,
    };
    if (output["Mode"] !== undefined) {
        contents.Mode = __expectString(output["Mode"]);
    }
    if (output["Days"] !== undefined) {
        contents.Days = __strictParseInt32(output["Days"]);
    }
    if (output["Years"] !== undefined) {
        contents.Years = __strictParseInt32(output["Years"]);
    }
    return contents;
};
var deserializeAws_restXmlDeletedObject = function (output, context) {
    var contents = {
        Key: undefined,
        VersionId: undefined,
        DeleteMarker: undefined,
        DeleteMarkerVersionId: undefined,
    };
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["VersionId"] !== undefined) {
        contents.VersionId = __expectString(output["VersionId"]);
    }
    if (output["DeleteMarker"] !== undefined) {
        contents.DeleteMarker = __parseBoolean(output["DeleteMarker"]);
    }
    if (output["DeleteMarkerVersionId"] !== undefined) {
        contents.DeleteMarkerVersionId = __expectString(output["DeleteMarkerVersionId"]);
    }
    return contents;
};
var deserializeAws_restXmlDeletedObjects = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlDeletedObject(entry, context);
    });
};
var deserializeAws_restXmlDeleteMarkerEntry = function (output, context) {
    var contents = {
        Owner: undefined,
        Key: undefined,
        VersionId: undefined,
        IsLatest: undefined,
        LastModified: undefined,
    };
    if (output["Owner"] !== undefined) {
        contents.Owner = deserializeAws_restXmlOwner(output["Owner"], context);
    }
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["VersionId"] !== undefined) {
        contents.VersionId = __expectString(output["VersionId"]);
    }
    if (output["IsLatest"] !== undefined) {
        contents.IsLatest = __parseBoolean(output["IsLatest"]);
    }
    if (output["LastModified"] !== undefined) {
        contents.LastModified = __expectNonNull(__parseRfc3339DateTime(output["LastModified"]));
    }
    return contents;
};
var deserializeAws_restXmlDeleteMarkerReplication = function (output, context) {
    var contents = {
        Status: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_restXmlDeleteMarkers = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlDeleteMarkerEntry(entry, context);
    });
};
var deserializeAws_restXmlDestination = function (output, context) {
    var contents = {
        Bucket: undefined,
        Account: undefined,
        StorageClass: undefined,
        AccessControlTranslation: undefined,
        EncryptionConfiguration: undefined,
        ReplicationTime: undefined,
        Metrics: undefined,
    };
    if (output["Bucket"] !== undefined) {
        contents.Bucket = __expectString(output["Bucket"]);
    }
    if (output["Account"] !== undefined) {
        contents.Account = __expectString(output["Account"]);
    }
    if (output["StorageClass"] !== undefined) {
        contents.StorageClass = __expectString(output["StorageClass"]);
    }
    if (output["AccessControlTranslation"] !== undefined) {
        contents.AccessControlTranslation = deserializeAws_restXmlAccessControlTranslation(output["AccessControlTranslation"], context);
    }
    if (output["EncryptionConfiguration"] !== undefined) {
        contents.EncryptionConfiguration = deserializeAws_restXmlEncryptionConfiguration(output["EncryptionConfiguration"], context);
    }
    if (output["ReplicationTime"] !== undefined) {
        contents.ReplicationTime = deserializeAws_restXmlReplicationTime(output["ReplicationTime"], context);
    }
    if (output["Metrics"] !== undefined) {
        contents.Metrics = deserializeAws_restXmlMetrics(output["Metrics"], context);
    }
    return contents;
};
var deserializeAws_restXmlEncryptionConfiguration = function (output, context) {
    var contents = {
        ReplicaKmsKeyID: undefined,
    };
    if (output["ReplicaKmsKeyID"] !== undefined) {
        contents.ReplicaKmsKeyID = __expectString(output["ReplicaKmsKeyID"]);
    }
    return contents;
};
var deserializeAws_restXmlEndEvent = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_restXml_Error = function (output, context) {
    var contents = {
        Key: undefined,
        VersionId: undefined,
        Code: undefined,
        Message: undefined,
    };
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["VersionId"] !== undefined) {
        contents.VersionId = __expectString(output["VersionId"]);
    }
    if (output["Code"] !== undefined) {
        contents.Code = __expectString(output["Code"]);
    }
    if (output["Message"] !== undefined) {
        contents.Message = __expectString(output["Message"]);
    }
    return contents;
};
var deserializeAws_restXmlErrorDocument = function (output, context) {
    var contents = {
        Key: undefined,
    };
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    return contents;
};
var deserializeAws_restXmlErrors = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXml_Error(entry, context);
    });
};
var deserializeAws_restXmlEventBridgeConfiguration = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_restXmlEventList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlExistingObjectReplication = function (output, context) {
    var contents = {
        Status: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_restXmlExposeHeaders = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlFilterRule = function (output, context) {
    var contents = {
        Name: undefined,
        Value: undefined,
    };
    if (output["Name"] !== undefined) {
        contents.Name = __expectString(output["Name"]);
    }
    if (output["Value"] !== undefined) {
        contents.Value = __expectString(output["Value"]);
    }
    return contents;
};
var deserializeAws_restXmlFilterRuleList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlFilterRule(entry, context);
    });
};
var deserializeAws_restXmlGetObjectAttributesParts = function (output, context) {
    var contents = {
        TotalPartsCount: undefined,
        PartNumberMarker: undefined,
        NextPartNumberMarker: undefined,
        MaxParts: undefined,
        IsTruncated: undefined,
        Parts: undefined,
    };
    if (output["PartsCount"] !== undefined) {
        contents.TotalPartsCount = __strictParseInt32(output["PartsCount"]);
    }
    if (output["PartNumberMarker"] !== undefined) {
        contents.PartNumberMarker = __expectString(output["PartNumberMarker"]);
    }
    if (output["NextPartNumberMarker"] !== undefined) {
        contents.NextPartNumberMarker = __expectString(output["NextPartNumberMarker"]);
    }
    if (output["MaxParts"] !== undefined) {
        contents.MaxParts = __strictParseInt32(output["MaxParts"]);
    }
    if (output["IsTruncated"] !== undefined) {
        contents.IsTruncated = __parseBoolean(output["IsTruncated"]);
    }
    if (output.Part === "") {
        contents.Parts = [];
    }
    else if (output["Part"] !== undefined) {
        contents.Parts = deserializeAws_restXmlPartsList(__getArrayIfSingleItem(output["Part"]), context);
    }
    return contents;
};
var deserializeAws_restXmlGrant = function (output, context) {
    var contents = {
        Grantee: undefined,
        Permission: undefined,
    };
    if (output["Grantee"] !== undefined) {
        contents.Grantee = deserializeAws_restXmlGrantee(output["Grantee"], context);
    }
    if (output["Permission"] !== undefined) {
        contents.Permission = __expectString(output["Permission"]);
    }
    return contents;
};
var deserializeAws_restXmlGrantee = function (output, context) {
    var contents = {
        DisplayName: undefined,
        EmailAddress: undefined,
        ID: undefined,
        URI: undefined,
        Type: undefined,
    };
    if (output["DisplayName"] !== undefined) {
        contents.DisplayName = __expectString(output["DisplayName"]);
    }
    if (output["EmailAddress"] !== undefined) {
        contents.EmailAddress = __expectString(output["EmailAddress"]);
    }
    if (output["ID"] !== undefined) {
        contents.ID = __expectString(output["ID"]);
    }
    if (output["URI"] !== undefined) {
        contents.URI = __expectString(output["URI"]);
    }
    if (output["xsi:type"] !== undefined) {
        contents.Type = __expectString(output["xsi:type"]);
    }
    return contents;
};
var deserializeAws_restXmlGrants = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlGrant(entry, context);
    });
};
var deserializeAws_restXmlIndexDocument = function (output, context) {
    var contents = {
        Suffix: undefined,
    };
    if (output["Suffix"] !== undefined) {
        contents.Suffix = __expectString(output["Suffix"]);
    }
    return contents;
};
var deserializeAws_restXmlInitiator = function (output, context) {
    var contents = {
        ID: undefined,
        DisplayName: undefined,
    };
    if (output["ID"] !== undefined) {
        contents.ID = __expectString(output["ID"]);
    }
    if (output["DisplayName"] !== undefined) {
        contents.DisplayName = __expectString(output["DisplayName"]);
    }
    return contents;
};
var deserializeAws_restXmlIntelligentTieringAndOperator = function (output, context) {
    var contents = {
        Prefix: undefined,
        Tags: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Tag === "") {
        contents.Tags = [];
    }
    else if (output["Tag"] !== undefined) {
        contents.Tags = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(output["Tag"]), context);
    }
    return contents;
};
var deserializeAws_restXmlIntelligentTieringConfiguration = function (output, context) {
    var contents = {
        Id: undefined,
        Filter: undefined,
        Status: undefined,
        Tierings: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlIntelligentTieringFilter(output["Filter"], context);
    }
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    if (output.Tiering === "") {
        contents.Tierings = [];
    }
    else if (output["Tiering"] !== undefined) {
        contents.Tierings = deserializeAws_restXmlTieringList(__getArrayIfSingleItem(output["Tiering"]), context);
    }
    return contents;
};
var deserializeAws_restXmlIntelligentTieringConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlIntelligentTieringConfiguration(entry, context);
    });
};
var deserializeAws_restXmlIntelligentTieringFilter = function (output, context) {
    var contents = {
        Prefix: undefined,
        Tag: undefined,
        And: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output["Tag"] !== undefined) {
        contents.Tag = deserializeAws_restXmlTag(output["Tag"], context);
    }
    if (output["And"] !== undefined) {
        contents.And = deserializeAws_restXmlIntelligentTieringAndOperator(output["And"], context);
    }
    return contents;
};
var deserializeAws_restXmlInventoryConfiguration = function (output, context) {
    var contents = {
        Destination: undefined,
        IsEnabled: undefined,
        Filter: undefined,
        Id: undefined,
        IncludedObjectVersions: undefined,
        OptionalFields: undefined,
        Schedule: undefined,
    };
    if (output["Destination"] !== undefined) {
        contents.Destination = deserializeAws_restXmlInventoryDestination(output["Destination"], context);
    }
    if (output["IsEnabled"] !== undefined) {
        contents.IsEnabled = __parseBoolean(output["IsEnabled"]);
    }
    if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlInventoryFilter(output["Filter"], context);
    }
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["IncludedObjectVersions"] !== undefined) {
        contents.IncludedObjectVersions = __expectString(output["IncludedObjectVersions"]);
    }
    if (output.OptionalFields === "") {
        contents.OptionalFields = [];
    }
    else if (output["OptionalFields"] !== undefined && output["OptionalFields"]["Field"] !== undefined) {
        contents.OptionalFields = deserializeAws_restXmlInventoryOptionalFields(__getArrayIfSingleItem(output["OptionalFields"]["Field"]), context);
    }
    if (output["Schedule"] !== undefined) {
        contents.Schedule = deserializeAws_restXmlInventorySchedule(output["Schedule"], context);
    }
    return contents;
};
var deserializeAws_restXmlInventoryConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlInventoryConfiguration(entry, context);
    });
};
var deserializeAws_restXmlInventoryDestination = function (output, context) {
    var contents = {
        S3BucketDestination: undefined,
    };
    if (output["S3BucketDestination"] !== undefined) {
        contents.S3BucketDestination = deserializeAws_restXmlInventoryS3BucketDestination(output["S3BucketDestination"], context);
    }
    return contents;
};
var deserializeAws_restXmlInventoryEncryption = function (output, context) {
    var contents = {
        SSES3: undefined,
        SSEKMS: undefined,
    };
    if (output["SSE-S3"] !== undefined) {
        contents.SSES3 = deserializeAws_restXmlSSES3(output["SSE-S3"], context);
    }
    if (output["SSE-KMS"] !== undefined) {
        contents.SSEKMS = deserializeAws_restXmlSSEKMS(output["SSE-KMS"], context);
    }
    return contents;
};
var deserializeAws_restXmlInventoryFilter = function (output, context) {
    var contents = {
        Prefix: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    return contents;
};
var deserializeAws_restXmlInventoryOptionalFields = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return __expectString(entry);
    });
};
var deserializeAws_restXmlInventoryS3BucketDestination = function (output, context) {
    var contents = {
        AccountId: undefined,
        Bucket: undefined,
        Format: undefined,
        Prefix: undefined,
        Encryption: undefined,
    };
    if (output["AccountId"] !== undefined) {
        contents.AccountId = __expectString(output["AccountId"]);
    }
    if (output["Bucket"] !== undefined) {
        contents.Bucket = __expectString(output["Bucket"]);
    }
    if (output["Format"] !== undefined) {
        contents.Format = __expectString(output["Format"]);
    }
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output["Encryption"] !== undefined) {
        contents.Encryption = deserializeAws_restXmlInventoryEncryption(output["Encryption"], context);
    }
    return contents;
};
var deserializeAws_restXmlInventorySchedule = function (output, context) {
    var contents = {
        Frequency: undefined,
    };
    if (output["Frequency"] !== undefined) {
        contents.Frequency = __expectString(output["Frequency"]);
    }
    return contents;
};
var deserializeAws_restXmlLambdaFunctionConfiguration = function (output, context) {
    var contents = {
        Id: undefined,
        LambdaFunctionArn: undefined,
        Events: undefined,
        Filter: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["CloudFunction"] !== undefined) {
        contents.LambdaFunctionArn = __expectString(output["CloudFunction"]);
    }
    if (output.Event === "") {
        contents.Events = [];
    }
    else if (output["Event"] !== undefined) {
        contents.Events = deserializeAws_restXmlEventList(__getArrayIfSingleItem(output["Event"]), context);
    }
    if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlNotificationConfigurationFilter(output["Filter"], context);
    }
    return contents;
};
var deserializeAws_restXmlLambdaFunctionConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlLambdaFunctionConfiguration(entry, context);
    });
};
var deserializeAws_restXmlLifecycleExpiration = function (output, context) {
    var contents = {
        Date: undefined,
        Days: undefined,
        ExpiredObjectDeleteMarker: undefined,
    };
    if (output["Date"] !== undefined) {
        contents.Date = __expectNonNull(__parseRfc3339DateTime(output["Date"]));
    }
    if (output["Days"] !== undefined) {
        contents.Days = __strictParseInt32(output["Days"]);
    }
    if (output["ExpiredObjectDeleteMarker"] !== undefined) {
        contents.ExpiredObjectDeleteMarker = __parseBoolean(output["ExpiredObjectDeleteMarker"]);
    }
    return contents;
};
var deserializeAws_restXmlLifecycleRule = function (output, context) {
    var contents = {
        Expiration: undefined,
        ID: undefined,
        Prefix: undefined,
        Filter: undefined,
        Status: undefined,
        Transitions: undefined,
        NoncurrentVersionTransitions: undefined,
        NoncurrentVersionExpiration: undefined,
        AbortIncompleteMultipartUpload: undefined,
    };
    if (output["Expiration"] !== undefined) {
        contents.Expiration = deserializeAws_restXmlLifecycleExpiration(output["Expiration"], context);
    }
    if (output["ID"] !== undefined) {
        contents.ID = __expectString(output["ID"]);
    }
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Filter === "") {
    }
    else if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlLifecycleRuleFilter(__expectUnion(output["Filter"]), context);
    }
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    if (output.Transition === "") {
        contents.Transitions = [];
    }
    else if (output["Transition"] !== undefined) {
        contents.Transitions = deserializeAws_restXmlTransitionList(__getArrayIfSingleItem(output["Transition"]), context);
    }
    if (output.NoncurrentVersionTransition === "") {
        contents.NoncurrentVersionTransitions = [];
    }
    else if (output["NoncurrentVersionTransition"] !== undefined) {
        contents.NoncurrentVersionTransitions = deserializeAws_restXmlNoncurrentVersionTransitionList(__getArrayIfSingleItem(output["NoncurrentVersionTransition"]), context);
    }
    if (output["NoncurrentVersionExpiration"] !== undefined) {
        contents.NoncurrentVersionExpiration = deserializeAws_restXmlNoncurrentVersionExpiration(output["NoncurrentVersionExpiration"], context);
    }
    if (output["AbortIncompleteMultipartUpload"] !== undefined) {
        contents.AbortIncompleteMultipartUpload = deserializeAws_restXmlAbortIncompleteMultipartUpload(output["AbortIncompleteMultipartUpload"], context);
    }
    return contents;
};
var deserializeAws_restXmlLifecycleRuleAndOperator = function (output, context) {
    var contents = {
        Prefix: undefined,
        Tags: undefined,
        ObjectSizeGreaterThan: undefined,
        ObjectSizeLessThan: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Tag === "") {
        contents.Tags = [];
    }
    else if (output["Tag"] !== undefined) {
        contents.Tags = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(output["Tag"]), context);
    }
    if (output["ObjectSizeGreaterThan"] !== undefined) {
        contents.ObjectSizeGreaterThan = __strictParseLong(output["ObjectSizeGreaterThan"]);
    }
    if (output["ObjectSizeLessThan"] !== undefined) {
        contents.ObjectSizeLessThan = __strictParseLong(output["ObjectSizeLessThan"]);
    }
    return contents;
};
var deserializeAws_restXmlLifecycleRuleFilter = function (output, context) {
    if (output["Prefix"] !== undefined) {
        return {
            Prefix: __expectString(output["Prefix"]),
        };
    }
    if (output["Tag"] !== undefined) {
        return {
            Tag: deserializeAws_restXmlTag(output["Tag"], context),
        };
    }
    if (output["ObjectSizeGreaterThan"] !== undefined) {
        return {
            ObjectSizeGreaterThan: __strictParseLong(output["ObjectSizeGreaterThan"]),
        };
    }
    if (output["ObjectSizeLessThan"] !== undefined) {
        return {
            ObjectSizeLessThan: __strictParseLong(output["ObjectSizeLessThan"]),
        };
    }
    if (output["And"] !== undefined) {
        return {
            And: deserializeAws_restXmlLifecycleRuleAndOperator(output["And"], context),
        };
    }
    return { $unknown: Object.entries(output)[0] };
};
var deserializeAws_restXmlLifecycleRules = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlLifecycleRule(entry, context);
    });
};
var deserializeAws_restXmlLoggingEnabled = function (output, context) {
    var contents = {
        TargetBucket: undefined,
        TargetGrants: undefined,
        TargetPrefix: undefined,
    };
    if (output["TargetBucket"] !== undefined) {
        contents.TargetBucket = __expectString(output["TargetBucket"]);
    }
    if (output.TargetGrants === "") {
        contents.TargetGrants = [];
    }
    else if (output["TargetGrants"] !== undefined && output["TargetGrants"]["Grant"] !== undefined) {
        contents.TargetGrants = deserializeAws_restXmlTargetGrants(__getArrayIfSingleItem(output["TargetGrants"]["Grant"]), context);
    }
    if (output["TargetPrefix"] !== undefined) {
        contents.TargetPrefix = __expectString(output["TargetPrefix"]);
    }
    return contents;
};
var deserializeAws_restXmlMetrics = function (output, context) {
    var contents = {
        Status: undefined,
        EventThreshold: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    if (output["EventThreshold"] !== undefined) {
        contents.EventThreshold = deserializeAws_restXmlReplicationTimeValue(output["EventThreshold"], context);
    }
    return contents;
};
var deserializeAws_restXmlMetricsAndOperator = function (output, context) {
    var contents = {
        Prefix: undefined,
        Tags: undefined,
        AccessPointArn: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Tag === "") {
        contents.Tags = [];
    }
    else if (output["Tag"] !== undefined) {
        contents.Tags = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(output["Tag"]), context);
    }
    if (output["AccessPointArn"] !== undefined) {
        contents.AccessPointArn = __expectString(output["AccessPointArn"]);
    }
    return contents;
};
var deserializeAws_restXmlMetricsConfiguration = function (output, context) {
    var contents = {
        Id: undefined,
        Filter: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output.Filter === "") {
    }
    else if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlMetricsFilter(__expectUnion(output["Filter"]), context);
    }
    return contents;
};
var deserializeAws_restXmlMetricsConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlMetricsConfiguration(entry, context);
    });
};
var deserializeAws_restXmlMetricsFilter = function (output, context) {
    if (output["Prefix"] !== undefined) {
        return {
            Prefix: __expectString(output["Prefix"]),
        };
    }
    if (output["Tag"] !== undefined) {
        return {
            Tag: deserializeAws_restXmlTag(output["Tag"], context),
        };
    }
    if (output["AccessPointArn"] !== undefined) {
        return {
            AccessPointArn: __expectString(output["AccessPointArn"]),
        };
    }
    if (output["And"] !== undefined) {
        return {
            And: deserializeAws_restXmlMetricsAndOperator(output["And"], context),
        };
    }
    return { $unknown: Object.entries(output)[0] };
};
var deserializeAws_restXmlMultipartUpload = function (output, context) {
    var contents = {
        UploadId: undefined,
        Key: undefined,
        Initiated: undefined,
        StorageClass: undefined,
        Owner: undefined,
        Initiator: undefined,
        ChecksumAlgorithm: undefined,
    };
    if (output["UploadId"] !== undefined) {
        contents.UploadId = __expectString(output["UploadId"]);
    }
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["Initiated"] !== undefined) {
        contents.Initiated = __expectNonNull(__parseRfc3339DateTime(output["Initiated"]));
    }
    if (output["StorageClass"] !== undefined) {
        contents.StorageClass = __expectString(output["StorageClass"]);
    }
    if (output["Owner"] !== undefined) {
        contents.Owner = deserializeAws_restXmlOwner(output["Owner"], context);
    }
    if (output["Initiator"] !== undefined) {
        contents.Initiator = deserializeAws_restXmlInitiator(output["Initiator"], context);
    }
    if (output["ChecksumAlgorithm"] !== undefined) {
        contents.ChecksumAlgorithm = __expectString(output["ChecksumAlgorithm"]);
    }
    return contents;
};
var deserializeAws_restXmlMultipartUploadList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlMultipartUpload(entry, context);
    });
};
var deserializeAws_restXmlNoncurrentVersionExpiration = function (output, context) {
    var contents = {
        NoncurrentDays: undefined,
        NewerNoncurrentVersions: undefined,
    };
    if (output["NoncurrentDays"] !== undefined) {
        contents.NoncurrentDays = __strictParseInt32(output["NoncurrentDays"]);
    }
    if (output["NewerNoncurrentVersions"] !== undefined) {
        contents.NewerNoncurrentVersions = __strictParseInt32(output["NewerNoncurrentVersions"]);
    }
    return contents;
};
var deserializeAws_restXmlNoncurrentVersionTransition = function (output, context) {
    var contents = {
        NoncurrentDays: undefined,
        StorageClass: undefined,
        NewerNoncurrentVersions: undefined,
    };
    if (output["NoncurrentDays"] !== undefined) {
        contents.NoncurrentDays = __strictParseInt32(output["NoncurrentDays"]);
    }
    if (output["StorageClass"] !== undefined) {
        contents.StorageClass = __expectString(output["StorageClass"]);
    }
    if (output["NewerNoncurrentVersions"] !== undefined) {
        contents.NewerNoncurrentVersions = __strictParseInt32(output["NewerNoncurrentVersions"]);
    }
    return contents;
};
var deserializeAws_restXmlNoncurrentVersionTransitionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlNoncurrentVersionTransition(entry, context);
    });
};
var deserializeAws_restXmlNotificationConfigurationFilter = function (output, context) {
    var contents = {
        Key: undefined,
    };
    if (output["S3Key"] !== undefined) {
        contents.Key = deserializeAws_restXmlS3KeyFilter(output["S3Key"], context);
    }
    return contents;
};
var deserializeAws_restXml_Object = function (output, context) {
    var contents = {
        Key: undefined,
        LastModified: undefined,
        ETag: undefined,
        ChecksumAlgorithm: undefined,
        Size: undefined,
        StorageClass: undefined,
        Owner: undefined,
    };
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["LastModified"] !== undefined) {
        contents.LastModified = __expectNonNull(__parseRfc3339DateTime(output["LastModified"]));
    }
    if (output["ETag"] !== undefined) {
        contents.ETag = __expectString(output["ETag"]);
    }
    if (output.ChecksumAlgorithm === "") {
        contents.ChecksumAlgorithm = [];
    }
    else if (output["ChecksumAlgorithm"] !== undefined) {
        contents.ChecksumAlgorithm = deserializeAws_restXmlChecksumAlgorithmList(__getArrayIfSingleItem(output["ChecksumAlgorithm"]), context);
    }
    if (output["Size"] !== undefined) {
        contents.Size = __strictParseLong(output["Size"]);
    }
    if (output["StorageClass"] !== undefined) {
        contents.StorageClass = __expectString(output["StorageClass"]);
    }
    if (output["Owner"] !== undefined) {
        contents.Owner = deserializeAws_restXmlOwner(output["Owner"], context);
    }
    return contents;
};
var deserializeAws_restXmlObjectList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXml_Object(entry, context);
    });
};
var deserializeAws_restXmlObjectLockConfiguration = function (output, context) {
    var contents = {
        ObjectLockEnabled: undefined,
        Rule: undefined,
    };
    if (output["ObjectLockEnabled"] !== undefined) {
        contents.ObjectLockEnabled = __expectString(output["ObjectLockEnabled"]);
    }
    if (output["Rule"] !== undefined) {
        contents.Rule = deserializeAws_restXmlObjectLockRule(output["Rule"], context);
    }
    return contents;
};
var deserializeAws_restXmlObjectLockLegalHold = function (output, context) {
    var contents = {
        Status: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_restXmlObjectLockRetention = function (output, context) {
    var contents = {
        Mode: undefined,
        RetainUntilDate: undefined,
    };
    if (output["Mode"] !== undefined) {
        contents.Mode = __expectString(output["Mode"]);
    }
    if (output["RetainUntilDate"] !== undefined) {
        contents.RetainUntilDate = __expectNonNull(__parseRfc3339DateTime(output["RetainUntilDate"]));
    }
    return contents;
};
var deserializeAws_restXmlObjectLockRule = function (output, context) {
    var contents = {
        DefaultRetention: undefined,
    };
    if (output["DefaultRetention"] !== undefined) {
        contents.DefaultRetention = deserializeAws_restXmlDefaultRetention(output["DefaultRetention"], context);
    }
    return contents;
};
var deserializeAws_restXmlObjectPart = function (output, context) {
    var contents = {
        PartNumber: undefined,
        Size: undefined,
        ChecksumCRC32: undefined,
        ChecksumCRC32C: undefined,
        ChecksumSHA1: undefined,
        ChecksumSHA256: undefined,
    };
    if (output["PartNumber"] !== undefined) {
        contents.PartNumber = __strictParseInt32(output["PartNumber"]);
    }
    if (output["Size"] !== undefined) {
        contents.Size = __strictParseLong(output["Size"]);
    }
    if (output["ChecksumCRC32"] !== undefined) {
        contents.ChecksumCRC32 = __expectString(output["ChecksumCRC32"]);
    }
    if (output["ChecksumCRC32C"] !== undefined) {
        contents.ChecksumCRC32C = __expectString(output["ChecksumCRC32C"]);
    }
    if (output["ChecksumSHA1"] !== undefined) {
        contents.ChecksumSHA1 = __expectString(output["ChecksumSHA1"]);
    }
    if (output["ChecksumSHA256"] !== undefined) {
        contents.ChecksumSHA256 = __expectString(output["ChecksumSHA256"]);
    }
    return contents;
};
var deserializeAws_restXmlObjectVersion = function (output, context) {
    var contents = {
        ETag: undefined,
        ChecksumAlgorithm: undefined,
        Size: undefined,
        StorageClass: undefined,
        Key: undefined,
        VersionId: undefined,
        IsLatest: undefined,
        LastModified: undefined,
        Owner: undefined,
    };
    if (output["ETag"] !== undefined) {
        contents.ETag = __expectString(output["ETag"]);
    }
    if (output.ChecksumAlgorithm === "") {
        contents.ChecksumAlgorithm = [];
    }
    else if (output["ChecksumAlgorithm"] !== undefined) {
        contents.ChecksumAlgorithm = deserializeAws_restXmlChecksumAlgorithmList(__getArrayIfSingleItem(output["ChecksumAlgorithm"]), context);
    }
    if (output["Size"] !== undefined) {
        contents.Size = __strictParseLong(output["Size"]);
    }
    if (output["StorageClass"] !== undefined) {
        contents.StorageClass = __expectString(output["StorageClass"]);
    }
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["VersionId"] !== undefined) {
        contents.VersionId = __expectString(output["VersionId"]);
    }
    if (output["IsLatest"] !== undefined) {
        contents.IsLatest = __parseBoolean(output["IsLatest"]);
    }
    if (output["LastModified"] !== undefined) {
        contents.LastModified = __expectNonNull(__parseRfc3339DateTime(output["LastModified"]));
    }
    if (output["Owner"] !== undefined) {
        contents.Owner = deserializeAws_restXmlOwner(output["Owner"], context);
    }
    return contents;
};
var deserializeAws_restXmlObjectVersionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlObjectVersion(entry, context);
    });
};
var deserializeAws_restXmlOwner = function (output, context) {
    var contents = {
        DisplayName: undefined,
        ID: undefined,
    };
    if (output["DisplayName"] !== undefined) {
        contents.DisplayName = __expectString(output["DisplayName"]);
    }
    if (output["ID"] !== undefined) {
        contents.ID = __expectString(output["ID"]);
    }
    return contents;
};
var deserializeAws_restXmlOwnershipControls = function (output, context) {
    var contents = {
        Rules: undefined,
    };
    if (output.Rule === "") {
        contents.Rules = [];
    }
    else if (output["Rule"] !== undefined) {
        contents.Rules = deserializeAws_restXmlOwnershipControlsRules(__getArrayIfSingleItem(output["Rule"]), context);
    }
    return contents;
};
var deserializeAws_restXmlOwnershipControlsRule = function (output, context) {
    var contents = {
        ObjectOwnership: undefined,
    };
    if (output["ObjectOwnership"] !== undefined) {
        contents.ObjectOwnership = __expectString(output["ObjectOwnership"]);
    }
    return contents;
};
var deserializeAws_restXmlOwnershipControlsRules = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlOwnershipControlsRule(entry, context);
    });
};
var deserializeAws_restXmlPart = function (output, context) {
    var contents = {
        PartNumber: undefined,
        LastModified: undefined,
        ETag: undefined,
        Size: undefined,
        ChecksumCRC32: undefined,
        ChecksumCRC32C: undefined,
        ChecksumSHA1: undefined,
        ChecksumSHA256: undefined,
    };
    if (output["PartNumber"] !== undefined) {
        contents.PartNumber = __strictParseInt32(output["PartNumber"]);
    }
    if (output["LastModified"] !== undefined) {
        contents.LastModified = __expectNonNull(__parseRfc3339DateTime(output["LastModified"]));
    }
    if (output["ETag"] !== undefined) {
        contents.ETag = __expectString(output["ETag"]);
    }
    if (output["Size"] !== undefined) {
        contents.Size = __strictParseLong(output["Size"]);
    }
    if (output["ChecksumCRC32"] !== undefined) {
        contents.ChecksumCRC32 = __expectString(output["ChecksumCRC32"]);
    }
    if (output["ChecksumCRC32C"] !== undefined) {
        contents.ChecksumCRC32C = __expectString(output["ChecksumCRC32C"]);
    }
    if (output["ChecksumSHA1"] !== undefined) {
        contents.ChecksumSHA1 = __expectString(output["ChecksumSHA1"]);
    }
    if (output["ChecksumSHA256"] !== undefined) {
        contents.ChecksumSHA256 = __expectString(output["ChecksumSHA256"]);
    }
    return contents;
};
var deserializeAws_restXmlParts = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlPart(entry, context);
    });
};
var deserializeAws_restXmlPartsList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlObjectPart(entry, context);
    });
};
var deserializeAws_restXmlPolicyStatus = function (output, context) {
    var contents = {
        IsPublic: undefined,
    };
    if (output["IsPublic"] !== undefined) {
        contents.IsPublic = __parseBoolean(output["IsPublic"]);
    }
    return contents;
};
var deserializeAws_restXmlProgress = function (output, context) {
    var contents = {
        BytesScanned: undefined,
        BytesProcessed: undefined,
        BytesReturned: undefined,
    };
    if (output["BytesScanned"] !== undefined) {
        contents.BytesScanned = __strictParseLong(output["BytesScanned"]);
    }
    if (output["BytesProcessed"] !== undefined) {
        contents.BytesProcessed = __strictParseLong(output["BytesProcessed"]);
    }
    if (output["BytesReturned"] !== undefined) {
        contents.BytesReturned = __strictParseLong(output["BytesReturned"]);
    }
    return contents;
};
var deserializeAws_restXmlPublicAccessBlockConfiguration = function (output, context) {
    var contents = {
        BlockPublicAcls: undefined,
        IgnorePublicAcls: undefined,
        BlockPublicPolicy: undefined,
        RestrictPublicBuckets: undefined,
    };
    if (output["BlockPublicAcls"] !== undefined) {
        contents.BlockPublicAcls = __parseBoolean(output["BlockPublicAcls"]);
    }
    if (output["IgnorePublicAcls"] !== undefined) {
        contents.IgnorePublicAcls = __parseBoolean(output["IgnorePublicAcls"]);
    }
    if (output["BlockPublicPolicy"] !== undefined) {
        contents.BlockPublicPolicy = __parseBoolean(output["BlockPublicPolicy"]);
    }
    if (output["RestrictPublicBuckets"] !== undefined) {
        contents.RestrictPublicBuckets = __parseBoolean(output["RestrictPublicBuckets"]);
    }
    return contents;
};
var deserializeAws_restXmlQueueConfiguration = function (output, context) {
    var contents = {
        Id: undefined,
        QueueArn: undefined,
        Events: undefined,
        Filter: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["Queue"] !== undefined) {
        contents.QueueArn = __expectString(output["Queue"]);
    }
    if (output.Event === "") {
        contents.Events = [];
    }
    else if (output["Event"] !== undefined) {
        contents.Events = deserializeAws_restXmlEventList(__getArrayIfSingleItem(output["Event"]), context);
    }
    if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlNotificationConfigurationFilter(output["Filter"], context);
    }
    return contents;
};
var deserializeAws_restXmlQueueConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlQueueConfiguration(entry, context);
    });
};
var deserializeAws_restXmlRedirect = function (output, context) {
    var contents = {
        HostName: undefined,
        HttpRedirectCode: undefined,
        Protocol: undefined,
        ReplaceKeyPrefixWith: undefined,
        ReplaceKeyWith: undefined,
    };
    if (output["HostName"] !== undefined) {
        contents.HostName = __expectString(output["HostName"]);
    }
    if (output["HttpRedirectCode"] !== undefined) {
        contents.HttpRedirectCode = __expectString(output["HttpRedirectCode"]);
    }
    if (output["Protocol"] !== undefined) {
        contents.Protocol = __expectString(output["Protocol"]);
    }
    if (output["ReplaceKeyPrefixWith"] !== undefined) {
        contents.ReplaceKeyPrefixWith = __expectString(output["ReplaceKeyPrefixWith"]);
    }
    if (output["ReplaceKeyWith"] !== undefined) {
        contents.ReplaceKeyWith = __expectString(output["ReplaceKeyWith"]);
    }
    return contents;
};
var deserializeAws_restXmlRedirectAllRequestsTo = function (output, context) {
    var contents = {
        HostName: undefined,
        Protocol: undefined,
    };
    if (output["HostName"] !== undefined) {
        contents.HostName = __expectString(output["HostName"]);
    }
    if (output["Protocol"] !== undefined) {
        contents.Protocol = __expectString(output["Protocol"]);
    }
    return contents;
};
var deserializeAws_restXmlReplicaModifications = function (output, context) {
    var contents = {
        Status: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_restXmlReplicationConfiguration = function (output, context) {
    var contents = {
        Role: undefined,
        Rules: undefined,
    };
    if (output["Role"] !== undefined) {
        contents.Role = __expectString(output["Role"]);
    }
    if (output.Rule === "") {
        contents.Rules = [];
    }
    else if (output["Rule"] !== undefined) {
        contents.Rules = deserializeAws_restXmlReplicationRules(__getArrayIfSingleItem(output["Rule"]), context);
    }
    return contents;
};
var deserializeAws_restXmlReplicationRule = function (output, context) {
    var contents = {
        ID: undefined,
        Priority: undefined,
        Prefix: undefined,
        Filter: undefined,
        Status: undefined,
        SourceSelectionCriteria: undefined,
        ExistingObjectReplication: undefined,
        Destination: undefined,
        DeleteMarkerReplication: undefined,
    };
    if (output["ID"] !== undefined) {
        contents.ID = __expectString(output["ID"]);
    }
    if (output["Priority"] !== undefined) {
        contents.Priority = __strictParseInt32(output["Priority"]);
    }
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Filter === "") {
    }
    else if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlReplicationRuleFilter(__expectUnion(output["Filter"]), context);
    }
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    if (output["SourceSelectionCriteria"] !== undefined) {
        contents.SourceSelectionCriteria = deserializeAws_restXmlSourceSelectionCriteria(output["SourceSelectionCriteria"], context);
    }
    if (output["ExistingObjectReplication"] !== undefined) {
        contents.ExistingObjectReplication = deserializeAws_restXmlExistingObjectReplication(output["ExistingObjectReplication"], context);
    }
    if (output["Destination"] !== undefined) {
        contents.Destination = deserializeAws_restXmlDestination(output["Destination"], context);
    }
    if (output["DeleteMarkerReplication"] !== undefined) {
        contents.DeleteMarkerReplication = deserializeAws_restXmlDeleteMarkerReplication(output["DeleteMarkerReplication"], context);
    }
    return contents;
};
var deserializeAws_restXmlReplicationRuleAndOperator = function (output, context) {
    var contents = {
        Prefix: undefined,
        Tags: undefined,
    };
    if (output["Prefix"] !== undefined) {
        contents.Prefix = __expectString(output["Prefix"]);
    }
    if (output.Tag === "") {
        contents.Tags = [];
    }
    else if (output["Tag"] !== undefined) {
        contents.Tags = deserializeAws_restXmlTagSet(__getArrayIfSingleItem(output["Tag"]), context);
    }
    return contents;
};
var deserializeAws_restXmlReplicationRuleFilter = function (output, context) {
    if (output["Prefix"] !== undefined) {
        return {
            Prefix: __expectString(output["Prefix"]),
        };
    }
    if (output["Tag"] !== undefined) {
        return {
            Tag: deserializeAws_restXmlTag(output["Tag"], context),
        };
    }
    if (output["And"] !== undefined) {
        return {
            And: deserializeAws_restXmlReplicationRuleAndOperator(output["And"], context),
        };
    }
    return { $unknown: Object.entries(output)[0] };
};
var deserializeAws_restXmlReplicationRules = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlReplicationRule(entry, context);
    });
};
var deserializeAws_restXmlReplicationTime = function (output, context) {
    var contents = {
        Status: undefined,
        Time: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    if (output["Time"] !== undefined) {
        contents.Time = deserializeAws_restXmlReplicationTimeValue(output["Time"], context);
    }
    return contents;
};
var deserializeAws_restXmlReplicationTimeValue = function (output, context) {
    var contents = {
        Minutes: undefined,
    };
    if (output["Minutes"] !== undefined) {
        contents.Minutes = __strictParseInt32(output["Minutes"]);
    }
    return contents;
};
var deserializeAws_restXmlRoutingRule = function (output, context) {
    var contents = {
        Condition: undefined,
        Redirect: undefined,
    };
    if (output["Condition"] !== undefined) {
        contents.Condition = deserializeAws_restXmlCondition(output["Condition"], context);
    }
    if (output["Redirect"] !== undefined) {
        contents.Redirect = deserializeAws_restXmlRedirect(output["Redirect"], context);
    }
    return contents;
};
var deserializeAws_restXmlRoutingRules = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlRoutingRule(entry, context);
    });
};
var deserializeAws_restXmlS3KeyFilter = function (output, context) {
    var contents = {
        FilterRules: undefined,
    };
    if (output.FilterRule === "") {
        contents.FilterRules = [];
    }
    else if (output["FilterRule"] !== undefined) {
        contents.FilterRules = deserializeAws_restXmlFilterRuleList(__getArrayIfSingleItem(output["FilterRule"]), context);
    }
    return contents;
};
var deserializeAws_restXmlServerSideEncryptionByDefault = function (output, context) {
    var contents = {
        SSEAlgorithm: undefined,
        KMSMasterKeyID: undefined,
    };
    if (output["SSEAlgorithm"] !== undefined) {
        contents.SSEAlgorithm = __expectString(output["SSEAlgorithm"]);
    }
    if (output["KMSMasterKeyID"] !== undefined) {
        contents.KMSMasterKeyID = __expectString(output["KMSMasterKeyID"]);
    }
    return contents;
};
var deserializeAws_restXmlServerSideEncryptionConfiguration = function (output, context) {
    var contents = {
        Rules: undefined,
    };
    if (output.Rule === "") {
        contents.Rules = [];
    }
    else if (output["Rule"] !== undefined) {
        contents.Rules = deserializeAws_restXmlServerSideEncryptionRules(__getArrayIfSingleItem(output["Rule"]), context);
    }
    return contents;
};
var deserializeAws_restXmlServerSideEncryptionRule = function (output, context) {
    var contents = {
        ApplyServerSideEncryptionByDefault: undefined,
        BucketKeyEnabled: undefined,
    };
    if (output["ApplyServerSideEncryptionByDefault"] !== undefined) {
        contents.ApplyServerSideEncryptionByDefault = deserializeAws_restXmlServerSideEncryptionByDefault(output["ApplyServerSideEncryptionByDefault"], context);
    }
    if (output["BucketKeyEnabled"] !== undefined) {
        contents.BucketKeyEnabled = __parseBoolean(output["BucketKeyEnabled"]);
    }
    return contents;
};
var deserializeAws_restXmlServerSideEncryptionRules = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlServerSideEncryptionRule(entry, context);
    });
};
var deserializeAws_restXmlSourceSelectionCriteria = function (output, context) {
    var contents = {
        SseKmsEncryptedObjects: undefined,
        ReplicaModifications: undefined,
    };
    if (output["SseKmsEncryptedObjects"] !== undefined) {
        contents.SseKmsEncryptedObjects = deserializeAws_restXmlSseKmsEncryptedObjects(output["SseKmsEncryptedObjects"], context);
    }
    if (output["ReplicaModifications"] !== undefined) {
        contents.ReplicaModifications = deserializeAws_restXmlReplicaModifications(output["ReplicaModifications"], context);
    }
    return contents;
};
var deserializeAws_restXmlSSEKMS = function (output, context) {
    var contents = {
        KeyId: undefined,
    };
    if (output["KeyId"] !== undefined) {
        contents.KeyId = __expectString(output["KeyId"]);
    }
    return contents;
};
var deserializeAws_restXmlSseKmsEncryptedObjects = function (output, context) {
    var contents = {
        Status: undefined,
    };
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_restXmlSSES3 = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_restXmlStats = function (output, context) {
    var contents = {
        BytesScanned: undefined,
        BytesProcessed: undefined,
        BytesReturned: undefined,
    };
    if (output["BytesScanned"] !== undefined) {
        contents.BytesScanned = __strictParseLong(output["BytesScanned"]);
    }
    if (output["BytesProcessed"] !== undefined) {
        contents.BytesProcessed = __strictParseLong(output["BytesProcessed"]);
    }
    if (output["BytesReturned"] !== undefined) {
        contents.BytesReturned = __strictParseLong(output["BytesReturned"]);
    }
    return contents;
};
var deserializeAws_restXmlStorageClassAnalysis = function (output, context) {
    var contents = {
        DataExport: undefined,
    };
    if (output["DataExport"] !== undefined) {
        contents.DataExport = deserializeAws_restXmlStorageClassAnalysisDataExport(output["DataExport"], context);
    }
    return contents;
};
var deserializeAws_restXmlStorageClassAnalysisDataExport = function (output, context) {
    var contents = {
        OutputSchemaVersion: undefined,
        Destination: undefined,
    };
    if (output["OutputSchemaVersion"] !== undefined) {
        contents.OutputSchemaVersion = __expectString(output["OutputSchemaVersion"]);
    }
    if (output["Destination"] !== undefined) {
        contents.Destination = deserializeAws_restXmlAnalyticsExportDestination(output["Destination"], context);
    }
    return contents;
};
var deserializeAws_restXmlTag = function (output, context) {
    var contents = {
        Key: undefined,
        Value: undefined,
    };
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["Value"] !== undefined) {
        contents.Value = __expectString(output["Value"]);
    }
    return contents;
};
var deserializeAws_restXmlTagSet = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlTag(entry, context);
    });
};
var deserializeAws_restXmlTargetGrant = function (output, context) {
    var contents = {
        Grantee: undefined,
        Permission: undefined,
    };
    if (output["Grantee"] !== undefined) {
        contents.Grantee = deserializeAws_restXmlGrantee(output["Grantee"], context);
    }
    if (output["Permission"] !== undefined) {
        contents.Permission = __expectString(output["Permission"]);
    }
    return contents;
};
var deserializeAws_restXmlTargetGrants = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlTargetGrant(entry, context);
    });
};
var deserializeAws_restXmlTiering = function (output, context) {
    var contents = {
        Days: undefined,
        AccessTier: undefined,
    };
    if (output["Days"] !== undefined) {
        contents.Days = __strictParseInt32(output["Days"]);
    }
    if (output["AccessTier"] !== undefined) {
        contents.AccessTier = __expectString(output["AccessTier"]);
    }
    return contents;
};
var deserializeAws_restXmlTieringList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlTiering(entry, context);
    });
};
var deserializeAws_restXmlTopicConfiguration = function (output, context) {
    var contents = {
        Id: undefined,
        TopicArn: undefined,
        Events: undefined,
        Filter: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["Topic"] !== undefined) {
        contents.TopicArn = __expectString(output["Topic"]);
    }
    if (output.Event === "") {
        contents.Events = [];
    }
    else if (output["Event"] !== undefined) {
        contents.Events = deserializeAws_restXmlEventList(__getArrayIfSingleItem(output["Event"]), context);
    }
    if (output["Filter"] !== undefined) {
        contents.Filter = deserializeAws_restXmlNotificationConfigurationFilter(output["Filter"], context);
    }
    return contents;
};
var deserializeAws_restXmlTopicConfigurationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlTopicConfiguration(entry, context);
    });
};
var deserializeAws_restXmlTransition = function (output, context) {
    var contents = {
        Date: undefined,
        Days: undefined,
        StorageClass: undefined,
    };
    if (output["Date"] !== undefined) {
        contents.Date = __expectNonNull(__parseRfc3339DateTime(output["Date"]));
    }
    if (output["Days"] !== undefined) {
        contents.Days = __strictParseInt32(output["Days"]);
    }
    if (output["StorageClass"] !== undefined) {
        contents.StorageClass = __expectString(output["StorageClass"]);
    }
    return contents;
};
var deserializeAws_restXmlTransitionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return deserializeAws_restXmlTransition(entry, context);
    });
};
var deserializeMetadata = function (output) {
    var _a;
    return ({
        httpStatusCode: output.statusCode,
        requestId: (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"],
        extendedRequestId: output.headers["x-amz-id-2"],
        cfId: output.headers["x-amz-cf-id"],
    });
};
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
var isSerializableHeaderValue = function (value) {
    return value !== undefined &&
        value !== null &&
        value !== "" &&
        (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
        (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
};
var parseBody = function (streamBody, context) {
    return collectBodyString(streamBody, context).then(function (encoded) {
        if (encoded.length) {
            var parsedObj = xmlParse(encoded, {
                attributeNamePrefix: "",
                ignoreAttributes: false,
                parseNodeValue: false,
                trimValues: false,
                tagValueProcessor: function (val) { return (val.trim() === "" && val.includes("\n") ? "" : decodeHTML(val)); },
            });
            var textNodeName = "#text";
            var key = Object.keys(parsedObj)[0];
            var parsedObjToReturn = parsedObj[key];
            if (parsedObjToReturn[textNodeName]) {
                parsedObjToReturn[key] = parsedObjToReturn[textNodeName];
                delete parsedObjToReturn[textNodeName];
            }
            return __getValueFromTextNode(parsedObjToReturn);
        }
        return {};
    });
};
var loadRestXmlErrorCode = function (output, data) {
    if (data.Code !== undefined) {
        return data.Code;
    }
    if (output.statusCode == 404) {
        return "NotFound";
    }
};
