import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { getChecksumAlgorithmForRequest } from "./getChecksumAlgorithmForRequest";
import { getChecksumLocationName } from "./getChecksumLocationName";
import { hasHeader } from "./hasHeader";
import { isStreaming } from "./isStreaming";
import { selectChecksumAlgorithmFunction } from "./selectChecksumAlgorithmFunction";
import { stringHasher } from "./stringHasher";
import { validateChecksumFromResponse } from "./validateChecksumFromResponse";
export var flexibleChecksumsMiddleware = function (config, middlewareConfig) {
    return function (next) {
        return function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var request, requestBody, headers, base64Encoder, streamHasher, input, requestChecksumRequired, requestAlgorithmMember, checksumAlgorithm, updatedBody, updatedHeaders, checksumLocationName, checksumAlgorithmFn, getAwsChunkedEncodingStream, bodyLengthChecker, rawChecksum, result, requestValidationModeMember, responseAlgorithms;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!HttpRequest.isInstance(args.request)) {
                            return [2, next(args)];
                        }
                        request = args.request;
                        requestBody = request.body, headers = request.headers;
                        base64Encoder = config.base64Encoder, streamHasher = config.streamHasher;
                        input = middlewareConfig.input, requestChecksumRequired = middlewareConfig.requestChecksumRequired, requestAlgorithmMember = middlewareConfig.requestAlgorithmMember;
                        checksumAlgorithm = getChecksumAlgorithmForRequest(input, {
                            requestChecksumRequired: requestChecksumRequired,
                            requestAlgorithmMember: requestAlgorithmMember,
                        });
                        updatedBody = requestBody;
                        updatedHeaders = headers;
                        if (!checksumAlgorithm) return [3, 3];
                        checksumLocationName = getChecksumLocationName(checksumAlgorithm);
                        checksumAlgorithmFn = selectChecksumAlgorithmFunction(checksumAlgorithm, config);
                        if (!isStreaming(requestBody)) return [3, 1];
                        getAwsChunkedEncodingStream = config.getAwsChunkedEncodingStream, bodyLengthChecker = config.bodyLengthChecker;
                        updatedBody = getAwsChunkedEncodingStream(requestBody, {
                            base64Encoder: base64Encoder,
                            bodyLengthChecker: bodyLengthChecker,
                            checksumLocationName: checksumLocationName,
                            checksumAlgorithmFn: checksumAlgorithmFn,
                            streamHasher: streamHasher,
                        });
                        updatedHeaders = __assign(__assign({}, headers), { "content-encoding": "aws-chunked", "transfer-encoding": "chunked", "x-amz-decoded-content-length": headers["content-length"], "x-amz-content-sha256": "STREAMING-UNSIGNED-PAYLOAD-TRAILER", "x-amz-trailer": checksumLocationName });
                        delete updatedHeaders["content-length"];
                        return [3, 3];
                    case 1:
                        if (!!hasHeader(checksumLocationName, headers)) return [3, 3];
                        return [4, stringHasher(checksumAlgorithmFn, requestBody)];
                    case 2:
                        rawChecksum = _b.sent();
                        updatedHeaders = __assign(__assign({}, headers), (_a = {}, _a[checksumLocationName] = base64Encoder(rawChecksum), _a));
                        _b.label = 3;
                    case 3: return [4, next(__assign(__assign({}, args), { request: __assign(__assign({}, request), { headers: updatedHeaders, body: updatedBody }) }))];
                    case 4:
                        result = _b.sent();
                        requestValidationModeMember = middlewareConfig.requestValidationModeMember, responseAlgorithms = middlewareConfig.responseAlgorithms;
                        if (requestValidationModeMember && input[requestValidationModeMember] === "ENABLED") {
                            validateChecksumFromResponse(result.response, {
                                config: config,
                                responseAlgorithms: responseAlgorithms,
                            });
                        }
                        return [2, result];
                }
            });
        }); };
    };
};
