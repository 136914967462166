// ../../react-shim.js
import React from "react";

// src/radio.tsx
import {
  chakra as chakra3,
  forwardRef as forwardRef2,
  layoutPropNames,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/dist/index.esm.js
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !Array.isArray(value);
}
var dataAttr = (condition) => condition ? "" : void 0;
var ariaAttr = (condition) => condition ? true : void 0;
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}
function callAll(...fns) {
  return function mergedFn(arg) {
    fns.forEach((fn) => {
      fn == null ? void 0 : fn(arg);
    });
  };
}

// ../../utilities/object-utils/dist/index.esm.js
function split(object, keys) {
  const picked = {};
  const omitted = {};
  for (const [key, value] of Object.entries(object)) {
    if (keys.includes(key))
      picked[key] = value;
    else
      omitted[key] = value;
  }
  return [picked, omitted];
}

// src/radio-group.tsx
import {
  chakra,
  forwardRef
} from "@chakra-ui/system";
import { createContext } from "@chakra-ui/react-context";

// src/use-radio-group.ts
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { useCallback, useRef, useState, useId } from "react";
function isInputEvent(value) {
  return value && isObject(value) && isObject(value.target);
}
function useRadioGroup(props = {}) {
  const {
    onChange: onChangeProp,
    value: valueProp,
    defaultValue,
    name: nameProp,
    isDisabled,
    isFocusable,
    isNative,
    ...htmlProps
  } = props;
  const [valueState, setValue] = useState(defaultValue || "");
  const isControlled = typeof valueProp !== "undefined";
  const value = isControlled ? valueProp : valueState;
  const ref = useRef(null);
  const focus = useCallback(() => {
    const rootNode = ref.current;
    if (!rootNode)
      return;
    let query = `input:not(:disabled):checked`;
    const firstEnabledAndCheckedInput = rootNode.querySelector(query);
    if (firstEnabledAndCheckedInput) {
      firstEnabledAndCheckedInput.focus();
      return;
    }
    query = `input:not(:disabled)`;
    const firstEnabledInput = rootNode.querySelector(query);
    firstEnabledInput == null ? void 0 : firstEnabledInput.focus();
  }, []);
  const uuid = useId();
  const fallbackName = `radio-${uuid}`;
  const name = nameProp || fallbackName;
  const onChange = useCallback((eventOrValue) => {
    const nextValue = isInputEvent(eventOrValue) ? eventOrValue.target.value : eventOrValue;
    if (!isControlled) {
      setValue(nextValue);
    }
    onChangeProp == null ? void 0 : onChangeProp(String(nextValue));
  }, [onChangeProp, isControlled]);
  const getRootProps = useCallback((props2 = {}, forwardedRef = null) => ({
    ...props2,
    ref: mergeRefs(forwardedRef, ref),
    role: "radiogroup"
  }), []);
  const getRadioProps = useCallback((props2 = {}, ref2 = null) => {
    const checkedKey = isNative ? "checked" : "isChecked";
    return {
      ...props2,
      ref: ref2,
      name,
      [checkedKey]: value != null ? props2.value === value : void 0,
      onChange(event) {
        onChange(event);
      },
      "data-radiogroup": true
    };
  }, [isNative, name, onChange, value]);
  return {
    getRootProps,
    getRadioProps,
    name,
    ref,
    focus,
    setValue,
    value,
    onChange,
    isDisabled,
    isFocusable,
    htmlProps
  };
}

// src/radio-group.tsx
import { useMemo } from "react";
var [RadioGroupProvider, useRadioGroupContext] = createContext({
  name: "RadioGroupContext",
  strict: false
});
var RadioGroup = forwardRef((props, ref) => {
  const {
    colorScheme,
    size,
    variant,
    children,
    className,
    isDisabled,
    isFocusable,
    ...rest
  } = props;
  const { value, onChange, getRootProps, name, htmlProps } = useRadioGroup(rest);
  const group = useMemo(() => ({
    name,
    size,
    onChange,
    colorScheme,
    value,
    variant,
    isDisabled,
    isFocusable
  }), [
    name,
    size,
    onChange,
    colorScheme,
    value,
    variant,
    isDisabled,
    isFocusable
  ]);
  return /* @__PURE__ */ React.createElement(RadioGroupProvider, {
    value: group
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ...getRootProps(htmlProps, ref),
    className: cx("chakra-radio-group", className)
  }, children));
});
RadioGroup.displayName = "RadioGroup";

// src/use-radio.ts
import { useFormControlContext } from "@chakra-ui/form-control";

// ../visually-hidden/dist/index.esm.js
import React2 from "react";
import { chakra as chakra2 } from "@chakra-ui/system";
var visuallyHiddenStyle = {
  border: "0px",
  clip: "rect(0px, 0px, 0px, 0px)",
  height: "1px",
  width: "1px",
  margin: "-1px",
  padding: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "absolute"
};
var VisuallyHidden = chakra2("span", {
  baseStyle: visuallyHiddenStyle
});
VisuallyHidden.displayName = "VisuallyHidden";
var VisuallyHiddenInput = chakra2("input", {
  baseStyle: visuallyHiddenStyle
});
VisuallyHiddenInput.displayName = "VisuallyHiddenInput";

// src/use-radio.ts
import { trackFocusVisible } from "@zag-js/focus-visible";
import { useCallback as useCallback2, useEffect, useState as useState2, useId as useId2 } from "react";
function useRadio(props = {}) {
  const {
    defaultChecked,
    isChecked: isCheckedProp,
    isFocusable,
    isDisabled: isDisabledProp,
    isReadOnly: isReadOnlyProp,
    isRequired: isRequiredProp,
    onChange,
    isInvalid: isInvalidProp,
    name,
    value,
    id: idProp,
    "data-radiogroup": dataRadioGroup,
    "aria-describedby": ariaDescribedBy,
    ...htmlProps
  } = props;
  const uuid = `radio-${useId2()}`;
  const formControl = useFormControlContext();
  const group = useRadioGroupContext();
  const isWithinRadioGroup = !!group || !!dataRadioGroup;
  const isWithinFormControl = !!formControl;
  let id = isWithinFormControl && !isWithinRadioGroup ? formControl.id : uuid;
  id = idProp ?? id;
  const isDisabled = isDisabledProp ?? (formControl == null ? void 0 : formControl.isDisabled);
  const isReadOnly = isReadOnlyProp ?? (formControl == null ? void 0 : formControl.isReadOnly);
  const isRequired = isRequiredProp ?? (formControl == null ? void 0 : formControl.isRequired);
  const isInvalid = isInvalidProp ?? (formControl == null ? void 0 : formControl.isInvalid);
  const [isFocusVisible, setIsFocusVisible] = useState2(false);
  const [isFocused, setFocused] = useState2(false);
  const [isHovered, setHovering] = useState2(false);
  const [isActive, setActive] = useState2(false);
  const [isCheckedState, setChecked] = useState2(Boolean(defaultChecked));
  const isControlled = typeof isCheckedProp !== "undefined";
  const isChecked = isControlled ? isCheckedProp : isCheckedState;
  useEffect(() => {
    return trackFocusVisible(setIsFocusVisible);
  }, []);
  const handleChange = useCallback2((event) => {
    if (isReadOnly || isDisabled) {
      event.preventDefault();
      return;
    }
    if (!isControlled) {
      setChecked(event.target.checked);
    }
    onChange == null ? void 0 : onChange(event);
  }, [isControlled, isDisabled, isReadOnly, onChange]);
  const onKeyDown = useCallback2((event) => {
    if (event.key === " ") {
      setActive(true);
    }
  }, [setActive]);
  const onKeyUp = useCallback2((event) => {
    if (event.key === " ") {
      setActive(false);
    }
  }, [setActive]);
  const getRadioProps = useCallback2((props2 = {}, ref = null) => ({
    ...props2,
    ref,
    "data-active": dataAttr(isActive),
    "data-hover": dataAttr(isHovered),
    "data-disabled": dataAttr(isDisabled),
    "data-invalid": dataAttr(isInvalid),
    "data-checked": dataAttr(isChecked),
    "data-focus": dataAttr(isFocused),
    "data-focus-visible": dataAttr(isFocused && isFocusVisible),
    "data-readonly": dataAttr(isReadOnly),
    "aria-hidden": true,
    onMouseDown: callAllHandlers(props2.onMouseDown, () => setActive(true)),
    onMouseUp: callAllHandlers(props2.onMouseUp, () => setActive(false)),
    onMouseEnter: callAllHandlers(props2.onMouseEnter, () => setHovering(true)),
    onMouseLeave: callAllHandlers(props2.onMouseLeave, () => setHovering(false))
  }), [
    isActive,
    isHovered,
    isDisabled,
    isInvalid,
    isChecked,
    isFocused,
    isReadOnly,
    isFocusVisible
  ]);
  const { onFocus, onBlur } = formControl ?? {};
  const getInputProps = useCallback2((props2 = {}, ref = null) => {
    const trulyDisabled = isDisabled && !isFocusable;
    return {
      ...props2,
      id,
      ref,
      type: "radio",
      name,
      value,
      onChange: callAllHandlers(props2.onChange, handleChange),
      onBlur: callAllHandlers(onBlur, props2.onBlur, () => setFocused(false)),
      onFocus: callAllHandlers(onFocus, props2.onFocus, () => setFocused(true)),
      onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
      onKeyUp: callAllHandlers(props2.onKeyUp, onKeyUp),
      checked: isChecked,
      disabled: trulyDisabled,
      readOnly: isReadOnly,
      required: isRequired,
      "aria-invalid": ariaAttr(isInvalid),
      "aria-disabled": ariaAttr(trulyDisabled),
      "aria-required": ariaAttr(isRequired),
      "data-readonly": dataAttr(isReadOnly),
      "aria-describedby": ariaDescribedBy,
      style: visuallyHiddenStyle
    };
  }, [
    isDisabled,
    isFocusable,
    id,
    name,
    value,
    handleChange,
    onBlur,
    onFocus,
    onKeyDown,
    onKeyUp,
    isChecked,
    isReadOnly,
    isRequired,
    isInvalid,
    ariaDescribedBy
  ]);
  const getLabelProps = (props2 = {}, ref = null) => ({
    ...props2,
    ref,
    onMouseDown: callAllHandlers(props2.onMouseDown, stop),
    onTouchStart: callAllHandlers(props2.onTouchStart, stop),
    "data-disabled": dataAttr(isDisabled),
    "data-checked": dataAttr(isChecked),
    "data-invalid": dataAttr(isInvalid)
  });
  const getRootProps = (props2, ref = null) => ({
    ...props2,
    ref,
    "data-disabled": dataAttr(isDisabled),
    "data-checked": dataAttr(isChecked),
    "data-invalid": dataAttr(isInvalid)
  });
  const state = {
    isInvalid,
    isFocused,
    isChecked,
    isActive,
    isHovered,
    isDisabled,
    isReadOnly,
    isRequired
  };
  return {
    state,
    getCheckboxProps: getRadioProps,
    getInputProps,
    getLabelProps,
    getRootProps,
    htmlProps
  };
}
function stop(event) {
  event.preventDefault();
  event.stopPropagation();
}

// src/radio.tsx
var Radio = forwardRef2((props, ref) => {
  const group = useRadioGroupContext();
  const { onChange: onChangeProp, value: valueProp } = props;
  const styles = useMultiStyleConfig("Radio", { ...group, ...props });
  const ownProps = omitThemingProps(props);
  const {
    spacing = "0.5rem",
    children,
    isDisabled = group == null ? void 0 : group.isDisabled,
    isFocusable = group == null ? void 0 : group.isFocusable,
    inputProps: htmlInputProps,
    ...rest
  } = ownProps;
  let isChecked = props.isChecked;
  if ((group == null ? void 0 : group.value) != null && valueProp != null) {
    isChecked = group.value === valueProp;
  }
  let onChange = onChangeProp;
  if ((group == null ? void 0 : group.onChange) && valueProp != null) {
    onChange = callAll(group.onChange, onChangeProp);
  }
  const name = (props == null ? void 0 : props.name) ?? (group == null ? void 0 : group.name);
  const {
    getInputProps,
    getCheckboxProps,
    getLabelProps,
    getRootProps,
    htmlProps
  } = useRadio({
    ...rest,
    isChecked,
    isFocusable,
    isDisabled,
    onChange,
    name
  });
  const [layoutProps, otherProps] = split(htmlProps, layoutPropNames);
  const checkboxProps = getCheckboxProps(otherProps);
  const inputProps = getInputProps(htmlInputProps, ref);
  const labelProps = getLabelProps();
  const rootProps = Object.assign({}, layoutProps, getRootProps());
  const rootStyles = {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top",
    cursor: "pointer",
    position: "relative",
    ...styles.container
  };
  const checkboxStyles = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    ...styles.control
  };
  const labelStyles = {
    userSelect: "none",
    marginStart: spacing,
    ...styles.label
  };
  return /* @__PURE__ */ React.createElement(chakra3.label, {
    className: "chakra-radio",
    ...rootProps,
    __css: rootStyles
  }, /* @__PURE__ */ React.createElement("input", {
    className: "chakra-radio__input",
    ...inputProps
  }), /* @__PURE__ */ React.createElement(chakra3.span, {
    className: "chakra-radio__control",
    ...checkboxProps,
    __css: checkboxStyles
  }), children && /* @__PURE__ */ React.createElement(chakra3.span, {
    className: "chakra-radio__label",
    ...labelProps,
    __css: labelStyles
  }, children));
});
Radio.displayName = "Radio";
export {
  Radio,
  RadioGroup,
  useRadio,
  useRadioGroup,
  useRadioGroupContext
};
