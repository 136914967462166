import { flexibleChecksumsMiddleware } from "./flexibleChecksumsMiddleware";
export var flexibleChecksumsMiddlewareOptions = {
    name: "flexibleChecksumsMiddleware",
    step: "build",
    tags: ["BODY_CHECKSUM"],
    override: true,
};
export var getFlexibleChecksumsPlugin = function (config, middlewareConfig) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(flexibleChecksumsMiddleware(config, middlewareConfig), flexibleChecksumsMiddlewareOptions);
    },
}); };
