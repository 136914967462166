import { ChecksumAlgorithm } from "./constants";
export var CLIENT_SUPPORTED_ALGORITHMS = [
    ChecksumAlgorithm.CRC32,
    ChecksumAlgorithm.CRC32C,
    ChecksumAlgorithm.SHA1,
    ChecksumAlgorithm.SHA256,
];
export var PRIORITY_ORDER_ALGORITHMS = [
    ChecksumAlgorithm.CRC32,
    ChecksumAlgorithm.CRC32C,
    ChecksumAlgorithm.SHA1,
    ChecksumAlgorithm.SHA256,
];
