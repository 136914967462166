import { __awaiter, __generator, __values } from "tslib";
import { getChecksum } from "./getChecksum";
import { getChecksumAlgorithmListForResponse } from "./getChecksumAlgorithmListForResponse";
import { getChecksumLocationName } from "./getChecksumLocationName";
import { selectChecksumAlgorithmFunction } from "./selectChecksumAlgorithmFunction";
export var validateChecksumFromResponse = function (response, _a) {
    var config = _a.config, responseAlgorithms = _a.responseAlgorithms;
    return __awaiter(void 0, void 0, void 0, function () {
        var checksumAlgorithms, responseBody, responseHeaders, checksumAlgorithms_1, checksumAlgorithms_1_1, algorithm, responseHeader, checksumFromResponse, checksumAlgorithmFn, streamHasher, base64Encoder, checksum, e_1_1;
        var e_1, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    checksumAlgorithms = getChecksumAlgorithmListForResponse(responseAlgorithms);
                    responseBody = response.body, responseHeaders = response.headers;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, 7, 8]);
                    checksumAlgorithms_1 = __values(checksumAlgorithms), checksumAlgorithms_1_1 = checksumAlgorithms_1.next();
                    _c.label = 2;
                case 2:
                    if (!!checksumAlgorithms_1_1.done) return [3, 5];
                    algorithm = checksumAlgorithms_1_1.value;
                    responseHeader = getChecksumLocationName(algorithm);
                    checksumFromResponse = responseHeaders[responseHeader];
                    if (!checksumFromResponse) return [3, 4];
                    checksumAlgorithmFn = selectChecksumAlgorithmFunction(algorithm, config);
                    streamHasher = config.streamHasher, base64Encoder = config.base64Encoder;
                    return [4, getChecksum(responseBody, { streamHasher: streamHasher, checksumAlgorithmFn: checksumAlgorithmFn, base64Encoder: base64Encoder })];
                case 3:
                    checksum = _c.sent();
                    if (checksum === checksumFromResponse) {
                        return [3, 5];
                    }
                    throw new Error("Checksum mismatch: expected \"".concat(checksum, "\" but received \"").concat(checksumFromResponse, "\"") +
                        " in response header \"".concat(responseHeader, "\"."));
                case 4:
                    checksumAlgorithms_1_1 = checksumAlgorithms_1.next();
                    return [3, 2];
                case 5: return [3, 8];
                case 6:
                    e_1_1 = _c.sent();
                    e_1 = { error: e_1_1 };
                    return [3, 8];
                case 7:
                    try {
                        if (checksumAlgorithms_1_1 && !checksumAlgorithms_1_1.done && (_b = checksumAlgorithms_1.return)) _b.call(checksumAlgorithms_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7];
                case 8: return [2];
            }
        });
    });
};
