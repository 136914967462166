import { __values } from "tslib";
import { CLIENT_SUPPORTED_ALGORITHMS, PRIORITY_ORDER_ALGORITHMS } from "./types";
export var getChecksumAlgorithmListForResponse = function (responseAlgorithms) {
    var e_1, _a;
    if (responseAlgorithms === void 0) { responseAlgorithms = []; }
    var validChecksumAlgorithms = [];
    try {
        for (var PRIORITY_ORDER_ALGORITHMS_1 = __values(PRIORITY_ORDER_ALGORITHMS), PRIORITY_ORDER_ALGORITHMS_1_1 = PRIORITY_ORDER_ALGORITHMS_1.next(); !PRIORITY_ORDER_ALGORITHMS_1_1.done; PRIORITY_ORDER_ALGORITHMS_1_1 = PRIORITY_ORDER_ALGORITHMS_1.next()) {
            var algorithm = PRIORITY_ORDER_ALGORITHMS_1_1.value;
            if (!responseAlgorithms.includes(algorithm) || !CLIENT_SUPPORTED_ALGORITHMS.includes(algorithm)) {
                continue;
            }
            validChecksumAlgorithms.push(algorithm);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (PRIORITY_ORDER_ALGORITHMS_1_1 && !PRIORITY_ORDER_ALGORITHMS_1_1.done && (_a = PRIORITY_ORDER_ALGORITHMS_1.return)) _a.call(PRIORITY_ORDER_ALGORITHMS_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return validChecksumAlgorithms;
};
