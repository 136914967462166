// ../../react-shim.js
import React from "react";

// src/focus-lock.tsx
import ReactFocusLock from "react-focus-lock";
import { getAllFocusable } from "@chakra-ui/dom-utils";
import { useCallback } from "react";
var FocusLock = (props) => {
  const {
    initialFocusRef,
    finalFocusRef,
    contentRef,
    restoreFocus,
    children,
    isDisabled,
    autoFocus,
    persistentFocus,
    lockFocusAcrossFrames
  } = props;
  const onActivation = useCallback(() => {
    if (initialFocusRef == null ? void 0 : initialFocusRef.current) {
      initialFocusRef.current.focus();
    } else if (contentRef == null ? void 0 : contentRef.current) {
      const focusables = getAllFocusable(contentRef.current);
      if (focusables.length === 0) {
        requestAnimationFrame(() => {
          var _a;
          (_a = contentRef.current) == null ? void 0 : _a.focus();
        });
      }
    }
  }, [initialFocusRef, contentRef]);
  const onDeactivation = useCallback(() => {
    var _a;
    (_a = finalFocusRef == null ? void 0 : finalFocusRef.current) == null ? void 0 : _a.focus();
  }, [finalFocusRef]);
  const returnFocus = restoreFocus && !finalFocusRef;
  return /* @__PURE__ */ React.createElement(ReactFocusLock, {
    crossFrame: lockFocusAcrossFrames,
    persistentFocus,
    autoFocus,
    disabled: isDisabled,
    onActivation,
    onDeactivation,
    returnFocus
  }, children);
};
FocusLock.displayName = "FocusLock";
export {
  FocusLock
};
