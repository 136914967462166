import { __extends } from "tslib";
import { ServiceException as __ServiceException, } from "@aws-sdk/smithy-client";
var S3ServiceException = (function (_super) {
    __extends(S3ServiceException, _super);
    function S3ServiceException(options) {
        var _this = _super.call(this, options) || this;
        Object.setPrototypeOf(_this, S3ServiceException.prototype);
        return _this;
    }
    return S3ServiceException;
}(__ServiceException));
export { S3ServiceException };
