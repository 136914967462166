// ../../react-shim.js
import React from "react";

// src/aspect-ratio.tsx
import {
  chakra,
  forwardRef
} from "@chakra-ui/system";
import { mapResponsive } from "@chakra-ui/breakpoint-utils";
import { cx } from "@chakra-ui/shared-utils";
import { Children } from "react";
var AspectRatio = forwardRef(function(props, ref) {
  const { ratio = 4 / 3, children, className, ...rest } = props;
  const child = Children.only(children);
  const _className = cx("chakra-aspect-ratio", className);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    position: "relative",
    className: _className,
    _before: {
      height: 0,
      content: `""`,
      display: "block",
      paddingBottom: mapResponsive(ratio, (r) => `${1 / r * 100}%`)
    },
    __css: {
      "& > *:not(style)": {
        overflow: "hidden",
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
      },
      "& > img, & > video": {
        objectFit: "cover"
      }
    },
    ...rest
  }, child);
});
AspectRatio.displayName = "AspectRatio";

// src/badge.tsx
import {
  chakra as chakra2,
  forwardRef as forwardRef2,
  omitThemingProps,
  useStyleConfig
} from "@chakra-ui/system";
import { cx as cx2 } from "@chakra-ui/shared-utils";
var Badge = forwardRef2(function Badge2(props, ref) {
  const styles = useStyleConfig("Badge", props);
  const { className, ...rest } = omitThemingProps(props);
  return /* @__PURE__ */ React.createElement(chakra2.span, {
    ref,
    className: cx2("chakra-badge", props.className),
    ...rest,
    __css: {
      display: "inline-block",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      ...styles
    }
  });
});
Badge.displayName = "Badge";

// src/box.tsx
import {
  chakra as chakra3,
  forwardRef as forwardRef3
} from "@chakra-ui/system";
var Box = chakra3("div");
Box.displayName = "Box";
var Square = forwardRef3(function Square2(props, ref) {
  const { size, centerContent = true, ...rest } = props;
  const styles = centerContent ? { display: "flex", alignItems: "center", justifyContent: "center" } : {};
  return /* @__PURE__ */ React.createElement(Box, {
    ref,
    boxSize: size,
    __css: {
      ...styles,
      flexShrink: 0,
      flexGrow: 0
    },
    ...rest
  });
});
Square.displayName = "Square";
var Circle = forwardRef3(function Circle2(props, ref) {
  const { size, ...rest } = props;
  return /* @__PURE__ */ React.createElement(Square, {
    size,
    ref,
    borderRadius: "9999px",
    ...rest
  });
});
Circle.displayName = "Circle";

// src/center.tsx
import { chakra as chakra4, forwardRef as forwardRef4 } from "@chakra-ui/system";
var Center = chakra4("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});
Center.displayName = "Center";
var centerStyles = {
  horizontal: {
    insetStart: "50%",
    transform: "translateX(-50%)"
  },
  vertical: {
    top: "50%",
    transform: "translateY(-50%)"
  },
  both: {
    insetStart: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};
var AbsoluteCenter = forwardRef4(function AbsoluteCenter2(props, ref) {
  const { axis = "both", ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra4.div, {
    ref,
    __css: centerStyles[axis],
    ...rest,
    position: "absolute"
  });
});

// src/code.tsx
import {
  chakra as chakra5,
  forwardRef as forwardRef5,
  omitThemingProps as omitThemingProps2,
  useStyleConfig as useStyleConfig2
} from "@chakra-ui/system";
import { cx as cx3 } from "@chakra-ui/shared-utils";
var Code = forwardRef5(function Code2(props, ref) {
  const styles = useStyleConfig2("Code", props);
  const { className, ...rest } = omitThemingProps2(props);
  return /* @__PURE__ */ React.createElement(chakra5.code, {
    ref,
    className: cx3("chakra-code", props.className),
    ...rest,
    __css: {
      display: "inline-block",
      ...styles
    }
  });
});
Code.displayName = "Code";

// src/container.tsx
import {
  chakra as chakra6,
  forwardRef as forwardRef6,
  omitThemingProps as omitThemingProps3,
  useStyleConfig as useStyleConfig3
} from "@chakra-ui/system";
import { cx as cx4 } from "@chakra-ui/shared-utils";
var Container = forwardRef6(function Container2(props, ref) {
  const { className, centerContent, ...rest } = omitThemingProps3(props);
  const styles = useStyleConfig3("Container", props);
  return /* @__PURE__ */ React.createElement(chakra6.div, {
    ref,
    className: cx4("chakra-container", className),
    ...rest,
    __css: {
      ...styles,
      ...centerContent && {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }
    }
  });
});
Container.displayName = "Container";

// src/divider.tsx
import {
  chakra as chakra7,
  forwardRef as forwardRef7,
  omitThemingProps as omitThemingProps4,
  useStyleConfig as useStyleConfig4
} from "@chakra-ui/system";
import { cx as cx5 } from "@chakra-ui/shared-utils";
var Divider = forwardRef7(function Divider2(props, ref) {
  const {
    borderLeftWidth,
    borderBottomWidth,
    borderTopWidth,
    borderRightWidth,
    borderWidth,
    borderStyle,
    borderColor,
    ...styles
  } = useStyleConfig4("Divider", props);
  const {
    className,
    orientation = "horizontal",
    __css,
    ...rest
  } = omitThemingProps4(props);
  const dividerStyles = {
    vertical: {
      borderLeftWidth: borderLeftWidth || borderRightWidth || borderWidth || "1px",
      height: "100%"
    },
    horizontal: {
      borderBottomWidth: borderBottomWidth || borderTopWidth || borderWidth || "1px",
      width: "100%"
    }
  };
  return /* @__PURE__ */ React.createElement(chakra7.hr, {
    ref,
    "aria-orientation": orientation,
    ...rest,
    __css: {
      ...styles,
      border: "0",
      borderColor,
      borderStyle,
      ...dividerStyles[orientation],
      ...__css
    },
    className: cx5("chakra-divider", className)
  });
});
Divider.displayName = "Divider";

// src/flex.tsx
import {
  chakra as chakra8,
  forwardRef as forwardRef8
} from "@chakra-ui/system";
var Flex = forwardRef8(function Flex2(props, ref) {
  const { direction, align, justify, wrap, basis, grow, shrink, ...rest } = props;
  const styles = {
    display: "flex",
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink
  };
  return /* @__PURE__ */ React.createElement(chakra8.div, {
    ref,
    __css: styles,
    ...rest
  });
});
Flex.displayName = "Flex";

// src/grid.tsx
import {
  chakra as chakra9,
  forwardRef as forwardRef9
} from "@chakra-ui/system";
var Grid = forwardRef9(function Grid2(props, ref) {
  const {
    templateAreas,
    gap,
    rowGap,
    columnGap,
    column,
    row,
    autoFlow,
    autoRows,
    templateRows,
    autoColumns,
    templateColumns,
    ...rest
  } = props;
  const styles = {
    display: "grid",
    gridTemplateAreas: templateAreas,
    gridGap: gap,
    gridRowGap: rowGap,
    gridColumnGap: columnGap,
    gridAutoColumns: autoColumns,
    gridColumn: column,
    gridRow: row,
    gridAutoFlow: autoFlow,
    gridAutoRows: autoRows,
    gridTemplateRows: templateRows,
    gridTemplateColumns: templateColumns
  };
  return /* @__PURE__ */ React.createElement(chakra9.div, {
    ref,
    __css: styles,
    ...rest
  });
});
Grid.displayName = "Grid";

// src/grid-item.tsx
import {
  forwardRef as forwardRef10,
  chakra as chakra10
} from "@chakra-ui/system";
import { compact } from "@chakra-ui/object-utils";
import { mapResponsive as mapResponsive2 } from "@chakra-ui/breakpoint-utils";
function spanFn(span) {
  return mapResponsive2(span, (value) => value === "auto" ? "auto" : `span ${value}/span ${value}`);
}
var GridItem = forwardRef10(function GridItem2(props, ref) {
  const {
    area,
    colSpan,
    colStart,
    colEnd,
    rowEnd,
    rowSpan,
    rowStart,
    ...rest
  } = props;
  const styles = compact({
    gridArea: area,
    gridColumn: spanFn(colSpan),
    gridRow: spanFn(rowSpan),
    gridColumnStart: colStart,
    gridColumnEnd: colEnd,
    gridRowStart: rowStart,
    gridRowEnd: rowEnd
  });
  return /* @__PURE__ */ React.createElement(chakra10.div, {
    ref,
    __css: styles,
    ...rest
  });
});
GridItem.displayName = "GridItem";

// src/heading.tsx
import {
  chakra as chakra11,
  forwardRef as forwardRef11,
  omitThemingProps as omitThemingProps5,
  useStyleConfig as useStyleConfig5
} from "@chakra-ui/system";
import { cx as cx6 } from "@chakra-ui/shared-utils";
var Heading = forwardRef11(function Heading2(props, ref) {
  const styles = useStyleConfig5("Heading", props);
  const { className, ...rest } = omitThemingProps5(props);
  return /* @__PURE__ */ React.createElement(chakra11.h2, {
    ref,
    className: cx6("chakra-heading", props.className),
    ...rest,
    __css: styles
  });
});
Heading.displayName = "Heading";

// src/highlight.tsx
import {
  forwardRef as forwardRef12,
  omitThemingProps as omitThemingProps6,
  useStyleConfig as useStyleConfig6
} from "@chakra-ui/system";
import { Fragment, useMemo } from "react";
var escapeRegexp = (term) => term.replace(/[|\\{}()[\]^$+*?.-]/g, (char) => `\\${char}`);
function buildRegex(query) {
  const _query = query.filter((text) => text.length !== 0).map((text) => escapeRegexp(text.trim()));
  if (!_query.length) {
    return null;
  }
  return new RegExp(`(${_query.join("|")})`, "ig");
}
function highlightWords({ text, query }) {
  const regex = buildRegex(Array.isArray(query) ? query : [query]);
  if (!regex) {
    return [];
  }
  const result = text.split(regex).filter(Boolean);
  return result.map((str) => ({ text: str, match: regex.test(str) }));
}
function useHighlight(props) {
  const { text, query } = props;
  return useMemo(() => highlightWords({ text, query }), [text, query]);
}
var Mark = forwardRef12(function Mark2(props, ref) {
  const styles = useStyleConfig6("Mark", props);
  const ownProps = omitThemingProps6(props);
  return /* @__PURE__ */ React.createElement(Box, {
    ref,
    ...ownProps,
    as: "mark",
    __css: { bg: "transparent", whiteSpace: "nowrap", ...styles }
  });
});
function Highlight(props) {
  const { children, query, styles } = props;
  if (typeof children !== "string") {
    throw new Error("The children prop of Highlight must be a string");
  }
  const chunks = useHighlight({ query, text: children });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, chunks.map((chunk, index) => {
    return chunk.match ? /* @__PURE__ */ React.createElement(Mark, {
      key: index,
      sx: styles
    }, chunk.text) : /* @__PURE__ */ React.createElement(Fragment, {
      key: index
    }, chunk.text);
  }));
}

// src/kbd.tsx
import {
  chakra as chakra12,
  forwardRef as forwardRef13,
  omitThemingProps as omitThemingProps7,
  useStyleConfig as useStyleConfig7
} from "@chakra-ui/system";
import { cx as cx7 } from "@chakra-ui/shared-utils";
var Kbd = forwardRef13(function Kbd2(props, ref) {
  const styles = useStyleConfig7("Kbd", props);
  const { className, ...rest } = omitThemingProps7(props);
  return /* @__PURE__ */ React.createElement(chakra12.kbd, {
    ref,
    className: cx7("chakra-kbd", className),
    ...rest,
    __css: {
      fontFamily: "mono",
      ...styles
    }
  });
});
Kbd.displayName = "Kbd";

// src/link.tsx
import {
  chakra as chakra13,
  forwardRef as forwardRef14,
  omitThemingProps as omitThemingProps8,
  useStyleConfig as useStyleConfig8
} from "@chakra-ui/system";
import { cx as cx8 } from "@chakra-ui/shared-utils";
var Link = forwardRef14(function Link2(props, ref) {
  const styles = useStyleConfig8("Link", props);
  const { className, isExternal, ...rest } = omitThemingProps8(props);
  return /* @__PURE__ */ React.createElement(chakra13.a, {
    target: isExternal ? "_blank" : void 0,
    rel: isExternal ? "noopener" : void 0,
    ref,
    className: cx8("chakra-link", className),
    ...rest,
    __css: styles
  });
});
Link.displayName = "Link";

// src/link-box.tsx
import { chakra as chakra14, forwardRef as forwardRef15 } from "@chakra-ui/system";
import { cx as cx9 } from "@chakra-ui/shared-utils";
var LinkOverlay = forwardRef15(function LinkOverlay2(props, ref) {
  const { isExternal, target, rel, className, ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra14.a, {
    ...rest,
    ref,
    className: cx9("chakra-linkbox__overlay", className),
    rel: isExternal ? "noopener noreferrer" : rel,
    target: isExternal ? "_blank" : target,
    __css: {
      position: "static",
      "&::before": {
        content: "''",
        cursor: "inherit",
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 0,
        width: "100%",
        height: "100%"
      }
    }
  });
});
var LinkBox = forwardRef15(function LinkBox2(props, ref) {
  const { className, ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra14.div, {
    ref,
    position: "relative",
    ...rest,
    className: cx9("chakra-linkbox", className),
    __css: {
      "a[href]:not(.chakra-linkbox__overlay), abbr[title]": {
        position: "relative",
        zIndex: 1
      }
    }
  });
});

// src/list.tsx
import { Icon } from "@chakra-ui/icon";
import { createContext } from "@chakra-ui/react-context";
import { getValidChildren } from "@chakra-ui/react-children-utils";
import {
  chakra as chakra15,
  forwardRef as forwardRef16,
  omitThemingProps as omitThemingProps9,
  useMultiStyleConfig
} from "@chakra-ui/system";
var [ListStylesProvider, useListStyles] = createContext({
  name: `ListStylesContext`,
  errorMessage: `useListStyles returned is 'undefined'. Seems you forgot to wrap the components in "<List />" `
});
var List = forwardRef16(function List2(props, ref) {
  const styles = useMultiStyleConfig("List", props);
  const {
    children,
    styleType = "none",
    stylePosition,
    spacing,
    ...rest
  } = omitThemingProps9(props);
  const validChildren = getValidChildren(children);
  const selector2 = "& > *:not(style) ~ *:not(style)";
  const spacingStyle = spacing ? { [selector2]: { mt: spacing } } : {};
  return /* @__PURE__ */ React.createElement(ListStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra15.ul, {
    ref,
    listStyleType: styleType,
    listStylePosition: stylePosition,
    role: "list",
    __css: { ...styles.container, ...spacingStyle },
    ...rest
  }, validChildren));
});
List.displayName = "List";
var OrderedList = forwardRef16((props, ref) => {
  const { as, ...rest } = props;
  return /* @__PURE__ */ React.createElement(List, {
    ref,
    as: "ol",
    styleType: "decimal",
    marginStart: "1em",
    ...rest
  });
});
OrderedList.displayName = "OrderedList";
var UnorderedList = forwardRef16(function UnorderedList2(props, ref) {
  const { as, ...rest } = props;
  return /* @__PURE__ */ React.createElement(List, {
    ref,
    as: "ul",
    styleType: "initial",
    marginStart: "1em",
    ...rest
  });
});
UnorderedList.displayName = "UnorderedList";
var ListItem = forwardRef16(function ListItem2(props, ref) {
  const styles = useListStyles();
  return /* @__PURE__ */ React.createElement(chakra15.li, {
    ref,
    ...props,
    __css: styles.item
  });
});
ListItem.displayName = "ListItem";
var ListIcon = forwardRef16(function ListIcon2(props, ref) {
  const styles = useListStyles();
  return /* @__PURE__ */ React.createElement(Icon, {
    ref,
    role: "presentation",
    ...props,
    __css: styles.icon
  });
});
ListIcon.displayName = "ListIcon";

// src/simple-grid.tsx
import {
  forwardRef as forwardRef17,
  getToken,
  useTheme
} from "@chakra-ui/system";
import { mapResponsive as mapResponsive3 } from "@chakra-ui/breakpoint-utils";
var SimpleGrid = forwardRef17(function SimpleGrid2(props, ref) {
  const { columns, spacingX, spacingY, spacing, minChildWidth, ...rest } = props;
  const theme = useTheme();
  const templateColumns = minChildWidth ? widthToColumns(minChildWidth, theme) : countToColumns(columns);
  return /* @__PURE__ */ React.createElement(Grid, {
    ref,
    gap: spacing,
    columnGap: spacingX,
    rowGap: spacingY,
    templateColumns,
    ...rest
  });
});
SimpleGrid.displayName = "SimpleGrid";
function toPx(n) {
  return typeof n === "number" ? `${n}px` : n;
}
function widthToColumns(width, theme) {
  return mapResponsive3(width, (value) => {
    const _value = getToken("sizes", value, toPx(value))(theme);
    return value === null ? null : `repeat(auto-fit, minmax(${_value}, 1fr))`;
  });
}
function countToColumns(count) {
  return mapResponsive3(count, (value) => value === null ? null : `repeat(${value}, minmax(0, 1fr))`);
}

// src/spacer.tsx
import { chakra as chakra16 } from "@chakra-ui/system";
var Spacer = chakra16("div", {
  baseStyle: {
    flex: 1,
    justifySelf: "stretch",
    alignSelf: "stretch"
  }
});
Spacer.displayName = "Spacer";

// src/stack/h-stack.tsx
import { forwardRef as forwardRef19 } from "@chakra-ui/system";

// src/stack/stack.tsx
import {
  chakra as chakra18,
  forwardRef as forwardRef18
} from "@chakra-ui/system";
import { cx as cx10 } from "@chakra-ui/shared-utils";
import { getValidChildren as getValidChildren2 } from "@chakra-ui/react-children-utils";
import { cloneElement, Fragment as Fragment2, useMemo as useMemo2 } from "react";

// src/stack/stack.utils.tsx
import { mapResponsive as mapResponsive4 } from "@chakra-ui/breakpoint-utils";
var selector = "& > *:not(style) ~ *:not(style)";
function getStackStyles(options) {
  const { spacing, direction } = options;
  const directionStyles = {
    column: {
      marginTop: spacing,
      marginEnd: 0,
      marginBottom: 0,
      marginStart: 0
    },
    row: { marginTop: 0, marginEnd: 0, marginBottom: 0, marginStart: spacing },
    "column-reverse": {
      marginTop: 0,
      marginEnd: 0,
      marginBottom: spacing,
      marginStart: 0
    },
    "row-reverse": {
      marginTop: 0,
      marginEnd: spacing,
      marginBottom: 0,
      marginStart: 0
    }
  };
  return {
    flexDirection: direction,
    [selector]: mapResponsive4(direction, (value) => directionStyles[value])
  };
}
function getDividerStyles(options) {
  const { spacing, direction } = options;
  const dividerStyles = {
    column: {
      my: spacing,
      mx: 0,
      borderLeftWidth: 0,
      borderBottomWidth: "1px"
    },
    "column-reverse": {
      my: spacing,
      mx: 0,
      borderLeftWidth: 0,
      borderBottomWidth: "1px"
    },
    row: {
      mx: spacing,
      my: 0,
      borderLeftWidth: "1px",
      borderBottomWidth: 0
    },
    "row-reverse": {
      mx: spacing,
      my: 0,
      borderLeftWidth: "1px",
      borderBottomWidth: 0
    }
  };
  return {
    "&": mapResponsive4(direction, (value) => dividerStyles[value])
  };
}

// src/stack/stack-item.tsx
import { chakra as chakra17 } from "@chakra-ui/system";
var StackItem = (props) => /* @__PURE__ */ React.createElement(chakra17.div, {
  className: "chakra-stack__item",
  ...props,
  __css: {
    display: "inline-block",
    flex: "0 0 auto",
    minWidth: 0,
    ...props["__css"]
  }
});
StackItem.displayName = "StackItem";

// src/stack/stack.tsx
var Stack = forwardRef18((props, ref) => {
  const {
    isInline,
    direction: directionProp,
    align,
    justify,
    spacing = "0.5rem",
    wrap,
    children,
    divider,
    className,
    shouldWrapChildren,
    ...rest
  } = props;
  const direction = isInline ? "row" : directionProp ?? "column";
  const styles = useMemo2(() => getStackStyles({ direction, spacing }), [direction, spacing]);
  const dividerStyle = useMemo2(() => getDividerStyles({ spacing, direction }), [spacing, direction]);
  const hasDivider = !!divider;
  const shouldUseChildren = !shouldWrapChildren && !hasDivider;
  const validChildren = getValidChildren2(children);
  const clones = shouldUseChildren ? validChildren : validChildren.map((child, index) => {
    const key = typeof child.key !== "undefined" ? child.key : index;
    const isLast = index + 1 === validChildren.length;
    const wrappedChild = /* @__PURE__ */ React.createElement(StackItem, {
      key
    }, child);
    const _child = shouldWrapChildren ? wrappedChild : child;
    if (!hasDivider)
      return _child;
    const clonedDivider = cloneElement(divider, {
      __css: dividerStyle
    });
    const _divider = isLast ? null : clonedDivider;
    return /* @__PURE__ */ React.createElement(Fragment2, {
      key
    }, _child, _divider);
  });
  const _className = cx10("chakra-stack", className);
  return /* @__PURE__ */ React.createElement(chakra18.div, {
    ref,
    display: "flex",
    alignItems: align,
    justifyContent: justify,
    flexDirection: styles.flexDirection,
    flexWrap: wrap,
    className: _className,
    __css: hasDivider ? {} : { [selector]: styles[selector] },
    ...rest
  }, clones);
});
Stack.displayName = "Stack";

// src/stack/h-stack.tsx
var HStack = forwardRef19((props, ref) => /* @__PURE__ */ React.createElement(Stack, {
  align: "center",
  ...props,
  direction: "row",
  ref
}));
HStack.displayName = "HStack";

// src/stack/stack-divider.tsx
import { chakra as chakra19 } from "@chakra-ui/system";
var StackDivider = (props) => /* @__PURE__ */ React.createElement(chakra19.div, {
  className: "chakra-stack__divider",
  ...props,
  __css: {
    ...props["__css"],
    borderWidth: 0,
    alignSelf: "stretch",
    borderColor: "inherit",
    width: "auto",
    height: "auto"
  }
});
StackDivider.displayName = "StackDivider";

// src/stack/v-stack.tsx
import { forwardRef as forwardRef20 } from "@chakra-ui/system";
var VStack = forwardRef20((props, ref) => /* @__PURE__ */ React.createElement(Stack, {
  align: "center",
  ...props,
  direction: "column",
  ref
}));
VStack.displayName = "VStack";

// src/text.tsx
import {
  chakra as chakra20,
  forwardRef as forwardRef21,
  omitThemingProps as omitThemingProps10,
  useStyleConfig as useStyleConfig9
} from "@chakra-ui/system";
import { cx as cx11 } from "@chakra-ui/shared-utils";
import { compact as compact2 } from "@chakra-ui/object-utils";
var Text = forwardRef21(function Text2(props, ref) {
  const styles = useStyleConfig9("Text", props);
  const { className, align, decoration, casing, ...rest } = omitThemingProps10(props);
  const aliasedProps = compact2({
    textAlign: props.align,
    textDecoration: props.decoration,
    textTransform: props.casing
  });
  return /* @__PURE__ */ React.createElement(chakra20.p, {
    ref,
    className: cx11("chakra-text", props.className),
    ...aliasedProps,
    ...rest,
    __css: styles
  });
});
Text.displayName = "Text";

// src/wrap.tsx
import {
  chakra as chakra21,
  forwardRef as forwardRef22,
  tokenToCSSVar
} from "@chakra-ui/system";
import { cx as cx12 } from "@chakra-ui/shared-utils";
import { mapResponsive as mapResponsive5 } from "@chakra-ui/breakpoint-utils";
import { Children as Children2, useMemo as useMemo3 } from "react";
function px(value) {
  return typeof value === "number" ? `${value}px` : value;
}
var Wrap = forwardRef22(function Wrap2(props, ref) {
  const {
    spacing = "0.5rem",
    spacingX,
    spacingY,
    children,
    justify,
    direction,
    align,
    className,
    shouldWrapChildren,
    ...rest
  } = props;
  const styles = useMemo3(() => {
    const { spacingX: x = spacing, spacingY: y = spacing } = {
      spacingX,
      spacingY
    };
    return {
      "--chakra-wrap-x-spacing": (theme) => mapResponsive5(x, (value) => px(tokenToCSSVar("space", value)(theme))),
      "--chakra-wrap-y-spacing": (theme) => mapResponsive5(y, (value) => px(tokenToCSSVar("space", value)(theme))),
      "--wrap-x-spacing": "calc(var(--chakra-wrap-x-spacing) / 2)",
      "--wrap-y-spacing": "calc(var(--chakra-wrap-y-spacing) / 2)",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: justify,
      alignItems: align,
      flexDirection: direction,
      listStyleType: "none",
      padding: "0",
      margin: "calc(var(--wrap-y-spacing) * -1) calc(var(--wrap-x-spacing) * -1)",
      "& > *:not(style)": {
        margin: "var(--wrap-y-spacing) var(--wrap-x-spacing)"
      }
    };
  }, [spacing, spacingX, spacingY, justify, align, direction]);
  const childrenToRender = shouldWrapChildren ? Children2.map(children, (child, index) => /* @__PURE__ */ React.createElement(WrapItem, {
    key: index
  }, child)) : children;
  return /* @__PURE__ */ React.createElement(chakra21.div, {
    ref,
    className: cx12("chakra-wrap", className),
    overflow: "hidden",
    ...rest
  }, /* @__PURE__ */ React.createElement(chakra21.ul, {
    className: "chakra-wrap__list",
    __css: styles
  }, childrenToRender));
});
Wrap.displayName = "Wrap";
var WrapItem = forwardRef22(function WrapItem2(props, ref) {
  const { className, ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra21.li, {
    ref,
    __css: { display: "flex", alignItems: "flex-start" },
    className: cx12("chakra-wrap__listitem", className),
    ...rest
  });
});
WrapItem.displayName = "WrapItem";
export {
  AbsoluteCenter,
  AspectRatio,
  Badge,
  Box,
  Center,
  Circle,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Highlight,
  Kbd,
  Link,
  LinkBox,
  LinkOverlay,
  List,
  ListIcon,
  ListItem,
  Mark,
  OrderedList,
  SimpleGrid,
  Spacer,
  Square,
  Stack,
  StackDivider,
  StackItem,
  Text,
  UnorderedList,
  VStack,
  Wrap,
  WrapItem,
  getDividerStyles,
  getStackStyles,
  selector,
  useHighlight,
  useListStyles
};
