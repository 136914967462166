// ../../../react-shim.js
import React from "react";

// src/use-toast.tsx
import { useChakra } from "@chakra-ui/system";

// src/toast.tsx
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle
} from "@chakra-ui/alert";
import { chakra } from "@chakra-ui/system";
import { CloseButton } from "@chakra-ui/close-button";

// ../../utilities/shared-utils/dist/index.esm.js
function runIfFn(valueOrFn, ...args) {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}
var isFunction = (value) => typeof value === "function";

// src/toast.placement.ts
function getToastPlacement(position, dir) {
  const computedPosition = position ?? "bottom";
  const logicals = {
    "top-start": { ltr: "top-left", rtl: "top-right" },
    "top-end": { ltr: "top-right", rtl: "top-left" },
    "bottom-start": { ltr: "bottom-left", rtl: "bottom-right" },
    "bottom-end": { ltr: "bottom-right", rtl: "bottom-left" }
  };
  const logical = logicals[computedPosition];
  return (logical == null ? void 0 : logical[dir]) ?? computedPosition;
}

// src/toast.utils.ts
var findById = (arr, id) => arr.find((toast) => toast.id === id);
function findToast(toasts, id) {
  const position = getToastPosition(toasts, id);
  const index = position ? toasts[position].findIndex((toast) => toast.id === id) : -1;
  return {
    position,
    index
  };
}
function getToastPosition(toasts, id) {
  for (const [position, values] of Object.entries(toasts)) {
    if (findById(values, id)) {
      return position;
    }
  }
}
function getToastStyle(position) {
  const isRighty = position.includes("right");
  const isLefty = position.includes("left");
  let alignItems = "center";
  if (isRighty)
    alignItems = "flex-end";
  if (isLefty)
    alignItems = "flex-start";
  return {
    display: "flex",
    flexDirection: "column",
    alignItems
  };
}
function getToastListStyle(position) {
  const isTopOrBottom = position === "top" || position === "bottom";
  const margin = isTopOrBottom ? "0 auto" : void 0;
  const top = position.includes("top") ? "env(safe-area-inset-top, 0px)" : void 0;
  const bottom = position.includes("bottom") ? "env(safe-area-inset-bottom, 0px)" : void 0;
  const right = !position.includes("left") ? "env(safe-area-inset-right, 0px)" : void 0;
  const left = !position.includes("right") ? "env(safe-area-inset-left, 0px)" : void 0;
  return {
    position: "fixed",
    zIndex: 5500,
    pointerEvents: "none",
    display: "flex",
    flexDirection: "column",
    margin,
    top,
    bottom,
    right,
    left
  };
}

// src/toast.store.ts
var initialState = {
  top: [],
  "top-left": [],
  "top-right": [],
  "bottom-left": [],
  bottom: [],
  "bottom-right": []
};
var toastStore = createStore(initialState);
function createStore(initialState2) {
  let state = initialState2;
  const listeners = /* @__PURE__ */ new Set();
  const setState = (setStateFn) => {
    state = setStateFn(state);
    listeners.forEach((l) => l());
  };
  return {
    getState: () => state,
    subscribe: (listener) => {
      listeners.add(listener);
      return () => {
        setState(() => initialState2);
        listeners.delete(listener);
      };
    },
    removeToast: (id, position) => {
      setState((prevState) => ({
        ...prevState,
        [position]: prevState[position].filter((toast) => toast.id != id)
      }));
    },
    notify: (message, options) => {
      const toast = createToast(message, options);
      const { position, id } = toast;
      setState((prevToasts) => {
        const isTop = position.includes("top");
        const toasts = isTop ? [toast, ...prevToasts[position] ?? []] : [...prevToasts[position] ?? [], toast];
        return {
          ...prevToasts,
          [position]: toasts
        };
      });
      return id;
    },
    update: (id, options) => {
      if (!id)
        return;
      setState((prevState) => {
        const nextState = { ...prevState };
        const { position, index } = findToast(nextState, id);
        if (position && index !== -1) {
          nextState[position][index] = {
            ...nextState[position][index],
            ...options,
            message: createRenderToast(options)
          };
        }
        return nextState;
      });
    },
    closeAll: ({ positions } = {}) => {
      setState((prev) => {
        const allPositions = [
          "bottom",
          "bottom-right",
          "bottom-left",
          "top",
          "top-left",
          "top-right"
        ];
        const positionsToClose = positions ?? allPositions;
        return positionsToClose.reduce((acc, position) => {
          acc[position] = prev[position].map((toast) => ({
            ...toast,
            requestClose: true
          }));
          return acc;
        }, { ...prev });
      });
    },
    close: (id) => {
      setState((prevState) => {
        const position = getToastPosition(prevState, id);
        if (!position)
          return prevState;
        return {
          ...prevState,
          [position]: prevState[position].map((toast) => {
            if (toast.id == id) {
              return {
                ...toast,
                requestClose: true
              };
            }
            return toast;
          })
        };
      });
    },
    isActive: (id) => Boolean(findToast(toastStore.getState(), id).position)
  };
}
var counter = 0;
function createToast(message, options = {}) {
  counter += 1;
  const id = options.id ?? counter;
  const position = options.position ?? "bottom";
  return {
    id,
    message,
    position,
    duration: options.duration,
    onCloseComplete: options.onCloseComplete,
    onRequestRemove: () => toastStore.removeToast(String(id), position),
    status: options.status,
    requestClose: false,
    containerStyle: options.containerStyle
  };
}

// src/toast.tsx
var Toast = (props) => {
  const {
    status,
    variant = "solid",
    id,
    title,
    isClosable,
    onClose,
    description,
    icon
  } = props;
  const ids = id ? {
    root: `toast-${id}`,
    title: `toast-${id}-title`,
    description: `toast-${id}-description`
  } : void 0;
  return /* @__PURE__ */ React.createElement(Alert, {
    addRole: false,
    status,
    variant,
    id: ids == null ? void 0 : ids.root,
    alignItems: "start",
    borderRadius: "md",
    boxShadow: "lg",
    paddingEnd: 8,
    textAlign: "start",
    width: "auto"
  }, /* @__PURE__ */ React.createElement(AlertIcon, null, icon), /* @__PURE__ */ React.createElement(chakra.div, {
    flex: "1",
    maxWidth: "100%"
  }, title && /* @__PURE__ */ React.createElement(AlertTitle, {
    id: ids == null ? void 0 : ids.title
  }, title), description && /* @__PURE__ */ React.createElement(AlertDescription, {
    id: ids == null ? void 0 : ids.description,
    display: "block"
  }, description)), isClosable && /* @__PURE__ */ React.createElement(CloseButton, {
    size: "sm",
    onClick: onClose,
    position: "absolute",
    insetEnd: 1,
    top: 1
  }));
};
function createRenderToast(options = {}) {
  const { render, toastComponent: ToastComponent2 = Toast } = options;
  const renderToast = (props) => {
    if (typeof render === "function") {
      return render(props);
    }
    return /* @__PURE__ */ React.createElement(ToastComponent2, {
      ...props,
      ...options
    });
  };
  return renderToast;
}
function createToastFn(dir, defaultOptions) {
  const normalizeToastOptions = (options) => ({
    ...defaultOptions,
    ...options,
    position: getToastPlacement((options == null ? void 0 : options.position) ?? (defaultOptions == null ? void 0 : defaultOptions.position), dir)
  });
  const toast = (options) => {
    const normalizedToastOptions = normalizeToastOptions(options);
    const Message = createRenderToast(normalizedToastOptions);
    return toastStore.notify(Message, normalizedToastOptions);
  };
  toast.update = (id, options) => {
    toastStore.update(id, normalizeToastOptions(options));
  };
  toast.promise = (promise, options) => {
    const id = toast({
      ...options.loading,
      status: "loading",
      duration: null
    });
    promise.then((data) => toast.update(id, {
      status: "success",
      duration: 5e3,
      ...runIfFn(options.success, data)
    })).catch((error) => toast.update(id, {
      status: "error",
      duration: 5e3,
      ...runIfFn(options.error, error)
    }));
  };
  toast.closeAll = toastStore.closeAll;
  toast.close = toastStore.close;
  toast.isActive = toastStore.isActive;
  return toast;
}

// src/use-toast.tsx
import { useMemo } from "react";
function useToast(defaultOptions) {
  const { theme } = useChakra();
  return useMemo(() => createToastFn(theme.direction, defaultOptions), [defaultOptions, theme.direction]);
}

// src/toast.provider.tsx
import { AnimatePresence } from "framer-motion";
import { Portal } from "@chakra-ui/portal";

// src/toast.component.tsx
import { useTimeout } from "@chakra-ui/react-use-timeout";
import { useUpdateEffect } from "@chakra-ui/react-use-update-effect";
import { motion, useIsPresent } from "framer-motion";
import { chakra as chakra2 } from "@chakra-ui/system";
import { memo, useEffect, useMemo as useMemo2, useState } from "react";
var toastMotionVariants = {
  initial: (props) => {
    const { position } = props;
    const dir = ["top", "bottom"].includes(position) ? "y" : "x";
    let factor = ["top-right", "bottom-right"].includes(position) ? 1 : -1;
    if (position === "bottom")
      factor = 1;
    return {
      opacity: 0,
      [dir]: factor * 24
    };
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1]
    }
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 1, 1]
    }
  }
};
var ToastComponent = memo((props) => {
  const {
    id,
    message,
    onCloseComplete,
    onRequestRemove,
    requestClose = false,
    position = "bottom",
    duration = 5e3,
    containerStyle,
    motionVariants = toastMotionVariants,
    toastSpacing = "0.5rem"
  } = props;
  const [delay, setDelay] = useState(duration);
  const isPresent = useIsPresent();
  useUpdateEffect(() => {
    if (!isPresent) {
      onCloseComplete == null ? void 0 : onCloseComplete();
    }
  }, [isPresent]);
  useUpdateEffect(() => {
    setDelay(duration);
  }, [duration]);
  const onMouseEnter = () => setDelay(null);
  const onMouseLeave = () => setDelay(duration);
  const close = () => {
    if (isPresent)
      onRequestRemove();
  };
  useEffect(() => {
    if (isPresent && requestClose) {
      onRequestRemove();
    }
  }, [isPresent, requestClose, onRequestRemove]);
  useTimeout(close, delay);
  const containerStyles = useMemo2(() => ({
    pointerEvents: "auto",
    maxWidth: 560,
    minWidth: 300,
    margin: toastSpacing,
    ...containerStyle
  }), [containerStyle, toastSpacing]);
  const toastStyle = useMemo2(() => getToastStyle(position), [position]);
  return /* @__PURE__ */ React.createElement(motion.li, {
    layout: true,
    className: "chakra-toast",
    variants: motionVariants,
    initial: "initial",
    animate: "animate",
    exit: "exit",
    onHoverStart: onMouseEnter,
    onHoverEnd: onMouseLeave,
    custom: { position },
    style: toastStyle
  }, /* @__PURE__ */ React.createElement(chakra2.div, {
    role: "status",
    "aria-atomic": "true",
    className: "chakra-toast__inner",
    __css: containerStyles
  }, runIfFn(message, { id, onClose: close })));
});
ToastComponent.displayName = "ToastComponent";

// src/toast.provider.tsx
import { useSyncExternalStore } from "react";
var ToastProvider = (props) => {
  const state = useSyncExternalStore(toastStore.subscribe, toastStore.getState, toastStore.getState);
  const {
    children,
    motionVariants,
    component: Component = ToastComponent,
    portalProps
  } = props;
  const stateKeys = Object.keys(state);
  const toastList = stateKeys.map((position) => {
    const toasts = state[position];
    return /* @__PURE__ */ React.createElement("ul", {
      role: "region",
      "aria-live": "polite",
      key: position,
      id: `chakra-toast-manager-${position}`,
      style: getToastListStyle(position)
    }, /* @__PURE__ */ React.createElement(AnimatePresence, {
      initial: false
    }, toasts.map((toast) => /* @__PURE__ */ React.createElement(Component, {
      key: toast.id,
      motionVariants,
      ...toast
    }))));
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, children, /* @__PURE__ */ React.createElement(Portal, {
    ...portalProps
  }, toastList));
};

// src/create-standalone-toast.tsx
import {
  ColorModeContext,
  ThemeProvider
} from "@chakra-ui/system";
import defaultTheme from "@chakra-ui/theme";
var defaults = {
  duration: 5e3,
  variant: "solid"
};
var defaultStandaloneParam = {
  theme: defaultTheme,
  colorMode: "light",
  toggleColorMode: () => {
  },
  setColorMode: () => {
  },
  defaultOptions: defaults
};
function createStandaloneToast({
  theme = defaultStandaloneParam.theme,
  colorMode = defaultStandaloneParam.colorMode,
  toggleColorMode = defaultStandaloneParam.toggleColorMode,
  setColorMode = defaultStandaloneParam.setColorMode,
  defaultOptions = defaultStandaloneParam.defaultOptions,
  motionVariants,
  toastSpacing,
  component
} = defaultStandaloneParam) {
  const colorModeContextValue = { colorMode, setColorMode, toggleColorMode };
  const ToastContainer = () => /* @__PURE__ */ React.createElement(ThemeProvider, {
    theme
  }, /* @__PURE__ */ React.createElement(ColorModeContext.Provider, {
    value: colorModeContextValue
  }, /* @__PURE__ */ React.createElement(ToastProvider, {
    defaultOptions,
    motionVariants,
    toastSpacing,
    component
  })));
  return {
    ToastContainer,
    toast: createToastFn(theme.direction, defaultOptions)
  };
}
export {
  Toast,
  ToastProvider,
  createRenderToast,
  createStandaloneToast,
  createToastFn,
  defaultStandaloneParam,
  getToastPlacement,
  useToast
};
