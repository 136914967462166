import { AwsCrc32 } from "@aws-crypto/crc32";
import { AwsCrc32c } from "@aws-crypto/crc32c";
import { ChecksumAlgorithm } from "./constants";
export var selectChecksumAlgorithmFunction = function (checksumAlgorithm, config) {
    var _a;
    return ((_a = {},
        _a[ChecksumAlgorithm.MD5] = config.md5,
        _a[ChecksumAlgorithm.CRC32] = AwsCrc32,
        _a[ChecksumAlgorithm.CRC32C] = AwsCrc32c,
        _a[ChecksumAlgorithm.SHA1] = config.sha1,
        _a[ChecksumAlgorithm.SHA256] = config.sha256,
        _a)[checksumAlgorithm]);
};
