import { __assign, __read } from "tslib";
import { DOT_PATTERN, getArnResources, getSuffix, getSuffixForArnEndpoint, isBucketNameOptions, isDnsCompatibleBucketName, validateAccountId, validateArnEndpointOptions, validateCustomEndpoint, validateDNSHostLabel, validateMrapAlias, validateNoDualstack, validateNoFIPS, validateOutpostService, validatePartition, validateRegion, validateRegionalClient, validateS3Service, validateService, } from "./bucketHostnameUtils";
export var bucketHostname = function (options) {
    validateCustomEndpoint(options);
    return isBucketNameOptions(options)
        ?
            getEndpointFromBucketName(options)
        :
            getEndpointFromArn(options);
};
var getEndpointFromBucketName = function (_a) {
    var _b = _a.accelerateEndpoint, accelerateEndpoint = _b === void 0 ? false : _b, region = _a.clientRegion, baseHostname = _a.baseHostname, bucketName = _a.bucketName, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.fipsEndpoint, fipsEndpoint = _d === void 0 ? false : _d, _e = _a.pathStyleEndpoint, pathStyleEndpoint = _e === void 0 ? false : _e, _f = _a.tlsCompatible, tlsCompatible = _f === void 0 ? true : _f, _g = _a.isCustomEndpoint, isCustomEndpoint = _g === void 0 ? false : _g;
    var _h = __read(isCustomEndpoint ? [region, baseHostname] : getSuffix(baseHostname), 2), clientRegion = _h[0], hostnameSuffix = _h[1];
    if (pathStyleEndpoint || !isDnsCompatibleBucketName(bucketName) || (tlsCompatible && DOT_PATTERN.test(bucketName))) {
        return {
            bucketEndpoint: false,
            hostname: dualstackEndpoint ? "s3.dualstack.".concat(clientRegion, ".").concat(hostnameSuffix) : baseHostname,
        };
    }
    if (accelerateEndpoint) {
        baseHostname = "s3-accelerate".concat(dualstackEndpoint ? ".dualstack" : "", ".").concat(hostnameSuffix);
    }
    else if (dualstackEndpoint) {
        baseHostname = "s3.dualstack.".concat(clientRegion, ".").concat(hostnameSuffix);
    }
    return {
        bucketEndpoint: true,
        hostname: "".concat(bucketName, ".").concat(baseHostname),
    };
};
var getEndpointFromArn = function (options) {
    var isCustomEndpoint = options.isCustomEndpoint, baseHostname = options.baseHostname, clientRegion = options.clientRegion;
    var hostnameSuffix = isCustomEndpoint ? baseHostname : getSuffixForArnEndpoint(baseHostname)[1];
    var pathStyleEndpoint = options.pathStyleEndpoint, _a = options.accelerateEndpoint, accelerateEndpoint = _a === void 0 ? false : _a, _b = options.fipsEndpoint, fipsEndpoint = _b === void 0 ? false : _b, _c = options.tlsCompatible, tlsCompatible = _c === void 0 ? true : _c, bucketName = options.bucketName, _d = options.clientPartition, clientPartition = _d === void 0 ? "aws" : _d;
    validateArnEndpointOptions({ pathStyleEndpoint: pathStyleEndpoint, accelerateEndpoint: accelerateEndpoint, tlsCompatible: tlsCompatible });
    var service = bucketName.service, partition = bucketName.partition, accountId = bucketName.accountId, region = bucketName.region, resource = bucketName.resource;
    validateService(service);
    validatePartition(partition, { clientPartition: clientPartition });
    validateAccountId(accountId);
    var _e = getArnResources(resource), accesspointName = _e.accesspointName, outpostId = _e.outpostId;
    if (service === "s3-object-lambda") {
        return getEndpointFromObjectLambdaArn(__assign(__assign({}, options), { tlsCompatible: tlsCompatible, bucketName: bucketName, accesspointName: accesspointName, hostnameSuffix: hostnameSuffix }));
    }
    if (region === "") {
        return getEndpointFromMRAPArn(__assign(__assign({}, options), { clientRegion: clientRegion, mrapAlias: accesspointName, hostnameSuffix: hostnameSuffix }));
    }
    if (outpostId) {
        return getEndpointFromOutpostArn(__assign(__assign({}, options), { clientRegion: clientRegion, outpostId: outpostId, accesspointName: accesspointName, hostnameSuffix: hostnameSuffix }));
    }
    return getEndpointFromAccessPointArn(__assign(__assign({}, options), { clientRegion: clientRegion, accesspointName: accesspointName, hostnameSuffix: hostnameSuffix }));
};
var getEndpointFromObjectLambdaArn = function (_a) {
    var _b = _a.dualstackEndpoint, dualstackEndpoint = _b === void 0 ? false : _b, _c = _a.fipsEndpoint, fipsEndpoint = _c === void 0 ? false : _c, _d = _a.tlsCompatible, tlsCompatible = _d === void 0 ? true : _d, useArnRegion = _a.useArnRegion, clientRegion = _a.clientRegion, _e = _a.clientSigningRegion, clientSigningRegion = _e === void 0 ? clientRegion : _e, accesspointName = _a.accesspointName, bucketName = _a.bucketName, hostnameSuffix = _a.hostnameSuffix;
    var accountId = bucketName.accountId, region = bucketName.region, service = bucketName.service;
    validateRegionalClient(clientRegion);
    validateRegion(region, {
        useArnRegion: useArnRegion,
        clientRegion: clientRegion,
        clientSigningRegion: clientSigningRegion,
        allowFipsRegion: true,
        useFipsEndpoint: fipsEndpoint,
    });
    validateNoDualstack(dualstackEndpoint);
    var DNSHostLabel = "".concat(accesspointName, "-").concat(accountId);
    validateDNSHostLabel(DNSHostLabel, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? region : clientRegion;
    var signingRegion = useArnRegion ? region : clientSigningRegion;
    return {
        bucketEndpoint: true,
        hostname: "".concat(DNSHostLabel, ".").concat(service).concat(fipsEndpoint ? "-fips" : "", ".").concat(endpointRegion, ".").concat(hostnameSuffix),
        signingRegion: signingRegion,
        signingService: service,
    };
};
var getEndpointFromMRAPArn = function (_a) {
    var disableMultiregionAccessPoints = _a.disableMultiregionAccessPoints, _b = _a.dualstackEndpoint, dualstackEndpoint = _b === void 0 ? false : _b, isCustomEndpoint = _a.isCustomEndpoint, mrapAlias = _a.mrapAlias, hostnameSuffix = _a.hostnameSuffix;
    if (disableMultiregionAccessPoints === true) {
        throw new Error("SDK is attempting to use a MRAP ARN. Please enable to feature.");
    }
    validateMrapAlias(mrapAlias);
    validateNoDualstack(dualstackEndpoint);
    return {
        bucketEndpoint: true,
        hostname: "".concat(mrapAlias).concat(isCustomEndpoint ? "" : ".accesspoint.s3-global", ".").concat(hostnameSuffix),
        signingRegion: "*",
    };
};
var getEndpointFromOutpostArn = function (_a) {
    var useArnRegion = _a.useArnRegion, clientRegion = _a.clientRegion, _b = _a.clientSigningRegion, clientSigningRegion = _b === void 0 ? clientRegion : _b, bucketName = _a.bucketName, outpostId = _a.outpostId, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.fipsEndpoint, fipsEndpoint = _d === void 0 ? false : _d, _e = _a.tlsCompatible, tlsCompatible = _e === void 0 ? true : _e, accesspointName = _a.accesspointName, isCustomEndpoint = _a.isCustomEndpoint, hostnameSuffix = _a.hostnameSuffix;
    validateRegionalClient(clientRegion);
    validateRegion(bucketName.region, { useArnRegion: useArnRegion, clientRegion: clientRegion, clientSigningRegion: clientSigningRegion, useFipsEndpoint: fipsEndpoint });
    var DNSHostLabel = "".concat(accesspointName, "-").concat(bucketName.accountId);
    validateDNSHostLabel(DNSHostLabel, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? bucketName.region : clientRegion;
    var signingRegion = useArnRegion ? bucketName.region : clientSigningRegion;
    validateOutpostService(bucketName.service);
    validateDNSHostLabel(outpostId, { tlsCompatible: tlsCompatible });
    validateNoDualstack(dualstackEndpoint);
    validateNoFIPS(fipsEndpoint);
    var hostnamePrefix = "".concat(DNSHostLabel, ".").concat(outpostId);
    return {
        bucketEndpoint: true,
        hostname: "".concat(hostnamePrefix).concat(isCustomEndpoint ? "" : ".s3-outposts.".concat(endpointRegion), ".").concat(hostnameSuffix),
        signingRegion: signingRegion,
        signingService: "s3-outposts",
    };
};
var getEndpointFromAccessPointArn = function (_a) {
    var useArnRegion = _a.useArnRegion, clientRegion = _a.clientRegion, _b = _a.clientSigningRegion, clientSigningRegion = _b === void 0 ? clientRegion : _b, bucketName = _a.bucketName, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.fipsEndpoint, fipsEndpoint = _d === void 0 ? false : _d, _e = _a.tlsCompatible, tlsCompatible = _e === void 0 ? true : _e, accesspointName = _a.accesspointName, isCustomEndpoint = _a.isCustomEndpoint, hostnameSuffix = _a.hostnameSuffix;
    validateRegionalClient(clientRegion);
    validateRegion(bucketName.region, {
        useArnRegion: useArnRegion,
        clientRegion: clientRegion,
        clientSigningRegion: clientSigningRegion,
        allowFipsRegion: true,
        useFipsEndpoint: fipsEndpoint,
    });
    var hostnamePrefix = "".concat(accesspointName, "-").concat(bucketName.accountId);
    validateDNSHostLabel(hostnamePrefix, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? bucketName.region : clientRegion;
    var signingRegion = useArnRegion ? bucketName.region : clientSigningRegion;
    validateS3Service(bucketName.service);
    return {
        bucketEndpoint: true,
        hostname: "".concat(hostnamePrefix).concat(isCustomEndpoint
            ? ""
            : ".s3-accesspoint".concat(fipsEndpoint ? "-fips" : "").concat(dualstackEndpoint ? ".dualstack" : "", ".").concat(endpointRegion), ".").concat(hostnameSuffix),
        signingRegion: signingRegion,
    };
};
