// ../../react-shim.js
import React from "react";

// src/checkbox-group.tsx
import { useMemo } from "react";

// src/checkbox-context.ts
import { createContext } from "@chakra-ui/react-context";
var [CheckboxGroupProvider, useCheckboxGroupContext] = createContext({
  name: "CheckboxGroupContext",
  strict: false
});

// src/use-checkbox-group.ts
import { useCallbackRef } from "@chakra-ui/react-use-callback-ref";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";

// ../../utilities/shared-utils/dist/index.esm.js
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !Array.isArray(value);
}
var dataAttr = (condition) => condition ? "" : void 0;
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}
function callAll(...fns) {
  return function mergedFn(arg) {
    fns.forEach((fn) => {
      fn == null ? void 0 : fn(arg);
    });
  };
}

// src/use-checkbox-group.ts
import { useCallback } from "react";
function isInputEvent(value) {
  return value && isObject(value) && isObject(value.target);
}
function useCheckboxGroup(props = {}) {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    isDisabled,
    isNative
  } = props;
  const onChangeProp = useCallbackRef(onChange);
  const [value, setValue] = useControllableState({
    value: valueProp,
    defaultValue: defaultValue || [],
    onChange: onChangeProp
  });
  const handleChange = useCallback((eventOrValue) => {
    if (!value)
      return;
    const isChecked = isInputEvent(eventOrValue) ? eventOrValue.target.checked : !value.includes(eventOrValue);
    const selectedValue = isInputEvent(eventOrValue) ? eventOrValue.target.value : eventOrValue;
    const nextValue = isChecked ? [...value, selectedValue] : value.filter((v) => String(v) !== String(selectedValue));
    setValue(nextValue);
  }, [setValue, value]);
  const getCheckboxProps = useCallback((props2 = {}) => {
    const checkedKey = isNative ? "checked" : "isChecked";
    return {
      ...props2,
      [checkedKey]: value.some((val) => String(props2.value) === String(val)),
      onChange: handleChange
    };
  }, [handleChange, isNative, value]);
  return {
    value,
    isDisabled,
    onChange: handleChange,
    setValue,
    getCheckboxProps
  };
}

// src/checkbox-group.tsx
function CheckboxGroup(props) {
  const { colorScheme, size, variant, children, isDisabled } = props;
  const { value, onChange } = useCheckboxGroup(props);
  const group = useMemo(() => ({
    size,
    onChange,
    colorScheme,
    value,
    variant,
    isDisabled
  }), [size, onChange, colorScheme, value, variant, isDisabled]);
  return /* @__PURE__ */ React.createElement(CheckboxGroupProvider, {
    value: group,
    children
  });
}
CheckboxGroup.displayName = "CheckboxGroup";

// src/checkbox-icon.tsx
import { chakra } from "@chakra-ui/system";
import { AnimatePresence, motion } from "framer-motion";
function __motion(el) {
  const m = motion;
  if ("custom" in m && typeof m.custom === "function") {
    return m.custom(el);
  }
  return m(el);
}
var MotionSvg = __motion(chakra.svg);
function CheckIcon(props) {
  return /* @__PURE__ */ React.createElement(MotionSvg, {
    width: "1.2em",
    viewBox: "0 0 12 10",
    variants: {
      unchecked: {
        opacity: 0,
        strokeDashoffset: 16
      },
      checked: {
        opacity: 1,
        strokeDashoffset: 0,
        transition: { duration: 0.2 }
      }
    },
    style: {
      fill: "none",
      strokeWidth: 2,
      stroke: "currentColor",
      strokeDasharray: 16
    },
    ...props
  }, /* @__PURE__ */ React.createElement("polyline", {
    points: "1.5 6 4.5 9 10.5 1"
  }));
}
function IndeterminateIcon(props) {
  return /* @__PURE__ */ React.createElement(MotionSvg, {
    width: "1.2em",
    viewBox: "0 0 24 24",
    variants: {
      unchecked: {
        scaleX: 0.65,
        opacity: 0
      },
      checked: {
        scaleX: 1,
        opacity: 1,
        transition: {
          scaleX: { duration: 0 },
          opacity: { duration: 0.02 }
        }
      }
    },
    style: { stroke: "currentColor", strokeWidth: 4 },
    ...props
  }, /* @__PURE__ */ React.createElement("line", {
    x1: "21",
    x2: "3",
    y1: "12",
    y2: "12"
  }));
}
function CheckboxTransition({ open, children }) {
  return /* @__PURE__ */ React.createElement(AnimatePresence, {
    initial: false
  }, open && /* @__PURE__ */ React.createElement(motion.div, {
    variants: {
      unchecked: { scale: 0.5 },
      checked: { scale: 1 }
    },
    initial: "unchecked",
    animate: "checked",
    exit: "unchecked",
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%"
    }
  }, children));
}
function CheckboxIcon(props) {
  const { isIndeterminate, isChecked, ...rest } = props;
  const BaseIcon = isIndeterminate ? IndeterminateIcon : CheckIcon;
  return /* @__PURE__ */ React.createElement(CheckboxTransition, {
    open: isChecked || isIndeterminate
  }, /* @__PURE__ */ React.createElement(BaseIcon, {
    ...rest
  }));
}

// src/checkbox.tsx
import {
  chakra as chakra2,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { cloneElement, useMemo as useMemo2 } from "react";

// src/use-checkbox.ts
import { useFormControlProps } from "@chakra-ui/form-control";
import { useSafeLayoutEffect } from "@chakra-ui/react-use-safe-layout-effect";
import { useUpdateEffect } from "@chakra-ui/react-use-update-effect";
import { useCallbackRef as useCallbackRef2 } from "@chakra-ui/react-use-callback-ref";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";

// ../../utilities/object-utils/dist/index.esm.js
function omit(object, keysToOmit = []) {
  const clone = Object.assign({}, object);
  for (const key of keysToOmit) {
    if (key in clone)
      delete clone[key];
  }
  return clone;
}

// src/use-checkbox.ts
import { visuallyHiddenStyle } from "@chakra-ui/visually-hidden";
import { trackFocusVisible } from "@zag-js/focus-visible";
import { useCallback as useCallback2, useEffect, useRef, useState } from "react";
function useCheckbox(props = {}) {
  const formControlProps = useFormControlProps(props);
  const {
    isDisabled,
    isReadOnly,
    isRequired,
    isInvalid,
    id,
    onBlur,
    onFocus,
    "aria-describedby": ariaDescribedBy
  } = formControlProps;
  const {
    defaultChecked,
    isChecked: checkedProp,
    isFocusable,
    onChange,
    isIndeterminate,
    name,
    value,
    tabIndex = void 0,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-invalid": ariaInvalid,
    ...rest
  } = props;
  const htmlProps = omit(rest, [
    "isDisabled",
    "isReadOnly",
    "isRequired",
    "isInvalid",
    "id",
    "onBlur",
    "onFocus",
    "aria-describedby"
  ]);
  const onChangeProp = useCallbackRef2(onChange);
  const onBlurProp = useCallbackRef2(onBlur);
  const onFocusProp = useCallbackRef2(onFocus);
  const [isFocusVisible, setIsFocusVisible] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [isActive, setActive] = useState(false);
  useEffect(() => {
    return trackFocusVisible(setIsFocusVisible);
  }, []);
  const inputRef = useRef(null);
  const [rootIsLabelElement, setRootIsLabelElement] = useState(true);
  const [checkedState, setCheckedState] = useState(!!defaultChecked);
  const isControlled = checkedProp !== void 0;
  const isChecked = isControlled ? checkedProp : checkedState;
  const handleChange = useCallback2((event) => {
    if (isReadOnly || isDisabled) {
      event.preventDefault();
      return;
    }
    if (!isControlled) {
      if (isChecked) {
        setCheckedState(event.target.checked);
      } else {
        setCheckedState(isIndeterminate ? true : event.target.checked);
      }
    }
    onChangeProp == null ? void 0 : onChangeProp(event);
  }, [
    isReadOnly,
    isDisabled,
    isChecked,
    isControlled,
    isIndeterminate,
    onChangeProp
  ]);
  useSafeLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(isIndeterminate);
    }
  }, [isIndeterminate]);
  useUpdateEffect(() => {
    if (isDisabled) {
      setFocused(false);
    }
  }, [isDisabled, setFocused]);
  useSafeLayoutEffect(() => {
    const el = inputRef.current;
    if (!(el == null ? void 0 : el.form))
      return;
    el.form.onreset = () => {
      setCheckedState(!!defaultChecked);
    };
  }, []);
  const trulyDisabled = isDisabled && !isFocusable;
  const onKeyDown = useCallback2((event) => {
    if (event.key === " ") {
      setActive(true);
    }
  }, [setActive]);
  const onKeyUp = useCallback2((event) => {
    if (event.key === " ") {
      setActive(false);
    }
  }, [setActive]);
  useSafeLayoutEffect(() => {
    if (!inputRef.current)
      return;
    const notInSync = inputRef.current.checked !== isChecked;
    if (notInSync) {
      setCheckedState(inputRef.current.checked);
    }
  }, [inputRef.current]);
  const getCheckboxProps = useCallback2((props2 = {}, forwardedRef = null) => {
    const onPressDown = (event) => {
      if (isFocused) {
        event.preventDefault();
      }
      setActive(true);
    };
    return {
      ...props2,
      ref: forwardedRef,
      "data-active": dataAttr(isActive),
      "data-hover": dataAttr(isHovered),
      "data-checked": dataAttr(isChecked),
      "data-focus": dataAttr(isFocused),
      "data-focus-visible": dataAttr(isFocused && isFocusVisible),
      "data-indeterminate": dataAttr(isIndeterminate),
      "data-disabled": dataAttr(isDisabled),
      "data-invalid": dataAttr(isInvalid),
      "data-readonly": dataAttr(isReadOnly),
      "aria-hidden": true,
      onMouseDown: callAllHandlers(props2.onMouseDown, onPressDown),
      onMouseUp: callAllHandlers(props2.onMouseUp, () => setActive(false)),
      onMouseEnter: callAllHandlers(props2.onMouseEnter, () => setHovered(true)),
      onMouseLeave: callAllHandlers(props2.onMouseLeave, () => setHovered(false))
    };
  }, [
    isActive,
    isChecked,
    isDisabled,
    isFocused,
    isFocusVisible,
    isHovered,
    isIndeterminate,
    isInvalid,
    isReadOnly
  ]);
  const getRootProps = useCallback2((props2 = {}, forwardedRef = null) => ({
    ...htmlProps,
    ...props2,
    ref: mergeRefs(forwardedRef, (node) => {
      if (!node)
        return;
      setRootIsLabelElement(node.tagName === "LABEL");
    }),
    onClick: callAllHandlers(props2.onClick, () => {
      var _a;
      if (!rootIsLabelElement) {
        (_a = inputRef.current) == null ? void 0 : _a.click();
        requestAnimationFrame(() => {
          var _a2;
          (_a2 = inputRef.current) == null ? void 0 : _a2.focus();
        });
      }
    }),
    "data-disabled": dataAttr(isDisabled),
    "data-checked": dataAttr(isChecked),
    "data-invalid": dataAttr(isInvalid)
  }), [htmlProps, isDisabled, isChecked, isInvalid, rootIsLabelElement]);
  const getInputProps = useCallback2((props2 = {}, forwardedRef = null) => {
    return {
      ...props2,
      ref: mergeRefs(inputRef, forwardedRef),
      type: "checkbox",
      name,
      value,
      id,
      tabIndex,
      onChange: callAllHandlers(props2.onChange, handleChange),
      onBlur: callAllHandlers(props2.onBlur, onBlurProp, () => setFocused(false)),
      onFocus: callAllHandlers(props2.onFocus, onFocusProp, () => setFocused(true)),
      onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
      onKeyUp: callAllHandlers(props2.onKeyUp, onKeyUp),
      required: isRequired,
      checked: isChecked,
      disabled: trulyDisabled,
      readOnly: isReadOnly,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-invalid": ariaInvalid ? Boolean(ariaInvalid) : isInvalid,
      "aria-describedby": ariaDescribedBy,
      "aria-disabled": isDisabled,
      style: visuallyHiddenStyle
    };
  }, [
    name,
    value,
    id,
    handleChange,
    onBlurProp,
    onFocusProp,
    onKeyDown,
    onKeyUp,
    isRequired,
    isChecked,
    trulyDisabled,
    isReadOnly,
    ariaLabel,
    ariaLabelledBy,
    ariaInvalid,
    isInvalid,
    ariaDescribedBy,
    isDisabled,
    tabIndex
  ]);
  const getLabelProps = useCallback2((props2 = {}, forwardedRef = null) => ({
    ...props2,
    ref: forwardedRef,
    onMouseDown: callAllHandlers(props2.onMouseDown, stopEvent),
    onTouchStart: callAllHandlers(props2.onTouchStart, stopEvent),
    "data-disabled": dataAttr(isDisabled),
    "data-checked": dataAttr(isChecked),
    "data-invalid": dataAttr(isInvalid)
  }), [isChecked, isDisabled, isInvalid]);
  const state = {
    isInvalid,
    isFocused,
    isChecked,
    isActive,
    isHovered,
    isIndeterminate,
    isDisabled,
    isReadOnly,
    isRequired
  };
  return {
    state,
    getRootProps,
    getCheckboxProps,
    getInputProps,
    getLabelProps,
    htmlProps
  };
}
function stopEvent(event) {
  event.preventDefault();
  event.stopPropagation();
}

// src/checkbox.tsx
var CheckboxControl = chakra2("span", {
  baseStyle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "top",
    userSelect: "none",
    flexShrink: 0
  }
});
var CheckboxRoot = chakra2("label", {
  baseStyle: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top",
    position: "relative"
  }
});
var Checkbox = forwardRef(function Checkbox2(props, ref) {
  const group = useCheckboxGroupContext();
  const mergedProps = { ...group, ...props };
  const styles = useMultiStyleConfig("Checkbox", mergedProps);
  const ownProps = omitThemingProps(props);
  const {
    spacing = "0.5rem",
    className,
    children,
    iconColor,
    iconSize,
    icon = /* @__PURE__ */ React.createElement(CheckboxIcon, null),
    isChecked: isCheckedProp,
    isDisabled = group == null ? void 0 : group.isDisabled,
    onChange: onChangeProp,
    inputProps,
    ...rest
  } = ownProps;
  let isChecked = isCheckedProp;
  if ((group == null ? void 0 : group.value) && ownProps.value) {
    isChecked = group.value.includes(ownProps.value);
  }
  let onChange = onChangeProp;
  if ((group == null ? void 0 : group.onChange) && ownProps.value) {
    onChange = callAll(group.onChange, onChangeProp);
  }
  const {
    state,
    getInputProps,
    getCheckboxProps,
    getLabelProps,
    getRootProps
  } = useCheckbox({
    ...rest,
    isDisabled,
    isChecked,
    onChange
  });
  const iconStyles = useMemo2(() => ({
    opacity: state.isChecked || state.isIndeterminate ? 1 : 0,
    transform: state.isChecked || state.isIndeterminate ? "scale(1)" : "scale(0.95)",
    fontSize: iconSize,
    color: iconColor,
    ...styles.icon
  }), [iconColor, iconSize, state.isChecked, state.isIndeterminate, styles.icon]);
  const clonedIcon = cloneElement(icon, {
    __css: iconStyles,
    isIndeterminate: state.isIndeterminate,
    isChecked: state.isChecked
  });
  return /* @__PURE__ */ React.createElement(CheckboxRoot, {
    __css: styles.container,
    className: cx("chakra-checkbox", className),
    ...getRootProps()
  }, /* @__PURE__ */ React.createElement("input", {
    className: "chakra-checkbox__input",
    ...getInputProps(inputProps, ref)
  }), /* @__PURE__ */ React.createElement(CheckboxControl, {
    __css: styles.control,
    className: "chakra-checkbox__control",
    ...getCheckboxProps()
  }, clonedIcon), children && /* @__PURE__ */ React.createElement(chakra2.span, {
    className: "chakra-checkbox__label",
    ...getLabelProps(),
    __css: {
      marginStart: spacing,
      ...styles.label
    }
  }, children));
});
Checkbox.displayName = "Checkbox";
export {
  Checkbox,
  CheckboxGroup,
  CheckboxIcon,
  useCheckbox,
  useCheckboxGroup
};
